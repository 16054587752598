
export default function () {
  return {
    origin: null,
    destination: null,
    depart: null,
    return: null,
    adults: 1,
    children: 0,
    return_type: 'one_way',
    traveller: null,
    requester: null,
    passengerNumbers: [
      {
        label: '0',
        value: 0
      },
      {
        label: '1',
        value: 1
      },
      {
        label: '2',
        value: 2
      },
      {
        label: '3',
        value: 3
      },
      {
        label: '4',
        value: 4
      },
      {
        label: '5',
        value: 5
      },
      {
        label: '6',
        value: 6
      },
      {
        label: '7',
        value: 7
      },
      {
        label: '8',
        value: 8
      }
    ]
  }
}
