var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('m-group-title',{attrs:{"label":_vm.$t('onwardtravel.passenger_details')}}),_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"dense":"","label":_vm.$t('name.forename')},on:{"input":_vm.setPassenger},model:{value:(_vm.mainPassenger.firstName),callback:function ($$v) {_vm.$set(_vm.mainPassenger, "firstName", $$v)},expression:"mainPassenger.firstName"}})],1),_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"dense":"","label":_vm.$t('name.surname')},on:{"input":_vm.setPassenger},model:{value:(_vm.mainPassenger.surname),callback:function ($$v) {_vm.$set(_vm.mainPassenger, "surname", $$v)},expression:"mainPassenger.surname"}})],1),_c('div',{staticClass:"col-6"},[_c('m-phone-field',{attrs:{"dialcode":_vm.dialcode,"phone":_vm.phone},on:{"dialcode":function (val) { return _vm.handleDialcode(val); },"phone":function (val) { return _vm.handlePhone(val); }}})],1),_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"rules":[
              function (val) { return (val && val.length) || 'This field is required'; } ],"label":_vm.$t('onwardtravel.customer_reference'),"hint":_vm.$t('onwardtravel.customer_reference_hint')},on:{"input":_vm.setRacDetails},model:{value:(_vm.racDetails.customer_reference),callback:function ($$v) {_vm.$set(_vm.racDetails, "customer_reference", $$v)},expression:"racDetails.customer_reference"}})],1)])])],1),_c('m-group-title',{attrs:{"label":_vm.$t('onwardtravel.internal_details')}}),_c('q-card',[_c('q-card-section',[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-6"},[_c('m-business-area',{attrs:{"slug":_vm.requester.organisation,"dense":""},model:{value:(_vm.racDetails.business_area),callback:function ($$v) {_vm.$set(_vm.racDetails, "business_area", $$v)},expression:"racDetails.business_area"}})],1),_c('div',{staticClass:"col-6"},[_c('m-cost-centre',{attrs:{"slug":_vm.requester.organisation,"dense":""},model:{value:(_vm.racDetails.cost_centre),callback:function ($$v) {_vm.$set(_vm.racDetails, "cost_centre", $$v)},expression:"racDetails.cost_centre"}})],1),_c('div',{staticClass:"col-6"},[_c('q-select',{attrs:{"dense":"","label":_vm.$t('rac.vehicle.type'),"hint":_vm.$t('rac.vehicle.type_hint'),"options":[
              {
                label: _vm.$t('taxi.vehicle.types.taxi.description'),
                value: _vm.$t('taxi.vehicle.types.taxi.name'),
              },
              {
                label: _vm.$t('taxi.vehicle.types.mpv.description'),
                value: _vm.$t('taxi.vehicle.types.mpv.name'),
              },
              {
                label: _vm.$t('taxi.vehicle.types.wav.description'),
                value: _vm.$t('taxi.vehicle.types.wav.name'),
              },
              {
                label: _vm.$t('taxi.vehicle.types.other.description'),
                value: _vm.$t('taxi.vehicle.types.other.name'),
              } ],"emit-value":""},on:{"input":_vm.setRacDetails},model:{value:(_vm.racDetails.vehicle_type),callback:function ($$v) {_vm.$set(_vm.racDetails, "vehicle_type", $$v)},expression:"racDetails.vehicle_type"}})],1),_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"dense":"","label":_vm.$t('rac.vehicle.reg'),"hint":_vm.$t('rac.vehicle.reg_hint')},on:{"input":_vm.setRacDetails},model:{value:(_vm.racDetails.vehicle_registration),callback:function ($$v) {_vm.$set(_vm.racDetails, "vehicle_registration", $$v)},expression:"racDetails.vehicle_registration"}})],1),_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"dense":"","label":_vm.$t('rac.agreement_reference.label'),"hint":_vm.$t('rac.agreement_reference.hint')},on:{"input":_vm.setRacDetails},model:{value:(_vm.racDetails.agreement_reference),callback:function ($$v) {_vm.$set(_vm.racDetails, "agreement_reference", $$v)},expression:"racDetails.agreement_reference"}})],1)])])],1),_c('m-group-title',{attrs:{"label":_vm.$t('onwardtravel.driver_notes.label')}}),_c('q-card',[_c('m-banner',{attrs:{"message":_vm.$t('onwardtravel.no_personal_info'),"rounded":false,"borderless":""}}),_c('q-card-section',[_c('q-input',{attrs:{"dense":"","value":_vm.driverNotes,"placeholder":_vm.$t('onwardtravel.driver_notes.placeholder'),"autogrow":""},on:{"input":_vm.setDriverNotes}})],1)],1),_c('m-group-title',{attrs:{"label":_vm.$t('onwardtravel.internal_notes.label')}}),_c('q-card',[_c('q-card-section',[_c('q-input',{attrs:{"dense":"","value":_vm.internalNotes,"placeholder":_vm.$t('onwardtravel.internal_notes.placeholder'),"autogrow":""},on:{"input":_vm.setInternalNotes}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }