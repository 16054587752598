<template>
  <div v-if="!loading">
    <q-chip
      v-if="!readonly && hasPermission('roles.attach.permissions')"
      color="primary"
      square
      clickable
    >
      <q-icon name="add" size="xs" />
      <q-menu v-model="showingMenu">
        <q-input v-model="search" class="q-mx-sm" label="Filter permissions" />
        <q-separator />
        <q-list separator>
          <q-item v-for="perm in availablePermissionsToAttach" :key="perm.slug" v-close-popup="false" clickable @click.stop="selectPermission(perm.slug)">
            <q-item-section class="text-capitalize">
              {{ perm.name }}
            </q-item-section>
            <q-item-section side class="text-caption">
              {{ perm.slug }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <div v-if="!selectedPermissions.length">
        Attach new permission
      </div>
    </q-chip>
    <q-chip
      v-for="perm in selectedPermissions"
      :key="perm"
      :label="perm"
      square
      :dense="dense"
      :removable="!readonly"
      @remove="removePermission(perm)"
    />
  </div>
  <div v-else>
    Loading . . .
  </div>
</template>
<script>
import authentication from 'mixins/authentication'
import Permission from 'store/models/permission'
export default {
  mixins: [authentication],
  props: {
    value: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      loading: false,
      showingMenu: false
    }
  },
  computed: {
    selectedPermissions: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    allPermissions () {
      return Permission.all()
    },
    availablePermissionsToAttach () {
      return this.allPermissions.filter(perm => {
        return !this.selectedPermissions.includes(perm.slug) && (perm.name.includes(this.search) || perm.slug.includes(this.search))
      })
    }
  },
  beforeMount () {
    this.loading = true
    const permissions = this.value.permissions
    if (permissions) this.selectedPermissions = permissions
    if (!this.readonly) this.$store.dispatch('entities/permissions/load').then(() => { this.loading = false })
    else this.loading = false
  },
  methods: {
    selectPermission (slug) {
      if (slug) {
        this.selectedPermissions.push(slug)
        this.$emit('addition', slug)
        this.$nextTick(() => {
          this.showingMenu = true
        })
      }
    },
    removePermission (slug) {
      if (slug) {
        this.selectedPermissions = this.selectedPermissions.filter(perm => perm !== slug)
        this.$emit('removal', slug)
      }
    }
  }
}
</script>
