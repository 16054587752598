<template>
  <peek-form :title="$t('manage_mobility_budgets')" :loading="loading" @save="submit">
    <q-page padding>
      <m-group-title :label="$tc('budget', 2)" />
      <q-card>
        <q-card-section>
          <m-money-field v-model="meal_allowance" :disable="loading" :label="$t('meal_allowance')" @input="changed = true" />
          <p>{{ $t('tooltip.set_meal_allowance') }}</p>
          <br>
          <p>{{ $t('tooltip.before_meal_allowance') }}</p>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>
<script>
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import { travelPolicy, updateTravelPolicy } from 'api/organisations'
import { MGroupTitle, MMoneyField } from 'components/'

export default {
  name: 'UserSettingsBudgets',
  components: { peekForm, MMoneyField, MGroupTitle },
  data () {
    return {
      resource: null,
      loading: false,
      changed: false,
      policy: {
      },
      meal_allowance: 0
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      activeUser: 'entities/users/peekUser',
      authedUser: 'user'
    })
  },
  async beforeMount () {
    this.loading = true
    if (this.$route.query && this.$route.query.slug) this.organisation = this.lookupOrg(this.$route.query.slug)
    else this.organisation = this.orgGetter
    if (!this.organisation) this.$router.push({ path: this.$route.path })

    const policy = await travelPolicy(this.organisation.slug)
    this.policy = policy.data[0]
    this.meal_allowance = Number(this.policy.hotel.meal_allowance)
    this.loading = false
  },
  methods: {
    async submit () {
      if (this.changed) {
        this.policy.hotel.meal_allowance = this.meal_allowance
        this.loading = true
        await updateTravelPolicy(this.organisation.slug, 'hotel', this.policy.hotel)
        this.loading = false
        this.$q.notify({
          message: this.$t('updated.meal_allowance'),
          color: 'positive',
          icon: 'done_all'
        })
        this.$router.go(-1)
      } else this.$router.go(-1)
    }
  }
}
</script>
