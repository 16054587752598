import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') ? next() : next('/dashboard')
}

export const index = {
  name: 'pots',
  path: '/pots/:content',
  components: {
    default: () => import(/* webpackChunkName: "pots" */'pages/pots/index.vue')
  },
  beforeEnter
}

export const errors = {
  name: 'pots-errors',
  path: '/pots/:content/errors',
  components: {
    default: () => import(/* webpackChunkName: "pots" */'pages/pots/index.vue')
  },
  beforeEnter
}

export const amended = {
  name: 'pots-amended',
  path: '/pots/:content/amended',
  components: {
    default: () => import(/* webpackChunkName: "pots" */'pages/pots/index.vue')
  },
  beforeEnter
}

export const bookedToday = {
  name: 'pots-booked-today',
  path: '/pots/:content/booked-today',
  components: {
    default: () => import(/* webpackChunkName: "pots" */'pages/pots/index.vue')
  },
  beforeEnter
}

export default { index, errors, amended, bookedToday }
