import store from 'store/modules/ride-hailing'

const meta = {
  permission: 'search.rides'
}

const beforeEnter = (to, from, next) => {
  !store.state.time ? next({ name: 'ondemand-office-ride-hailing' }) : next()
}

const search = {
  name: 'ondemand-office-ride-hailing',
  path: '/on-demand/office/ride-hailing',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-office-ridehailing" */ 'pages/ondemand/office-ride-hailing/index.vue')
  },
  meta
}

const results = {
  name: 'ondemand-office-ride-hailing-results',
  path: '/on-demand/office/ride-hailing/results',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-office-ridehailing" */ 'pages/ondemand/office-ride-hailing/results.vue')
  },
  meta,
  beforeEnter
}

const info = {
  name: 'ondemand-office-ride-hailing-vehicle',
  path: '/on-demand/office/ride-hailing/info',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-office-ridehailing" */ 'pages/ondemand/office-ride-hailing/additional-info.vue')
  },
  meta,
  beforeEnter
}

const summary = {
  name: 'ondemand-office-ride-hailing-summary',
  path: '/on-demand/office/ride-hailing/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-office-ridehailing" */ 'pages/ondemand/office-ride-hailing/summary.vue')
  },
  meta,
  beforeEnter
}

export default { search, results, info, summary }
