import _ from 'lodash'
import { status } from 'api/bikehire'
import { Notify } from 'quasar'
import i18n from 'i18n'

const initialState = {
  status: {},
  search: null
}

const state = _.cloneDeep(initialState)

const getters = {
  status: state => state.status,
  search: state => state.search
}

const actions = {
  async setBikehireStatus ({ commit }, username) {
    try {
      const { data } = await status('brompton', username)
      if (data.error) throw data

      data.supplier = 'brompton'

      commit('bikehireStatus', data)

      return data
    } catch (err) {
      const detail = err.data?.detail
      const memberError = { supplier: 'brompton', status: 'Error' }
      if (detail?.includes('No phone number found for user')) {
        memberError.status = 'Not registered'
      } else {
        Notify.create(i18n.t('error.retrieving_brompton'))
      }
      commit('bikehireStatus', memberError)
      return memberError
    }
  },
  setSearchType ({ commit }, type) {
    commit('search', type)
  },
  setRegistrationUrl ({ commit }, url) {
    commit('addRegistrationUrl', url)
  }
}

const mutations = {
  bikehireStatus (state, data) {
    const { supplier } = data
    state.status[supplier] = data
  },
  search (state, type) {
    state.search = type
  },
  addRegistrationUrl (state, url) {
    state.status.brompton.registration_return_url = url
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
