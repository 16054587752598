<template>
  <peek-form
    :title="$t('feedback_form.amend_title')"
    :call-to-action="$t('send')"
    class="concierge-peek"
    @save="submit"
  >
    <div class="contact-form">
      <div class="subtitle">
        {{ $t('feedback_form.amend_text') }}
      </div>
      <label id="booking-reference">
        {{ $t('booking.reference') }}
        <input v-model="booking_reference">
      </label>
      <form @submit.prevent>
        <label>
          {{ $t('feedback_form.detail') }}
          <textarea v-model="detail" :placeholder="$t('feedback_form.amend_placeholder')" />
        </label>
        <label>
          {{ $t('contact.email') }}
          <input v-model="email">
        </label>
        <m-phone-field
          :dialcode="dialcode"
          :phone="phone"
          :borderless="$q.platform.is.mobile"
          @dialcode="val => dialcode = val"
          @phone="val => phone = val"
        />

        {{ $t('tooltip.image_upload_2') }}
        <file-upload
          ref="upload"
          v-model="files"
          :muliple="true"
          :maximum="5"
          accept="image/*"
          class="button"
          role="button"
          @input-filter="inputFilter"
        >
          {{ $t('feedback_form.add_image') }}
        </file-upload>

        <div class="image-display">
          <div
            v-for="(img, i) in files"
            :key="i"
            class="img-card"
          >
            <img :src="img.blob" class="uploaded-img">
            <q-icon name="delete" size="24px" @click="() => removeImage(i)" />
          </div>
        </div>
      </form>
    </div>
  </peek-form>
</template>

<script>
import peekForm from '../peekForm.vue'
import fileUpload from 'vue-upload-component'
import { mapGetters } from 'vuex'
import { post } from 'api/requests'
import { required } from 'vuelidate/lib/validators'
import { handleErrors } from 'utils/utils'
import handlePhoneNumber from 'utils/phone'
import { MPhoneField } from 'components/'

export default {
  name: 'Amend',
  components: { peekForm, fileUpload, MPhoneField },
  data () {
    return {
      email: null,
      dialcode: null,
      phone: null,
      detail: null,
      booking_reference: null,
      content_type: 'other',
      files: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  created () {
    const { email, meta } = this.user
    this.email = email
    const { countryCallingCode, nationalNumber } = handlePhoneNumber(meta.mobile)
    this.dialcode = countryCallingCode
    this.phone = nationalNumber

    this.booking_reference = this.$route.params.booking
    this.content_type = this.$route.params.content
  },
  methods: {
    inputFilter: function (newFile, oldFile, prevent) {
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
        this.$refs.upload.active = 1
      }
    },
    removeImage (index) {
      this.files.splice(index, 1)
    },
    async submit () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify({
          message: this.$t('fill_all_fields'),
          position: 'top-right'
        })
        return
      }
      const params = {
        type: 'amendment',
        content_type: this.content_type,
        requester: this.user.username,
        user: this.user.username,
        message: this.detail,
        contact_number: `+${this.dialcode}${this.phone}`,
        contact_email: this.email,
        booking: this.booking_reference
      }

      const data = new FormData()

      Object.keys(params).forEach(key => {
        data.append(key, params[key])
      })

      if (this.files.length) {
        this.files.forEach(file => {
          data.append('files[]', file.file)
        })
      }

      try {
        const response = await post(data)

        this.$router.push({
          name: 'dashboard',
          params: {
            booking_reference: response.data.reference
          }
        })
      } catch (err) {
        handleErrors(err)
      }
    }
  },
  validations: {
    email: { required },
    phone: { required },
    detail: { required },
    booking_reference: { required }
  }
}
</script>

<style lang="stylus" scoped>
.concierge-peek .q-textarea .q-field__native
  min-height 260px
  -ms-overflow-style none
  scrollbar-width none

.concierge-peek::-webkit-scrollbar
  display none

.contact-form
  display flex
  flex-direction column
  align-items center
  padding-top 10px

form
  border 2px solid #ddd
  width 95%
  display flex
  flex-direction column
  align-items center
  padding 0 2.5%

label
  width 100%
  margin 10px 0
  font-weight 500
  &#booking-reference
    width 95%

select
  margin 10px 0 20px
  background white
  height 36px

textarea, input, select
  border 2px solid #ddd
  border-radius 5px
  width 100%
  padding 5px
  font-weight 400

textarea
  min-height 230px

#image-upload
  display flex
  flex-direction column
  align-items center

.button
  background var(--q-color-primary)
  color white
  border none
  border-radius 5px
  width 50%
  padding 10px
  margin 10px 0

.img-card
  margin-top 12px
  width 100%
  display grid
  grid-template-columns auto 24px
  max-height 128px
  overflow-y hidden
  justify-items center
  align-items center

.img-card:hover .img-overlay
  opacity 1

.uploaded-img
  cursor pointer
  max-width calc(100% - 24px)
  max-height 128px

.subtitle
  align-self flex-start
  margin-left 10px
  font-weight 500
</style>
