import { Notify } from 'quasar'
import i18n from 'i18n'

function isStringKebabCase (str) {
  const regExValdityRule = /^[a-z0-9-]+$/i
  return regExValdityRule.test(str)
}

function notifyUserIfLabelIsInvalid (originalLabel, newLabel) {
  if (isLabelValid(originalLabel)) return
  const actionTaken = newLabel ? i18n.t('label.will_be_replaced', { label: newLabel }) : i18n.t('label.was_removed')
  Notify.create({
    message: i18n.t('error.label_invalid_characters', { label: originalLabel, actionTaken }),
    color: 'orange-9'
  })
}

function isLabelValid (labelValue) {
  if (labelValue) return !!isStringKebabCase(labelValue)
  return null
}

function convertLabelToKebabCase (labelValue) {
  if (!labelValue) return undefined
  const formattedLabel = labelValue.replace(/[^a-z0-9]+/gi, ' ').trim().replace(/ /g, '-')
  notifyUserIfLabelIsInvalid(labelValue, formattedLabel)
  return formattedLabel
}

export default { isLabelValid, convertLabelToKebabCase }
