<template>
  <peek-form
    :title="$t('change_named_address', { address: label })"
    :footer="null"
    :subtitle="$t('tooltip.favourite_address')"
  >
    <q-page padding>
      <q-card class="bg-white">
        <q-card-section>
          <q-input
            v-model="query"
            :label="$t('post_code')"
            icon="place"
            :placeholder="$t('search_for_address')"
          />
        </q-card-section>
        <q-list v-if="results" highlight>
          <div
            v-for="address in results"
            :key="address.value"
          >
            <q-item
              v-ripple
              class="result"
              separator
              clickable
              @click.native="select(address.value)"
            >
              <q-item-section>{{ address.label }}</q-item-section>
            </q-item>
            <q-separator />
          </div>
        </q-list>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import { search, get } from 'api/address'
import peekForm from 'peeks/peekForm.vue'
import { debounce, upperFirst } from 'lodash'

export default {
  components: {
    peekForm
  },
  data () {
    return {
      query: null,
      results: null
    }
  },
  computed: {
    label () {
      return upperFirst(this.$route.query.label)
    }
  },
  watch: {
    query: 'debounceSearch'
  },
  methods: {
    debounceSearch: debounce(function () {
      search(this.query).then(res => {
        this.results = res.data.map(val => ({ label: val.text, value: val.reference }))
      })
    }, 300),

    select (address) {
      get(address, this.label).then(res => {
        this.$store.dispatch('addresses/save', {
          ...res.data,
          address: {
            label: this.label,
            ...res.data.address
          }
        })
          .then(res => {
            this.$q.notify({ type: 'positive', message: this.$t('address_saved', { address: this.label }) })
            this.$router.back()
          })
          .catch(() => {
            this.$q.notify({ type: 'negative', message: this.$t('error.address_not_saved', { address: this.label }) })
          })
      })
    }
  }
}
</script>
