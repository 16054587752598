import { hasRole } from 'utils/authentication'
import store from 'store/modules/carclub'

const meta = {
  permission: 'search.carclub'
}

const beforeEnter = (to, from, next) => {
  !store.state.searchLocation ? next({ name: 'ondemand-carclub' }) : next()
}

export const form = {
  name: 'ondemand-carclub',
  path: '/on-demand/carclub',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/index.vue')
  },
  meta: {
    ...meta,
    label: 'Car Club: Search'
  },
  beforeEnter (to, from, next) {
    hasRole('guest') ? next({ name: 'guest-mode' }) : next()
  }
}

export const results = {
  name: 'ondemand-carclub-results',
  path: '/on-demand/carclub/results',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/results.vue'),
    right: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/sidebar.vue')
  },
  meta: {
    ...meta,
    label: 'Car Club: Vehicles'
  }
}

export const availabilityWindow = {
  name: 'ondemand-carclub-window',
  path: '/on-demand/carclub/window',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/window.vue'),
    right: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/sidebar.vue')
  },
  meta: {
    ...meta,
    label: 'Car Club: Availability'
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-carclub-summary',
  path: '/on-demand/carclub/:token',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/summary.vue'),
    right: () => import(/* webpackChunkName: "ondemand-carclub" */ 'pages/ondemand/carclub/sidebar.vue')
  },
  meta: {
    ...meta,
    label: 'Car Club: Summary'
  },
  beforeEnter
}

export default { form, results, availabilityWindow, summary }
