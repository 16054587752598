<template>
  <peek-form :footer="false" title="Roles">
    <div class="q-pa-md">
      <div class="row q-col-gutter-sm">
        <div class="col">
          <m-group-title label="Existing Roles" sublabel="Roles that have already been created in the system." />
        </div>
        <div class="col-12 col-md-5 col-xl-3 column justify-center">
          <q-btn
            :disable="loading || !hasPermission('roles.create')"
            color="primary"
            label="Create new Role"
            class="full-width"
            dense
            no-caps
            @click="openForm()"
          />
        </div>
      </div>
      <div class="q-my-sm">
        <q-card>
          <q-card-section>
            <q-input v-model="search" :disable="loading" :label="loading ? 'Refreshing roles . . .' : 'Filter'" @input="filter" />
          </q-card-section>
          <q-separator />
          <q-scroll-area style="height: calc(100vh - 250px)">
            <q-list class="bg-white">
              <q-intersection v-for="(role, index) in filteredRoles" :key="role.$id" class="example-item" transition="fade">
                <q-item clickable @click="openForm(role.slug)">
                  <q-item-section>
                    <q-item-label class="text-capitalize">
                      {{ role.name }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ role.slug }}
                    </q-item-label>
                  </q-item-section>
                  <q-item-section class="text-caption">
                    {{ role.description }}
                  </q-item-section>
                  <q-item-section side>
                    <q-btn :disable="!hasPermission('roles.update')" color="primary" dense flat label="Edit" @click="openForm(role.slug)" />
                  </q-item-section>
                  <q-tooltip>
                    {{ role.level }}
                  </q-tooltip>
                </q-item>
                <q-separator v-if="index < filteredRoles.length" />
              </q-intersection>
              <q-item v-if="loading">
                <q-item-section>
                  <q-item-label>
                    <q-skeleton type="text" width="200px" />
                  </q-item-label>
                  <q-item-label caption>
                    <q-skeleton type="text" width="150px" />
                  </q-item-label>
                </q-item-section>
                <q-item-section>
                  <q-skeleton type="text" width="300px" />
                </q-item-section>
                <q-item-section side>
                  <q-skeleton type="QBtn" width="34px" height="24px" />
                </q-item-section>
              </q-item>
            </q-list>
          </q-scroll-area>
        </q-card>
        <m-empty-state v-if="!filteredRoles.length && !loading" />
      </div>
    </div>
  </peek-form>
</template>
<script>
import authentication from 'mixins/authentication'
import { debounce } from 'lodash'
import peekForm from 'peeks/peekForm.vue'
import Role from 'store/models/role'
import { MGroupTitle, MEmptyState } from 'components/'

export default {
  name: 'PeekSystemSettingsRoles',
  components: { peekForm, MGroupTitle, MEmptyState },
  mixins: [ authentication ],
  data () {
    return {
      loading: false,
      search: '',
      filteredRoles: []
    }
  },
  computed: {
    roles () {
      return Role.query().orderBy('level', 'desc').get()
    }
  },
  beforeMount () {
    this.loading = true
    if (this.roles.length) this.filteredRoles = this.roles
    this.$store.dispatch('entities/roles/load').then(() => {
      this.filteredRoles = this.roles
      this.loading = false
    })
  },
  methods: {
    openForm (role = null) {
      if (this.hasPermission('roles.update')) {
        this.$router.push({
          hash: '/system-settings/roles/form',
          query: role ? { role } : null
        })
      }
    },
    filter: debounce(function () {
      this.filteredRoles = []
      this.$nextTick(() => {
        this.filteredRoles = this.roles.filter(role => {
          return role.name.includes(this.search) || role.slug.includes(this.search)
        })
      })
    }, 750)
  }
}
</script>
<style lang="stylus" scoped>
.example-item
  height 55px
  // width 734px
</style>
