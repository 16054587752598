<template>
  <peek-form :title="$t('my_travel_preferences')" :footer="false">
    <div class="q-ma-md q-pa-md">
      <m-group-title :label="$t('transport_preferences')">
        <template v-slot:sublabel>
          <p>{{ $t('enable_disable_transport_preferences') }}</p>
        </template>
      </m-group-title>
      <m-content-type-enable
        :loading="loading"
        :content-types="contentTypes"
        :org-types="orgTypes"
        :user-types="disabledContentTypes"
        :organisation="orgGetter"
        user
        @toggle="toggle"
      />
      <div class="text-body2">
        {{ $t('tooltip.disable_transport_types') }}
      </div>
    </div>
  </peek-form>
</template>
<script>
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import { getTravelPreferences, setTravelPreferences } from 'api/user'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'
import travelContents from 'mixins/travelContents'
import authentication from 'mixins/authentication'
import { MGroupTitle, MContentTypeEnable } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    MContentTypeEnable
  },
  mixins: [authentication, travelContents],
  data () {
    return {
      modify: null,
      disabledContentTypes: [],
      userTypes: [],
      loading: true
    }
  },

  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      authedUser: 'user',
      partner: 'partner'
    }),
    username () {
      return this.$route.query.username || this.authedUser.username
    },
    contentTypes () {
      const partnerTypes = [].concat(...this.partner.mobility, ...this.partner.bookables)
      return partnerTypes
        .filter(type => {
          // Give Backoffice the ability to see all the travel types
          return this.hasRole('backoffice')
            ? true
            : this.hasTypePermission(type)
        })
        .map(type => this.getTravelPreferenceName(type))
    },
    orgTypes () {
      return this.contentTypes.filter(type => this.isPolicyEnabled(type))
    }
  },
  async beforeMount () {
    this.loading = true

    loading.start({
      message: this.$t('loading.getting.your_travel_preferences'),
      partnerSlug: this.partner.slug,
      spinnerColor: this.partner.hex
    })

    await getTravelPreferences({ username: this.username }).then((res) => {
      this.userTypes = res.data
      this.userTypes.forEach(type => {
        if (type.hidden) {
          this.disabledContentTypes.push(type.content)
        }
      })
    }).catch(err => {
      handleErrors(err)
    }).finally(() => {
      loading.stop()
      this.loading = false
    })
  },
  methods: {
    async toggle (type, enabled) {
      loading.start({
        message: this.$t('loading.updating.your_travel_preferences'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      if (enabled) {
        let payload = this.userTypes.find(elem => elem.content === type)
        if (payload) {
          payload.budget = []
          payload.budgets = []
          payload.hidden = true
          payload.preferences = []
        } else {
          payload = {
            budgets: [],
            budget: [],
            content: type,
            hidden: true,
            preferences: []
          }
        }
        await setTravelPreferences(this.username, type, payload)
          .then(() => {
            // Change icon to disabled
            this.disabledContentTypes.push(type)

            this.$store.dispatch('updateSingleTravelPreference', {
              key: type,
              value: payload
            })
          })
      } else {
        let payload = this.userTypes.find(elem => elem.content === type)
        if (payload) {
          payload.budget = []
          payload.budgets = []
          payload.hidden = false
          payload.preferences = []
        } else {
          payload = {
            budgets: [],
            budget: [],
            content: type,
            hidden: false,
            preferences: []
          }
        }

        await setTravelPreferences(this.username, type, payload)
          .then(() => {
            // Change icon to enabled
            this.disabledContentTypes = this.disabledContentTypes.filter(disabledType => disabledType !== type)

            this.$store.dispatch('updateSingleTravelPreference', {
              key: type,
              value: payload
            })
          })
      }
      const contentType = this.$tc(`content_type.${type}`)
      loading.stop()
      this.$q.notify({
        message: this.$t('transport_preference_type_updated', { type: contentType }),
        color: 'positive',
        icon: 'far fa-check-double'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card
  margin 16px 0 !important
</style>
