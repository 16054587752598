import { hasRole } from 'utils/authentication'
let prevRouteMeta = {}

const index = {
  name: 'guest-mode',
  path: '/guest-mode',
  components: {
    default: () => import(/* webpackChunkName: "guest" */ 'pages/unauthed/guestMode.vue')
  },
  meta: {
    layout: 'mobile',
    label: prevRouteMeta?.label || 'Guest Mode',
    back: 'home'
  },
  beforeEnter (to, from, next) {
    if (from.meta) prevRouteMeta = from.meta;
    (!hasRole('guest')) ? next('dashboard') : next()
  }
}

export default { index }
