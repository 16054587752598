import http from './http'

export function index (params) {
  return http.get('/users', { params })
}

export function get (username, params) {
  return http.get(`/users/${username}`, { params })
}

export function put (username, params) {
  return http.put(`/users/${username}`, params)
}

export function search (params) {
  return http.get('/users/search', { params })
}

export function verify (email) {
  return http.get(`/users/verify/resend/${email}`)
}

export function budget (user) {
  return http.get(`/users/${user}/budget`)
}

export function setPaymentsEnabled (user, enabled) {
  if (enabled === undefined) throw new Error('enabled is required')

  if (enabled) return http.post(`/users/${user}/enable-payments`)
  else return http.post(`/users/${user}/disable-payments`)
}
