import http from './http'

export function conciergeRequest (params) {
  return http.post('requests', {
    'content_type': 'onwardtravel',
    type: 'booking',
    ...params
  })
}

export function cost_centres (slug) {
  if (!slug) {
    return Promise.reject(new Error('No organisation slug provided'))
  }
  return http.get(`organisations/${slug}/cost-centres`)
}

export function business_areas (slug) {
  if (!slug) {
    return Promise.reject(new Error('No organisation slug provided'))
  }
  return http.get(`organisations/${slug}/business-areas`)
}

export function searchGdprCustomers (params) {
  return http.get('/transient-customers/search', { params })
}
