<template>
  <peek-form :footer="false" :title="$t('account_settings')">
    <q-page padding>
      <q-list>
        <q-item v-for="item of items" :key="item.title" :to="{hash: item.link}">
          <q-item-section avatar>
            <q-icon color="primary" :name="item.icon" />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ item.title }}</q-item-label>
            <q-item-label caption>
              {{ item.caption }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-page>
  </peek-form>
</template>

<script>
import peekForm from 'peeks/peekForm.vue'
export default {
  components: {
    peekForm
  },
  data () {
    return {
      items: [
        {
          title: this.$t('profile_settings'),
          icon: 'far fa-user',
          link: '/user-settings/personal'
        },
        {
          title: this.$t('update.picture'),
          icon: 'far fa-image',
          link: '/user-settings/picture'
        },
        {
          title: this.$t('change_password'),
          icon: 'far fa-key',
          link: '/user-settings/password'
        }
      ]
    }
  }
}
</script>

<style>

</style>
