import http from './http'

export function nearby (params) {
  return http.get('/locations/nearby', { params })
}

export function sociabilityByLatLng (lat, lng, radius) {
  return http.get(`/sociability/?latitude=${lat}&longitude=${lng}&radius=${radius}`)
}
export function sociabilityByPlaceIds (place_ids) {
  if (typeof place_ids === 'string') place_ids = [place_ids]
  const place_ids_string = '?google_place_ids[]=' + place_ids.join('&google_place_ids[]=')
  return http.get('/sociability/' + place_ids_string)
}

export function distance (origin, destinations, maximum_walking_distance_in_metres = 1600) {
  if (typeof destinations === 'string') destinations = [destinations]
  return http.get('/distance-matrix/destinations', { params: { origin: origin, destinations: destinations.join('|'), maximum_walking_distance_in_metres } })
}
