<template>
  <div class="q-py-sm q-px-md">
    <div v-for="(group, groupInded) in groups" :key="`${group}-${groupInded}`">
      <strong>{{ group.name }}</strong>
      <q-separator />
      <q-list class="q-my-xs">
        <q-item
          v-for="(link, linkIndex) in group.links"
          :key="`${group}-link-${linkIndex}`"
          :to="{
            hash: '/travel-providers',
            query: {
              provider: link.provider
            }
          }"
        >
          <q-item-section side>
            <m-icons-avatar
              v-if="link.iconIsImage"
              :icon="link.icon"
              :is-image="link.iconIsImage"
              :color="link.iconIsImage ? null : getTravelContentColor(link.type)"
              :rounded="!link.iconIsImage"
              size="32px"
            />
            <m-travel-icon
              v-else
              :type="link.type"
              color="white"
              :bg-color="getTravelContentHex(link.type)"
              size="32px"
              circle
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>
              <span>{{ link.name }}</span>
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>
<script>
import { MIconsAvatar, MTravelIcon } from 'components'
import providers from 'mixins/providers.js'
export default {
  name: 'ProviderList',
  components: { MIconsAvatar, MTravelIcon },
  mixins: [providers]
}
</script>
