import { hasRole } from 'utils/authentication'
import { Platform } from 'quasar'
import store from 'store'

export default (to, from, next) => {
  if (hasRole('guest') && !Platform.is.mobile) {
    store.dispatch('logout')
    next({ path: '/' })
  }
  next()
}
