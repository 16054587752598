const discovery = {
  name: 'Discovery',
  path: '/discovery/:type',
  components: {
    default: () => import(/* webpackChunkName: "discovery" */ 'pages/discovery/index.vue')
  },
  props: { default: true }
}

export default { discovery }
