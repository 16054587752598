import http from './http'

export function get () {
  return http.get(`/dashboard`)
}

export function backoffice () {
  return http.get(`/dashboard/backoffice`)
}

export function middleoffice () {
  return http.get(`/dashboard/middleoffice`)
}
