import http from 'api/http'

export function index (params) {
  return http.get('/roles?limit=1000', { params })
}

export function get (slug, params) {
  return http.get(`/roles/${slug}`, { params })
}

export function post (role) {
  return http.post('/roles', role)
}

export function put (slug, role) {
  return http.put(`/roles/${slug}`, role)
}

export function attachPermissions (slug, permissions) {
  return http.put(`/roles/${slug}/permissions`, permissions)
}

export function detachPermissions (slug, permissions) {
  return http.delete(`/roles/${slug}/permissions`, permissions)
}

export function attachToUser (username, slug) {
  return http.post(`/users/${username}/roles/${slug}`)
}

export function removeFromUser (username, slug) {
  return http.delete(`/users/${username}/roles/${slug}`)
}
