<template>
  <peek-form
    :title="$t('organisation.config.hotel.title', { org: organisation ? organisation.name : '' })"
    :footer="true"
  >
    <q-page v-if="resource" padding>
      <m-group-title :label="$t('organisation.config.hotel.hotelzon')" />
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <q-input
            v-model="resource.attributes.hotelzon_target_company_id"
            label="Hotelzon Target Company ID"
          />

          <m-money-field v-model="resource.attributes.travel_policy.hotel.meal_allowance" :label="$t('meal_allowance')" />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('organisation.config.hotel.premierInn')" />
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          TODO: Add Premier Inn config (cell codes)
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import _ from 'lodash'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle, MMoneyField } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    MMoneyField
  },
  mixins: [authentication],
  data () {
    return {
      resource: null, // A copy of the organisation object, used to update the organisation
      unsavedTariffs: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      userOrg: 'organisation'
    }),
    organisation () {
      return this.userIsStaff ? this.orgFromSlug : this.userOrg
    },
    orgFromSlug () {
      return this.$store.getters['organisations/getOrg'](this.$route.query.slug)
    },
    userIsStaff () {
      return this.hasPermission('organisations.index')
    }
  },
  created () {
    if (this.organisation) this.resource = _.cloneDeep(this.organisation)
    else {
      this.$store.dispatch('organisations/loadOrg', { orgId: this.$route.query.slug || this.userOrg.id }).then(resOrg => {
        this.resource = resOrg
      })
    }
  },
  methods: {
    submit () {
      // Clone the resource, so we can make changes to it without affecting the user's visible inputs
      const org = _.cloneDeep(this.resource)

      // If every field of the first address is empty, remove it
      // (I'm not sure why this exists, I wrote it two years ago...)
      const isAddressEmpty = Object.values(org.attributes.addresses[0]).every(x => (x === null || x === ''))
      if (isAddressEmpty) org.attributes.addresses = []

      return this.$store.dispatch('organisations/updateOrg', { org })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
