import store from 'store'

/**
 * Route requires user to be authed before they can navigate to it.
 */
export default async (to, from, next) => {
  if (to.matched.some(record => !record.meta.public) && !store.getters.session.token) {
    return next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  }

  next()
}
