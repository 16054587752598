<template>
  <mango-wrapper>
    <div class="button-container">
      <div
        v-for="text in $t('mango.signin_blurb')"
        :key="text"
        class="text-body2 q-mb-sm"
      >
        {{ text }}
      </div>
      <a :href="mangoSigninLink">
        {{ $t('continue') }}
      </a>
    </div>
  </mango-wrapper>
</template>

<script>
import mangoWrapper from '../mango-wrapper'

export default {
  name: 'MangoSignIn',
  components: { mangoWrapper },
  data () {
    return {
      mangoSigninLink: process.env.VUE_APP_MANGO_SIGNIN
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-container
  color black
  display flex
  flex-direction column
  text-align center
  width 80vw
  margin-inline auto
  margin-block-start 24px
a
  margin-block 8px
  padding-block 8px
  border-radius 8px
  text-transform capitalize
  border none
  background-color var(--q-color-primary)
  color white
</style>
