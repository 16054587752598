import { Model } from '@vuex-orm/core'

export default class Invoice extends Model {
  static entity = 'invoices'

  static primaryKey = 'number'

  static fields () {
    return {
      reference: this.string(),
      number: this.string(),
      booking_references: this.attr([]),
      items: this.attr([]),
      po_number: this.attr(null),
      type: this.string(),
      raised_at: this.attr({}),
      gross_amount: this.attr({}),
      net_amount: this.attr({}),
      tax_amount: this.attr({}),
      organisation: this.attr({}),
      user: this.attr({})
    }
  }
}
