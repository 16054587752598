/**
 * Import required components, directives and plugins for quasar, only selecting what we use saves on build times,
 * but if necessary when deving there is an option to enable everything.
 */
import 'quasar/dist/quasar.ie.polyfills'
import lang from 'quasar/lang/en-gb.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/mdi-v5/mdi-v5.css'
import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/light.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/duotone.css'

import {
  BottomSheet,
  QSpace,
  ClosePopup,
  Dialog,
  Notify,
  QAvatar,
  QBanner,
  QBtn,
  QBtnDropdown,
  QCard,
  QCardActions,
  QCardSection,
  QCarousel,
  QCarouselSlide,
  QCheckbox,
  QChip,
  QDate,
  QDialog,
  QDrawer,
  QExpansionItem,
  QFab,
  QFabAction,
  QField,
  QFooter,
  QHeader,
  QIcon,
  QImg,
  QInfiniteScroll,
  QInnerLoading,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QLayout,
  QList,
  QMenu,
  QOptionGroup,
  QPage,
  QPageContainer,
  QPageSticky,
  QPopupProxy,
  QRadio,
  QRange,
  QRating,
  QRouteTab,
  QScrollArea,
  QSelect,
  QSeparator,
  QSlider,
  QSpinner,
  QSpinnerDots,
  QStep,
  QStepper,
  QStepperNavigation,
  QTab,
  QTable,
  QTabPanel,
  QTabPanels,
  QTabs,
  QTd,
  QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  QTr,
  Quasar,
  Ripple,
  Screen,
  QTime,
  QTimeline,
  QTimelineEntry,
  QBadge,
  QLinearProgress,
  QBtnToggle,
  QMarkupTable,
  QBtnGroup,
  Loading,
  QBreadcrumbs,
  QBreadcrumbsEl,
  QColor,
  QIntersection,
  QSkeleton,
  Scroll,
  QScrollObserver,
  QForm
} from 'quasar'

/**
 * Configure anything related to quasar below here
 */
Notify.setDefaults({
  position: 'bottom',
  icon: 'fas fa-exclamation-circle'
})
/**
 * Export our plugin install method
 */
export default ({ Vue }) => {
  Vue.use(Quasar, {
    config: {
      notify: {

      }
    },
    components: {
      QScrollObserver,
      ClosePopup,
      Dialog,
      Notify,
      QAvatar,
      QBanner,
      QBtn,
      QBtnDropdown,
      QCard,
      QCardActions,
      QCardSection,
      QCarousel,
      QCarouselSlide,
      QCheckbox,
      QChip,
      QDate,
      QDialog,
      QDrawer,
      QExpansionItem,
      QFab,
      QFabAction,
      QField,
      QFooter,
      QHeader,
      QIcon,
      QImg,
      QInfiniteScroll,
      QInnerLoading,
      QInput,
      QItem,
      QItemLabel,
      QItemSection,
      QLayout,
      QList,
      QMenu,
      QOptionGroup,
      QPage,
      QPageContainer,
      QPageSticky,
      QPopupProxy,
      QRadio,
      QRange,
      QRating,
      QRouteTab,
      QScrollArea,
      QSelect,
      QSeparator,
      QSlider,
      QSpinner,
      QSpinnerDots,
      QStep,
      QStepper,
      QStepperNavigation,
      QTab,
      QTable,
      QTabPanel,
      QTabPanels,
      QTabs,
      QTd,
      QToggle,
      QToolbar,
      QToolbarTitle,
      QTooltip,
      QTr,
      Quasar,
      Ripple,
      Screen,
      QSpace,
      QTime,
      QTimeline,
      QTimelineEntry,
      QBadge,
      QLinearProgress,
      QBtnToggle,
      QMarkupTable,
      QBtnGroup,
      QBreadcrumbs,
      QBreadcrumbsEl,
      QColor,
      QIntersection,
      QSkeleton,
      QForm
    },
    directives: {
      Ripple,
      ClosePopup,
      Scroll
    },
    plugins: {
      Screen,
      Notify,
      Dialog,
      BottomSheet,
      Loading
    },
    lang: lang
  })
}
