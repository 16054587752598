import date from 'utils/date-time'
const { addToDate } = date

export default function (route) {
  return {
    traveller: null,
    requester: null,
    pickup: {
      location: null,
      date: addToDate(date.newDate(), { minutes: 5 }),
      ...route.params.pickup
    },
    dropoff: {
      visible: route.params.dropoff || false,
      location: null,
      date: addToDate(date.newDate(), { days: 1, minutes: 5 }),
      ...route.params.dropoff
    }
  }
}
