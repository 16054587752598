import { Dialog } from 'quasar'
import date from 'utils/date-time'
import { refresh } from 'api/login'

export default {
  watch: {
    '$store.state.authentication.expires': {
      immediate: false,
      handler (timestamp, old) {
        window.clearTimeout(this.timeoutId)
        if (!timestamp) {
          return
        }
        /**
         * We will attempt to reauth 10s from the expiry time
         */
        let reauthIn = (date.getDateDiff(new Date(timestamp), new Date()).values.milliseconds) - 10000
        this.timeoutId = window.setTimeout(async () => {
          window.clearTimeout(this.timeoutId)
          try {
            await refresh()
          } catch {
            this.$router.push({ name: 'Logout' }, () => {}, () => {
              this.$router.push({ path: '/login', query: { redirect: this.$route.path } }, () => {
                Dialog.create({
                  noBackdropDismiss: true,
                  noEscDismiss: true,
                  title: 'Session time-out',
                  message: 'You have been logged out due to inactivity.',
                  buttons: ['Ok']
                })
              })
            })
          }
        }, reauthIn)
      }
    }
  }
}
