<template>
  <mango-wrapper>
    <div v-if="!loading" class="button-container">
      <div
        v-for="text in $t('mango.blurb')"
        :key="text"
        class="text-body2 q-mb-sm"
      >
        {{ text }}
      </div>
      <button @click="$router.push({ hash: '/mango/signup' })">
        {{ $t('mango.signup') }}
      </button>
      <div class="text-body2 q-mt-sm">
        {{ $t('travel_providers.signin_prompt') }}
      </div>
      <button @click="$router.push({ hash: '/mango/signin' })">
        {{ $t('mango.signin') }}
      </button>
    </div>
  </mango-wrapper>
</template>

<script>
import mangoWrapper from './mango-wrapper.vue'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

export default {
  name: 'MangoPeek',
  components: {
    mangoWrapper
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      user: 'user',
      mangoUser: 'mango/getCredentials'
    })
  },
  async mounted () {
    if (!this.mangoUser) {
      this.loading = true
      try {
        loading.start({
          partnerSlug: this.partner.slug,
          spinnerColor: this.partner.hex,
          message: this.$t('loading.checking.your_details')
        })

        const hasMango = await this.$store.dispatch(
          'mango/fetchCredentials',
          this.user.username
        )
        if (hasMango) {
          this.$router.replace({ hash: '/mango/wallet' })
        }
      } catch (err) {
        handleErrors(err)
      } finally {
        this.loading = false
        loading.stop()
      }
    } else {
      this.$router.replace({ hash: '/mango/wallet' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.button-container
  color black
  display flex
  flex-direction column
  text-align center
  width 80vw
  margin-inline auto
  margin-block-start 24px
  & button
    margin-block 8px
    padding-block 8px
    border-radius 8px
    text-transform capitalize
    color var(--q-color-primary)
    border 1px solid
    border-color var(--q-color-primary)
    background-color white
  & button:first-of-type
    border none
    background-color var(--q-color-primary)
    color white
</style>
