import { cloneDeep } from 'lodash'
// import { get as getWeather } from 'api/weather'
import dt from 'utils/date-time'

const initial = {
  location: null,
  lastUpdated: null,
  current: null,
  searching: false,
  coords: {
    lat: null,
    lng: null
  }
}

const state = cloneDeep(initial)

const getters = {
  all: state => state,
  lastUpdated: state => state.lastUpdated,
  current: state => state.current,
  location: state => state.location
}

const actions = {
  updateWeatherInformation ({ commit, state }, { lat, lng }) {
    return false
    /**
     * TODO: re-enable once API has replaced Darksky with new weather provider
     * if (!lat || !lng) throw new Error('Missing Lat/Lng param(s)! Cannot continue to lookup weather without this information.')
     * const sameLatLng = (String(lat).startsWith(state.coords.lat) && String(lng).startsWith(state.coords.lng))
     *
     * if (sameLatLng && state.lastUpdated) {
     *     const timeWhenWeCanNextRefresh = dt.addToDate(state.lastUpdated, { minutes: 10 })
     *     const canWeUpdateNow = dt.getDateDiff(dt.newDate(), timeWhenWeCanNextRefresh)
     *     if (canWeUpdateNow < 0) return false
     *   }
     *
     *   state.searching = true
     *   commit('storeLatLng', { lat, lng })
     *   getWeather({ latitude: lat, longitude: lng })
     *     .then(({ data }) => {
     *         commit('updateWeatherInformation', data)
     *       })
     *       .finally(() => {
     *           state.searching = false
     *         })
     */
  }
}

const mutations = {
  storeLatLng (state, coords) {
    Object.keys(coords).forEach(key => {
      coords[key] = Number(coords[key]).toFixed(3)
    })
    state.coords = coords
  },
  updateWeatherInformation (state, data) {
    state.location = data.location
    state.current = data.now
    state.lastUpdated = dt.newDate()
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
