<template>
  <peek-form
    title="Create credit invoice"
    footer-title
    call-to-action="Create credit note"
    :disable-call-to-action="selectedInvoices.length < 1 || salesLines.length < 1"
    @save="openConfirmation"
  >
    <q-page padding>
      <m-group-title
        label="Invoices to be credited"
        sublabel="Please make sure these are correct"
      />
      <q-list class="q-mt-sm" bordered separator>
        <invoice-card
          v-for="(invoice, index) in selectedInvoices"
          :key="`invoice${index}`"
          :value="invoice"
          :index="index"
          :inset="selectedInvoices.length > 1"
        />
      </q-list>
      <q-space class="q-mt-lg" />
      <m-group-title label="Create Credit lines" />
      <sales-lines v-model="salesLines" @updateLines="updateLines" />
      <confirmation-dialog
        :show-dialog="confirmationModal"
        :sales-lines="salesLines"
        :invoices-to-credit="selectedInvoices"
        @closeDialog="confirmationModal = false"
      />
    </q-page>
  </peek-form>
</template>

<script>
import peekForm from 'peeks/peekForm'
import salesLines from './sales-lines'
import invoiceCard from './invoice-card'
import confirmationDialog from './confirmation-dialog'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    salesLines,
    invoiceCard,
    confirmationDialog
  },
  mixins: [authentication],
  data () {
    return {
      confirmationModal: false,
      salesLines: []
    }
  },
  computed: {
    ...mapGetters({
      selectedInvoices: 'finance/getInvoicesToCredit'
    })
  },
  created () {
    if (
      !this.hasPermission('can.create.creditinvoices') ||
      this.selectedInvoices.length < 1
    ) { this.$router.go(-1) }
  },
  methods: {
    updateLines (lines) {
      this.salesLines = lines
    },
    openConfirmation () {
      this.confirmationModal = true
    }
  }
}
</script>
