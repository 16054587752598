<template>
  <div v-if="!loading">
    <q-chip
      v-if="!readonly && hasPermission('permissions.attach.roles')"
      color="primary"
      square
      clickable
    >
      <q-icon name="add" size="xs" />
      <q-menu v-model="showingMenu">
        <q-input v-model="search" class="q-mx-sm" label="Filter roles" />
        <q-separator />
        <q-list separator>
          <q-item v-for="role in availableRolesToAttach" :key="role.slug" clickable @click="selectRole(role.slug)">
            <q-item-section class="text-capitalize">
              {{ role.name }}
            </q-item-section>
            <q-item-section side class="text-caption">
              {{ role.slug }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-menu>
      <div v-if="!selectedRoles.length">
        Attach new role
      </div>
    </q-chip>
    <q-chip
      v-for="role in selectedRoles"
      :key="role"
      :label="role"
      square
      :dense="dense"
      :removable="!readonly"
      @remove="removeRole(role)"
    />
  </div>
  <div v-else>
    Loading . . .
  </div>
</template>
<script>
import authentication from 'mixins/authentication'
import Role from 'store/models/role'
export default {
  mixins: [authentication],
  props: {
    value: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      search: '',
      loading: false,
      showingMenu: false
    }
  },
  computed: {
    selectedRoles: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      }
    },
    allRoles () {
      return Role.all()
    },
    availableRolesToAttach () {
      return this.allRoles.filter(role => {
        return !this.selectedRoles.includes(role.slug) && (role.name.includes(this.search) || role.slug.includes(this.search))
      })
    }
  },
  beforeMount () {
    this.loading = true
    const roles = this.value.roles
    if (roles) this.selectedRoles = roles
    if (!this.readonly) this.$store.dispatch('entities/roles/load').then(() => { this.loading = false })
    else this.loading = false
  },
  methods: {
    selectRole (slug) {
      if (slug) {
        this.selectedRoles.push(slug)
        this.$emit('addition', slug)
        this.$nextTick(() => {
          this.showingMenu = true
        })
      }
    },
    removeRole (slug) {
      if (slug) {
        this.selectedRoles = this.selectedRoles.filter(role => role !== slug)
        this.$emit('removal', slug)
      }
    }
  }
}
</script>
