import { Model } from '@vuex-orm/core'

export default class Booking extends Model {
  static entity = 'bookings'

  static primaryKey = 'reference'

  static fields () {
    return {
      attributes: this.attr({}),
      booked_at: this.attr({}),
      cancelled_at: this.attr(),
      created_at: this.attr({}),
      details: this.attr([]),
      driver_details: this.attr(),
      journey: this.attr({}),
      lookups: this.attr(),
      name: this.string(''),
      price: this.attr({}),
      reference: this.string(''),
      starts_at: this.attr({}),
      status: this.string(''),
      summary: this.attr({}),
      type: this.string(''),
      updated_at: this.attr({}),
      user: this.attr({}),
      requester: this.attr({}),
      notes: this.attr([]),
      labels: this.attr([]),
      requests: this.attr(),
      passenger_name: this.attr({}),
      travel_type: this.string(''),
      supplier_reference: this.string('')
    }
  }
}
