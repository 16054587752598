import { get, put, post, createNote, createPayment, createInvoice, createBooking, amendBooking, cancelBooking, assignUser } from 'api/requests'
import { index, errors, amended, bookedToday } from 'api/pots'
import _ from 'lodash'
import { Notify } from 'quasar'
import http from 'api/http'

const initialState = {
  selectedResourceId: null,
  index: {},
  potEntries: {}
}

const state = _.cloneDeep(initialState)

const getters = {
  getResource: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getPotEntry: state => id => {
    return _.cloneDeep(state.potEntries[id])
  },
  getPotEntries: state => type => {
    if (typeof type === 'string') type = [type]
    const entries = _.values(_.cloneDeep(state.potEntries))
    const filteredEntries = _.filter(entries, p => type.includes(p.content_type))
    const splitEntries = _.groupBy(filteredEntries, p => p.status === 'BOOKED' ? 'bookings' : 'requests')
    return (splitEntries.requests || []).concat(splitEntries.bookings || [])
  },
  getSelectedResource: state => {
    return _.cloneDeep(state.potEntries[state.selectedResourceId])
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedResourceId ({ commit }, resourceId) {
    commit('setSelectedResourceId', resourceId)
  },
  loadResources ({ commit, getters }, params) {
    return index(params)
      .then(res => {
        commit('loadResourcesSuccess', res.data)
        return res.data
      })
      .catch(e => {
        commit('loadResourcesFailure', e)
      })
  },
  loadErrors ({ commit, getters }, params) {
    return errors(params)
      .then(res => {
        commit('loadResourcesSuccess', res.data)
        return res.data
      })
      .catch(e => {
        commit('loadResourcesFailure', e)
      })
  },
  loadAmended ({ commit, getters }, params) {
    return amended(params)
      .then(res => {
        commit('loadResourcesSuccess', res.data)
        return res.data
      })
      .catch(e => {
        commit('loadResourcesFailure', e)
      })
  },
  loadBookedToday ({ commit, getters }, params) {
    return bookedToday(params)
      .then(res => {
        commit('loadResourcesSuccess', res.data)
        return res.data
      })
      .catch(e => {
        commit('loadResourcesFailure', e)
      })
  },
  loadResource ({ commit }, { id }) {
    return get(id)
      .then(res => {
        commit('loadResourceSuccess', res.data)
      })
      .catch(e => {
        commit('loadResourceFailure', e)
      })
  },
  createResource ({ commit }, params) {
    return post(params)
      .then(res => {
        commit('createResourceSuccess', res.data)
        Notify.create({
          message: `Request created`,
          color: 'positive',
          icon: 'mdi-check'
        })
        return res.data
      })
      .catch(e => {
        Notify.create({
          message: `Request not created, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('createResourceFailure', e)
      })
  },
  updateResource ({ commit }, resource) {
    return put(resource.id, resource.meta)
      .then(res => {
        commit('updateResourceSuccess', res.data)
        Notify.create({
          message: `Request updated`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: `Request not updated, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('updateResourceFailure', e)
      })
  },
  addNote ({ commit, getters }, params) {
    return createNote(params.request, params.note)
      .then(res => {
        const resource = getters.getResource(params.request)
        commit('addNoteSuccess', {
          resource,
          note: res.data
        })
        Notify.create({
          message: `Note added to request`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: `Note not added to request, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('addNoteFailure', e)
      })
  },
  addPayment ({ commit, getters }, params) {
    return createPayment(params.request, params.payment)
      .then(res => {
        const resource = getters.getResource(params.request)
        commit('addPaymentSuccess', {
          resource,
          payment: res.data
        })
        Notify.create({
          message: `Payment added to request`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: `Payment not added to request, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('addPaymentFailure', e)
      })
  },
  addInvoice ({ commit, getters }, params) {
    return createInvoice(params.request, params.invoice)
      .then(res => {
        const resource = getters.getResource(params.request)
        commit('addInvoiceSuccess', {
          resource,
          invoice: res.data
        })
        Notify.create({
          message: `Invoice added to request`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: `Invoice not added to request, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('addInvoiceFailure', e)
      })
  },
  createBooking ({ commit, getters }, params) {
    return createBooking(params.requestReference, params.booking)
      .then(res => {
        commit('createBookingSuccess', {
          booking: res.data
        })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  amendBooking ({ commit, getters }, params) {
    return amendBooking(params.reference, params.bookingReference, params.booking)
      .then(res => {
        commit('amendBookingSuccess', {
          booking: res.data
        })
      })
      .catch(e => {
        return Promise.reject(e)
      })
  },
  cancelBooking ({ commit, getters }, params) {
    return cancelBooking(params.reference, params.bookingReference, params.booking)
      .catch(e => {
        return Promise.reject(e)
      })
  },
  setAssignee ({ commit, getters }, params) {
    return assignUser(params.requestReference, params.user.username)
      .then(res => {
        commit('setAssigneeSuccess', {
          potEntry: getters.getPotEntry(params.requestReference),
          resource: getters.getResource(params.requestReference),
          assignee: params.user
        })
        Notify.create({
          message: `User assigned`,
          color: 'positive',
          icon: 'mdi-check'
        })
      })
      .catch(e => {
        Notify.create({
          message: `User not assigned, error occurred`,
          color: 'negative',
          icon: 'clear'
        })
        commit('setAssigneeFailure', e)
      })
  },
  setState ({ commit, getters }, params) {
    http.post(`/request/${params.reference}/state`, params.state)
      .then(() => {
        commit('setStateSuccess', {
          request: getters.getResource(params.reference),
          status: params.state.name
        })
      })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedResourceId (state, id) {
    state.selectedResourceId = id
  },

  loadResourcesSuccess (state, resources) {
    const indexed = _.keyBy(resources, m => m.reference)
    state.potEntries = { ...state.potEntries, ...indexed }
  },
  loadResourcesFailure (state, error) {
    throw Error(error)
  },

  loadResourceSuccess (state, resource) {
    state.index = { ...state.index, [resource.reference]: resource }
  },
  loadResourceFailure (state, error) {
    throw Error(error)
  },

  createResourceSuccess (state, resource) {
    state.index = { ...state.index, [resource.reference]: resource }
  },
  createResourceFailure (state, error) {
    throw Error(error)
  },

  updateResourceSuccess (state, resource) {
    state.index = { ...state.index, [resource.reference]: resource }
  },
  updateResourceFailure (state, error) {
    throw Error(error)
  },

  addNoteSuccess (state, { resource, note }) {
    resource.notes.unshift(note)
    state.index = { ...state.index, [resource.reference]: resource }
  },
  addNoteFailure (state, error) {
    throw Error(error)
  },

  addPaymentSuccess (state, { resource, payment }) {
    resource.payments.unshift(payment)
    state.index = { ...state.index, [resource.reference]: resource }
  },
  addPaymentFailure (state, error) {
    throw Error(error)
  },

  addInvoiceSuccess (state, { resource, invoice }) {
    resource.invoices.unshift(invoice)
    state.index = { ...state.index, [resource.reference]: resource }
  },
  addInvoiceFailure (state, error) {
    throw Error(error)
  },

  setAssigneeSuccess (state, { potEntry, resource, assignee }) {
    if (potEntry) {
      potEntry.assignee = assignee
      state.potEntries = { ...state.potEntries, [potEntry.reference]: potEntry }
    }

    if (resource) {
      resource.assignee = assignee
      state.index = { ...state.index, [resource.reference]: resource }
    }
  },
  setAssigneeFailure (state, error) {

  },

  setStateSuccess (state, { request, status }) {
    request.status = status.toUpperCase()
    state.index = { ...state.index, [request.reference]: request }
  },

  amendBookingSuccess (state, { resource, payment }) {

  },
  amendBookingFailure (state, error) {
    throw Error(error)
  },

  cancelBookingSuccess (state, { resource, payment }) {

  },
  cancelBookingFailure (state, error) {
    Promise.reject(error)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
