/**
 * Automatically import a defined context of dependencies
 * https://webpack.js.org/guides/dependency-management/
 * @param {*} context
 * @param {*} fn
 */
export default function autoImport (context, fn) {
  context.keys().forEach(fileName => {
    fn(context, fileName)
  })
}
