import http from './http'

export function index () {
  return http.get('/labels?limit=1000')
}

export function search (query) {
  return http.get(`/labels?limit=1000&query=${query}`)
}

export function update (id, label) {
  return http.put(`/labels/${id}`, label)
}

export function create (label) {
  return http.post(`/labels`, label)
}

export function destroy (labelId) {
  return http.delete(`/labels/${labelId}`)
}

export function attach (resource, reference, labels) {
  return http.put(`/${resource}/${reference}/labels`, {
    labels: labels
  })
}

export function detach (resource, reference, labels) {
  return http.delete(`/${resource}/${reference}/labels`, {
    labels: labels
  })
}
