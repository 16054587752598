import { refresh } from 'api/login'
import store from 'store'
/**
 * Route requires use to be authed before they can navigate to it.
 */
export default (to, from, next) => {
  if (to.query.switchertoken) {
    store.dispatch('logout', false)
    store.dispatch('saveAuthToken', to.query.switchertoken)

    return refresh()
      .then(res => {
        store.dispatch('saveAuthUser', res.data)
        next()
      })
      .catch(() => {
        history.back()
      })
  }
  next()
}
