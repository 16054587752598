/**
 * This file contains all the colours we deem "worthy" of being shown
 * in components, such as the colour picker.
 */
import { textStringContrast } from 'utils/utils'

export default {
  data () {
    return {
      colourPalette: {
        'f44336': 'red',
        'e91e63': 'pink',
        '9c27b0': 'purple',
        '673ab7': 'deep-purple',
        '3f51b5': 'indigo',
        '2196f3': 'blue',
        '03a9f4': 'light-blue',
        '00bcd4': 'cyan',
        '009688': 'teal',
        '4caf50': 'green',
        '8bc34a': 'light-green',
        'cddc39': 'lime',
        'ffeb3b': 'yellow',
        'ffc107': 'amber',
        'ff9800': 'orange',
        'ff5722': 'deep-orange',
        '607d8b': 'blue-grey',
        'e0e0e0': 'grey-4'
      }
    }
  },
  methods: {
    lookupHex (hex) {
      if (hex) return this.colourPalette[hex.replace('#', '')]
    },
    textStringContrast
  }
}
