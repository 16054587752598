import http from './http'

export function routes (params) {
  return http.get('/ferry-routes', { params })
}

export function availability (routecode, params) {
  return http.get(`/ferry-routes/${routecode}/availability?return=1`, { params })
}

export function search (params) {
  return http.get(`/search/ferries`, { params })
}

export function accommodation (token, params) {
  return http.post(`/search/ferries/accommodation/${token}`, params)
}

export function passengers (token, params) {
  return http.post(`/search/ferries/details/${token}`, params)
}

export function summary (token, params) {
  // return Promise.resolve({ data: ferrySummaryData })
  return http.post(`/search/ferries/summary/${token}`, params)
}

export function countries (params) {
  return http.get('/ferry-countries', { params })
}

export function setCredentials (params) {
  return http.post('/ferry/credentials', params)
}

export function removeCredentials () {
  return http.delete('ferry/credentials?supplier=northlink')
}
