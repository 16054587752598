/**
 * The branding plugin handles the themeing of the app, with changes such as colours and app title.
 */
import { colors } from 'quasar'
import { textContrast } from 'utils/utils'
import { getPartnerFromHostname, getPartnerFromLabel } from 'utils/partner'

const baseBucket = 'https://mobilleo-assets.s3.eu-west-2.amazonaws.com/favicons'

/**
 * Set Theme Color for the app.
 * Defined as it's own function, rather than in export, so that it can be called externally (i.e. devtools)
 * @param {string} [partnerSlug=mobilleo] The slug of the partner to use.
 */
function setTheme (partnerSlug = 'mobilleo') {
  const { title, hex, slug } = getPartnerFromLabel(partnerSlug)
  const bucketURL = `${baseBucket}/${slug}`

  // Set the app title
  document.title = title

  // Dynamically set the primary colour palette of the app, based on the partner.
  // NOTE: Currently setup to only support light themes.
  // TODO: Add support for dark themes. (darker colours)
  colors.setBrand('primary', hex)
  colors.setBrand('tint', colors.lighten(hex, 10))
  colors.setBrand('selected', colors.lighten(hex, 80))
  colors.setBrand('contrast', textContrast(hex))

  // Manifest/Color configuration
  document.querySelector('link[rel="manifest"]').href = `${bucketURL}/site.webmanifest`
  document.querySelector('[name="theme-color"]').content = hex
  document.querySelector('[name="msapplication-TileColor"]').content = hex

  // Set the favicons for the app
  document.querySelectorAll('link[rel="icon"]')[0].href = `${bucketURL}/favicon-16x16.png`
  document.querySelectorAll('link[rel="icon"]')[1].href = `${bucketURL}/favicon-32x32.png`
  document.querySelector('link[rel="mask-icon"]').href = `${bucketURL}/safari-pinned-tab.svg`
  document.querySelector('link[rel="apple-touch-icon"]').href = `${bucketURL}/apple-touch-icon.png`

  // TODO: investigate whether this is still necessary, possibly redundant from previous code.
  document.body.classList.add('loaded')
}

export default () => {
  const partnerSlug = getPartnerFromHostname().slug
  setTheme(partnerSlug)

  // Make setTheme available in the global scope, so that it can be called externally (i.e. devtools)
  window.setTheme = setTheme
}
