/*
 * Exports parsePhoneNumber functionality with
 * backwards compatibility for older phone styles
 */

import parsePhoneNumber from 'libphonenumber-js'

export const phoneTemplate = {
  nationalNumber: null,
  countryCallingCode: '44',
  country: 'GB',
  number: null,
  formatNational: () => null,
  formatInternational: () => null,
  isValid: () => false,
  isPossible: () => false
}

export default function handlePhoneNumber (phone) {
  if (typeof phone !== 'string') return { ...phoneTemplate }
  // If it starts with a 0, assume it's a UK number
  if (phone.startsWith('0')) phone = `+44${phone.slice(1)}`
  // If it doesn't start with a +, assume it's just missing
  if (!phone.startsWith('+')) phone = `+${phone}`
  // If it doesn't parse properly, return a default template
  const parsedNumber = parsePhoneNumber(phone)
  return parsedNumber || { ...phoneTemplate }
}

export function formatNational (phone) {
  const parsedPhone = handlePhoneNumber(phone)
  if (!parsedPhone) return null
  return parsedPhone.formatNational()
}

export function nationalNumber (phone) {
  const parsedPhone = handlePhoneNumber(phone)
  if (!parsedPhone) return null
  return parsedPhone.nationalNumber
}
