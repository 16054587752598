import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') ? next() : next('/dashboard')
}

export const index = {
  name: 'quote-builder',
  path: '/quote-builder',
  components: {
    default: () => import(/* webpackChunkName: "quote-builder" */'pages/quote-builder/index.vue')
  },
  meta: {
    layout: 'blank',
    permission: 'can.view.quote.builder'
  },
  beforeEnter
}

export default { index }
