<template>
  <q-field
    ref="field"
    :label="label"
    :error="error"
    :error-message="errorMessage"
    stack-label
    :rounded="rounded"
    :outlined="outlined"
    :borderless="borderless"
    class="q-pb-none"
    :readonly="fieldIsReadOnly"
    @blur="$emit('blur')"
    @click.native.stop="showDateProxy"
    @keyup.native.enter="showDateProxy"
  >
    <template v-slot:control>
      <div class="self-center full-width no-outline" tabindex="0">
        {{ readOnlyValue || displayDate }}
      </div>
    </template>
    <template v-slot:append>
      <q-icon v-if="!fieldIsReadOnly" color="primary" :name="useDropdown ? 'arrow_drop_down' : 'event'" class="cursor-pointer">
        <q-popup-proxy ref="dateProxy">
          <q-date
            v-model="date"
            color="primary"
            :landscape="$q.screen.gt.sm"
            :today-btn="showTodayButton"
            :options="date => (minDate ? date >= minDate.replace(/-/g, '/') : true) && (maxDate ? date <= maxDate.replace(/-/g, '/') : true)"
            mask="YYYY-MM-DD HH:mm"
            @input="onChange"
          />
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-field>
</template>

<script>
import date from 'utils/date-time'

export default {
  props: {
    label: String,
    format: {
      type: String,
      default: 'DDDD'
    },
    value: {
      type: [Object, String, Date]
    },
    min: {
      type: [Object, String, Date]
    },
    max: {
      type: [Object, String, Date]
    },
    error: Boolean,
    errorMessage: String,
    modal: Boolean,
    civil: Boolean,
    color: {
      type: String,
      default: '#394154'
    },
    useDropdown: Boolean,
    readonly: Boolean,
    rounded: Boolean,
    outlined: Boolean,
    borderless: Boolean,
    readOnlyValue: String
  },
  computed: {
    date: {
      get () {
        return this.value ? date.toCivilDate(this.value) : null
      },
      set (val) {
        const newDate = this.constructDate(val)
        this.$emit('input', newDate)
        this.$emit('change', newDate)
      }
    },
    minDate () {
      if (!this.min) return
      const d = date.newDate(this.min)
      return d.isValid ? date.toCivilDate(d) : null
    },
    maxDate () {
      if (!this.max) return
      const d = date.newDate(this.max)
      return d.isValid ? date.toCivilDate(d) : null
    },
    displayDate () {
      return this.value ? date.luxonFormatDate(date.newDate(this.value), this.$q.platform.is.mobile ? 'dd/LL/yy' : this.format) : null
    },
    showTodayButton () {
      return (date.getDateDiff(date.newDate(), this.minDate, 'days').values.days >= 0)
    },
    fieldIsReadOnly () {
      return this.readonly || !!this.readOnlyValue
    }
  },
  methods: {
    showDateProxy () {
      if (this.fieldIsReadOnly) return
      this.$refs.dateProxy.show()
    },
    constructDate (val) {
      const newDate = date.newDate(val)
      const existingDateTime = date.newDate(this.value)
      const hour = this.value && existingDateTime.isValid ? existingDateTime.hour : 0
      const minute = this.value && existingDateTime.isValid ? existingDateTime.minute : 0
      const second = this.value && existingDateTime.isValid ? existingDateTime.second : 0
      let luxon = date.adjustDate(newDate, { hour, minute, second })
      return this.civil
        ? date.toCivilDate(luxon)
        : luxon
    },
    onChange (val) {
      this.$refs.dateProxy.hide()
      this.$refs.field.focus()
    }
  }
}
</script>

<style>

</style>
