import http from './http'

export function stations (params) {
  return http.get(`/stations`, { params })
}

export function search (params) {
  return http.get(`/search/trains`, { params })
}

export function editSearch (search, leg, direction, params) {
  return http.get(`/search/trains/${search}/${leg}/${direction}`, { params })
}

export function tickets (token, outboundId, returnId, excludeGroupsave) {
  const data = {
    params: {
      exclude_groupsave: excludeGroupsave || false
    }
  }

  if (returnId) data.params['return_journey_id'] = returnId

  return http.get(`/search/trains/tickets/${token}/${outboundId}`, data)
}

export function summary (token, params) {
  if (params) {
    return http.post(`/search/trains/${token}`, params)
  }

  return http.get(`/search/trains/${token}`)
}

export function railcards (params) {
  return http.get(`/railcards`, { params })
}

export function departures (code, params) {
  return http.get(`/rail/stations/${code}/timetable`, { params })
}
