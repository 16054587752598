<template>
  <peek-form
    title="Debug Menu"
    :footer="false"
  >
    <m-banner
      title="This debug menu is for developers/admins only."
      message="If this is seen by a normal user, it is a bug. Please report it ASAP."
      borderless
      :rounded="false"
      color="orange-12"
    />
    <q-page padding>
      <m-group-title label="Native Store" sublabel="Show the currently stored values of native store" />
      <q-card class="q-mb-xl">
        <q-card-actions>
          <q-btn
            label="Reset Store"
            color="warning"
            @click="$store.dispatch('native/reset')"
          />
        </q-card-actions>
        <q-card-section>
          <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
          <div class="jsonPreview">{{ stringifiedNativeAll }}</div>
        </q-card-section>
      </q-card>
      <m-group-title label="Sentry" sublabel="Stuff for prodding and poking sentry" />
      <q-card>
        <q-card-section>
          <div class="row q-col-gutter-sm">
            <div class="col flex items-center">
              <q-btn
                class="full-width"
                label="Trigger Error"
                color="warning"
                @click="sentryTriggerError"
              />
            </div>
            <div class="col">
              <q-select
                v-model="sentrySelectedErrorType"
                :options="sentryErrorTypes"
                dense
                :hint="sentryModeHints[sentrySelectedErrorType]"
              />
            </div>
          </div>
        </q-card-section>
        <q-card-section>
          <div class="jsonPreview">
            <div>Current Sentry DSN: {{ sentryDSN }}</div>
            <div>Current Sentry Release: {{ sentryRelease }}</div>{{ {} }}
          </div>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>
<script>
import PeekForm from 'peeks/peekForm'
import { MBanner, MGroupTitle } from 'components'
import { mapGetters } from 'vuex'

export default {
  components: {
    PeekForm,
    MBanner,
    MGroupTitle
  },
  data () {
    return {
      uiUpdate: false, // This literally just exists to flip a value to trigger ui updates
      sentryErrorTypes: [
        'error', // Your basic run of the mill "throw error"
        'fetchError' // A Fetch API Error, i.e. "Failed to fetch"
        // TODO: Add more error types :3
      ],
      sentrySelectedErrorType: 'error'
    }
  },
  computed: {
    ...mapGetters({
      nativeAll: 'native/all'
    }),
    stringifiedNativeAll () {
      return JSON.stringify(this.nativeAll, null, 2)
    },
    sentryDSN () {
      return process.env.VUE_APP_SENTRY_DSN
    },
    sentryRelease () {
      return process.env.VUE_APP_SENTRY_RELEASE
    },
    sentryModeHints () {
      return {
        'error': 'Your basic run of the mill "throw error"',
        'fetchError': 'A Fetch API Error, i.e. "Failed to fetch"'
      }
    }
  },
  methods: {
    sentryTriggerError () {
      switch (this.sentrySelectedErrorType) {
      case 'fetchError':
        fetch('httpsg://b.us/500')
        break
      case 'error':
      default:
        throw new Error('This is a test error')
      }
    }
  }
}
</script>
