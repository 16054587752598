const index = {
  name: 'profile',
  path: '/profile',
  components: {
    default: () => import(/* webpackChunkName: "profile" */ 'pages/profile/index.vue')
  },
  meta: {
    layout: 'mobile',
    label: 'Account'
  }
}

export default { index }
