<template>
  <peek-form
    title="mango top-up"
    :footer="false"
  >
    <div class="top-up-wrapper">
      <div class="m-card price">
        <span>{{ $t('price') }}</span>
        <span class="text-right">{{ $n(amount, 'currency') }}</span>
        <span>{{ $t('booking.fee') }}</span>
        <span class="text-right">{{ $n(0, 'currency') }}</span>
        <span>{{ $t('tax.vat') }}</span>
        <span class="text-right">{{ $n(0, 'currency') }}</span>
        <strong>{{ $t('total_cost') }}</strong>
        <strong class="text-right">{{ $n(amount, 'currency') }}</strong>
      </div>
      <div class="m-card payment">
        <strong style="grid-column: span 2">{{ $t('payment_method') }}</strong>
        <label>Card</label><input v-model="payBy" value="card" type="radio">
      </div>
      <button
        :disabled="payBy !== 'card'"
        @click="payByCard"
      >
        {{ $t('mango.add_funds') }} with {{ payBy }}
      </button>
    </div>
  </peek-form>
</template>

<script>
import { mapGetters } from 'vuex'
import peekForm from '../../peekForm'

export default {
  name: 'MangoPayment',
  components: {
    peekForm
  },
  data () {
    return {
      payBy: 'card'
    }
  },
  computed: {
    ...mapGetters({
      user: 'userLookup'
    }),
    amount () {
      return this.$route.params.amount
    }
  },
  methods: {
    payByCard () {
      this.$store.dispatch('payment/setParams', {
        requester: this.user,
        amount: this.amount * 100, // This converts our £ values into pennies for the API
        journey: {}
      })
      this.$router.push({
        name: 'booking-payment-card',
        params: { token: 'mango-topup' }
      })
    }
  }

}
</script>

<style lang="stylus" scoped>
.top-up-wrapper
  background-color #ddd
  height: calc(100vh - 64px)
  padding 16px 24px
  button
    position fixed
    bottom 16px
    left 16px
    right 16px
    border none
    background-color: var(--q-color-primary)
    color white
    border-radius 10px
    padding 8px

.m-card
  background-color white
  height 160px
  width 100%
  border-radius 10px
  margin-block 24px
  padding 8px 16px
  display grid
  grid-template-columns 1fr 1fr
  grid-template-rows repeat(4, 1fr)
  align-items: center

input[type=radio]
  appearance: radio
  justify-self: flex-end
</style>
