import { cloneDeep } from 'lodash'

const initial = {
  position: {
    coords: null,
    timestamp: null
  },
  error: {
    code: null,
    message: null
  },
  options: {
    enableHighAccuracy: false,
    timeout: null,
    maximumAge: 0
  },
  denied: false
}

// Copy inital state over to the state var
const state = cloneDeep(initial)

const getters = {
  requested (state) {
    return state.position.coords !== null || state.error.code !== null
  },
  latLng (state) {
    return {
      latitude: state.position.coords ? state.position.coords.latitude : null,
      longitude: state.position.coords ? state.position.coords.longitude : null
    }
  },
  denied: (state) => state.denied,
  position: (state) => state.position,
  error: (state) => state.error,
  options: (state) => state.options
}

const actions = {
  get ({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development') {
        return commit('position', { coords: { latitude: 53.8314288, longitude: -1.7605812 } }) // London
      }
      if ('geolocation' in window.navigator) {
        return window.navigator.geolocation.getCurrentPosition(
          position => {
            commit('position', position)
            resolve(position)
          },
          positionError => {
            reject(positionError)
          }
        )
      }
      return reject(commit('unsupported'))
    })
      .catch(err => {
        commit('error', err)
      })
  },
  setMapToInverness ({ commit, state }) {
    commit('position', { coords: { ...state.position.coords, latitude: 57.477620, longitude: -4.224920 }, timestamp: state.position.timestamp })
  }
}

const mutations = {
  position (state, { coords, timestamp }) {
    state.position.coords = {
      latitude: coords.latitude,
      longitude: coords.longitude,
      altitude: coords.altitude,
      accuracy: coords.accuracy,
      altitudeAccuracy: coords.altitudeAccuracy,
      heading: coords.heading,
      speed: coords.speed
    }
    state.position.timestamp = timestamp
  },
  error (state, error) {
    if (error.code === 1) {
      state.denied = true
    }
    state.error = error
  },
  unsupported (state) {
    state.error = {
      code: -1,
      message: 'Geolocation is not currently supported.'
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
