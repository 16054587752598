import { Platform } from 'quasar'

export const dashboard = {
  name: 'dashboard',
  path: '/dashboard/:type?',
  components: {
    default: () => import(/* webpackChunkName: "dashboard" */ `pages/dashboard/index.vue`)
  },
  beforeEnter (to, from, next) {
    if (Platform.is.mobile) {
      return next({
        ...to,
        name: 'home'
      })
    }
    next()
  }
}

export default { dashboard }
