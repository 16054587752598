<template>
  <div class="status" :class="{ active }">
    {{ $tc('active_inactive', active) }}
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      userLookup: 'userLookup'
    }),
    active () {
      return this.userLookup.has_northlink
    }
  }
}
</script>
<style lang="stylus" scoped>
.status
  display grid
  padding 1em 0
  justify-content center
  align-items center
  color $negative

  &.active
    color $positive
</style>
