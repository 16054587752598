export const exports = {
  name: 'finance-export',
  path: '/finance/export/*',
  components: {
    default: () => import(/* webpackChunkName: "finance" */ 'pages/finance/export/index.vue')
  },
  meta: {
    permission: ['can.view.finance.exports']
  }
}
export const onwardTravelExports = {
  name: 'finance-export-onwardtravel',
  path: '/finance/onwardtravel/export/*',
  components: {
    default: () => import(/* webpackChunkName: "finance" */ 'pages/finance/export/onwardtravel.vue')
  },
  meta: {
    permission: ['can.view.ops.reports']
  }
}
export default { exports, onwardTravelExports }
