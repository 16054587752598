<template>
  <div class="top-up-wrapper">
    <div class="balance-wrapper">
      <button class="minus" @click="handleMinus">
        -
      </button>
      <div class="funds-to-add">
        {{ fundsToCurrency }}
      </div>
      <button class="plus" @click="handlePlus">
        +
      </button>
    </div>
    <div v-if="error" role="alert" class="error">
      {{ error }}
    </div>
    <button
      class="submit"
      :disabled="fundsToAdd < 10"
      @click="addFunds"
    >
      {{ $t('mango.add_funds') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'MangoTopUp',
  props: {
    balance: Number
  },
  data () {
    return {
      fundsToAdd: 0,
      error: null
    }
  },
  computed: {
    fundsToCurrency () {
      return this.$n(this.fundsToAdd, 'currency')
    }
  },
  methods: {
    handlePlus () {
      if (this.fundsToAdd + this.balance >= 250) {
        this.error = this.$t('mango.error.balance_too_high')
        return
      }
      if (this.fundsToAdd < 10) this.fundsToAdd += 10
      else this.fundsToAdd += 5
    },
    handleMinus () {
      if (this.fundsToAdd <= 0) return
      if (this.fundsToAdd > 10) this.fundsToAdd -= 5
      else this.fundsToAdd -= 10
    },
    addFunds () {
      this.$router.push({ hash: '/#mango/payment', params: { amount: this.fundsToAdd } })
    }
  }
}
</script>

<style lang="stylus" scoped>
.top-up-wrapper
  width 100%
  display flex
  flex-direction: column
  .error
    color red
    margin 8px
    padding-inline 16px
    text-align center
    font-size 85%
    animation shake 0.2s

.balance-wrapper
  display flex
  justify-content: center
  .funds-to-add
    border solid #ddd 1px
    width 50%
    margin-inline 8px
    border-radius 5px
    display flex
    justify-content: center
    align-items: center
  button
    width 36px
    height 36px
    padding 0
    margin 0
    display flex
    align-items: center
    justify-content: center
    border-radius 50%
    border none
    font-size 36px
    &.plus
      background-color $light-green

button.submit
  padding 8px
  margin 8px
  background-color var(--q-color-primary)
  color white
  border none
  border-radius: 10px
  margin-top auto

@keyframes shake {
  0% {
    transform translateX(-2px)
  }
  33% {
    transform translateX(2px)
  }
  66% {
    transform translateX(-2px)
  }
  100% {
    transform translateX(2px)
  }
}

</style>
