import http from './http'

export function block (username, params) {
  return http.post(`/staff/${username}/block`, params)
}

export function unblock (username, params) {
  return http.post(`/staff/${username}/unblock`, params)
}

export function changePassword (username, newPassword, currentPassword) {
  return http.post(`/staff/${username}/change-password`, {
    new_password: newPassword,
    current_password: currentPassword
  })
}

export function avatar (user, params) {
  return http.post(`/staff/${user.username}/picture`, params)
}

export function updateStaff (username, params) {
  return http.put(`/backoffice/${username}`, params)
}
