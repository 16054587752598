<template>
  <div class="row q-col-gutter-sm">
    <q-input
      v-model="booking.first_name"
      class="col-6"
      :label="$t('name.forename')"
      :error="errors.booking.first_name.$error"
      @input="errors.booking.first_name.$touch()"
    />
    <q-input
      v-model="booking.last_name"
      class="col-6"
      :label="$t('name.surname')"
      :error="errors.booking.last_name.$error"
      @input="errors.booking.last_name.$touch()"
    />
    <q-input
      v-model="booking.address_1"
      class="col-6"
      :label="$t('address_line_one')"
      autogrow
      :error="errors.booking.address_1.$error"
      @input="errors.booking.address_1.$touch()"
    />
    <q-input
      v-model="booking.address_2"
      class="col-6"
      :label="$t('address_line_two')"
      autogrow
      @input="errors.booking.address_2.$touch()"
    />
    <q-input
      v-model="booking.post_code"
      class="col-6"
      :label="$t('post_code')"
      :error="errors.booking.post_code.$error"
      @input="errors.booking.post_code.$touch()"
    />
    <q-input
      v-model="booking.city"
      class="col-6"
      :label="$t('city')"
      :error="errors.booking.city.$error"
      @input="errors.booking.city.$touch()"
    />
    <q-select
      v-model="booking.country"
      class="col-6"
      :options="countries"
      map-options
      emit-value
      :label="$t('country')"
      :error="errors.booking.country.$error"
      @change="errors.booking.country.$touch()"
    />
    <q-select
      v-model="booking.state"
      class="col-6"
      :options="states"
      map-options
      emit-value
      :label="$t('state')"
      :error="errors.booking.state.$error"
      @change="errors.booking.state.$touch()"
    />
  </div>
</template>
<script>
import countries from 'store/countries'
import usStates from 'store/us-states'
export default {
  props: [ 'value', 'errors' ],
  computed: {
    booking: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('change', val)
      }
    },
    countries () {
      return countries.map((c) => ({ label: c.en_short_name, value: c.alpha_2_code }))
    },
    states () {
      return usStates.map(s => ({ label: s.name, value: s['alpha-2'] }))
    }
  },
  watch: {
    value: {
      deep: true,
      handler (val) {
        if (!val.card_save) this.booking.card_name = null
      }
    }
  }
}
</script>
