export default function (data, title) {
  var pom = document.createElement('a')
  var BOM = '\uFEFF'
  var csvContent = BOM + data
  var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
  var url = URL.createObjectURL(blob)
  pom.href = url
  pom.setAttribute('download', title.includes('.csv') ? title : title + '.csv')
  pom.click()
}
