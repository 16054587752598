import http from './http'

export default function login (username, password) {
  return http.post('/login', null, {
    headers: {
      'authorization': 'Basic ' + Buffer.from(username + ':' + password).toString('base64')
    }
  })
}

export function refresh () {
  return http.get('/auth/refresh')
}

export function verify (username, token) {
  return http.get(`/users/${username}/verify/${token}`)
}
