<template>
  <peek-form
    :title="$t('organisation.config.onwardtravel.title', { org: organisation ? organisation.name : '' })"
    :footer="true"
  >
    <q-page v-if="resource" padding>
      <m-group-title label="Business Areas" />
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <div class="row q-col-gutter-sm tariff-header">
            <q-chip
              v-for="(businessArea, index) in resource.business_areas"
              :key="index"
              color="primary"
              text-color="white"
              removable
              @remove="removeBusinessArea(businessArea)"
            >
              {{ businessArea }}
            </q-chip>
          </div>
          <q-input
            v-for="(c, i) in unsavedBusinessAreas"
            :key="i + 1"
            v-model="unsavedBusinessAreas[i]"
          />
          <q-btn round dense icon="add" class="add-tariff" @click="addBusinessArea" />
        </q-card-section>
      </q-card>

      <m-group-title label="Cost Centres" />
      <q-card class="bg-white no-margin no-border">
        <q-card-section>
          <div class="row q-col-gutter-sm tariff-header">
            <q-chip
              v-for="(costCentre, index) in resource.cost_centres"
              :key="index"
              color="primary"
              text-color="white"
              removable
              @remove="removeCostCentre(costCentre)"
            >
              {{ costCentre }}
            </q-chip>
          </div>
          <q-input
            v-for="(c, i) in unsavedCostCentres"
            :key="i + 1"
            v-model="unsavedCostCentres[i]"
          />
          <q-btn round dense icon="add" class="add-tariff" @click="addCostCentre" />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import _ from 'lodash'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle
  },
  mixins: [authentication],
  data () {
    return {
      resource: null, // A copy of the organisation object, used to update the organisation
      unsavedCostCentres: [],
      unsavedBusinessAreas: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      userOrg: 'organisation'
    }),
    organisation () {
      return this.userIsStaff ? this.orgFromSlug : this.userOrg
    },
    orgFromSlug () {
      return this.$store.getters['organisations/getOrg'](this.$route.query.slug)
    },
    userIsStaff () {
      return this.hasPermission('organisations.index')
    }
  },
  created () {
    if (this.organisation) this.resource = _.cloneDeep(this.organisation)
    else {
      this.$store.dispatch('organisations/loadOrg', { orgId: this.$route.query.slug || this.userOrg.id }).then(resOrg => {
        this.resource = resOrg
      })
    }
  },
  methods: {
    addCostCentre () {
      this.unsavedCostCentres.push(null)
    },
    removeCostCentre (value) {
      this.resource.cost_centres.splice(this.resource.cost_centres.indexOf(value), 1)
    },
    removeUnsavedCostCentre (index) {
      this.unsavedCostCentres.splice(index, 1)
    },
    addBusinessArea () {
      this.unsavedBusinessAreas.push(null)
    },
    removeBusinessArea (value) {
      this.resource.business_areas.splice(this.resource.business_areas.indexOf(value), 1)
    },
    removeUnsavedBusinessArea (index) {
      this.unsavedBusinessAreas.splice(index, 1)
    },
    submit () {
      // Clone the resource, so we can make changes to it without affecting the user's visible inputs
      const org = _.cloneDeep(this.resource)

      // If every field of the first address is empty, remove it
      // (I'm not sure why this exists, I wrote it two years ago...)
      const isAddressEmpty = Object.values(org.attributes.addresses[0]).every(x => (x === null || x === ''))
      if (isAddressEmpty) org.attributes.addresses = []

      return this.$store.dispatch('organisations/updateOrg', { org })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
