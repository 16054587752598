<template>
  <q-item
    data-testid="card"
    class="q-py-sm q-px-md"
    clickable
    :class="{ 'bg-blue-1': selected, 'text-blue': selected }"
    @click.prevent="$emit('select')"
  >
    <q-item-section side>
      <m-card-provider
        :method="card.type"
        height="32px"
      />
    </q-item-section>
    <q-item-section>
      <q-item-label>
        {{ card.name }}
      </q-item-label>
      <q-item-label caption>
        **** **** **** {{ card.last_four_digits }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-item-label caption>
        {{ card.expiry.date | expiry }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-btn label="..." flat size="lg">
        <q-menu
          anchor="top right"
          self="top left"
        >
          <q-list style="min-width: 100px">
            <q-item v-close-popup clickable>
              <q-item-section @click="$emit('delete-card', card.reference)">
                {{ $t('remove') }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-item-section>
  </q-item>
</template>
<script>
import date from 'utils/date-time'
import { MCardProvider } from 'components/'

export default {
  components: { MCardProvider },
  filters: {
    expiry: value => date.toCardExpiry(value)
  },
  props: {
    card: Object,
    selected: Boolean
  }
}
</script>
