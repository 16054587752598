import date from 'utils/date-time'

export default function () {
  return {
    origin: null,
    outbound: date.addToDate(date.newDate(), { minutes: 5 }),
    destination: null,
    inbound: date.addToDate(date.newDate(), { minutes: 5 }),
    adults: 1,
    children: 0,
    infants: 0,
    sortby: 'price',
    airline: {},
    traveller: null,
    requester: null,
    class: 'Economy',
    max_changes: null,
    resultsDescription: null,
    outboundArrivalMin: 0,
    outboundArrivalMax: 24,
    outboundDepartureMin: 0,
    outboundDepartureMax: 24,
    inboundArrivalMin: 0,
    inboundArrivalMax: 24,
    inboundDepartureMin: 0,
    inboundDepartureMax: 24
  }
}
