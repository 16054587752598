import { Notify } from 'quasar'
import i18n from 'i18n'
/**
 * Check if service-worker has been updated recently.
 */
export default (to, from, next) => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistration().then(sw => {
      if (sw && sw.active) sw.update()
      if (sw && sw.waiting) {
        Notify.create({
          message: i18n.t('update_available'),
          timeout: 0,
          color: 'yellow',
          textColor: 'black',
          icon: 'update',
          position: 'bottom-right',
          actions: [
            {
              label: i18n.t('refresh'),
              handler () {
                sw.waiting.postMessage({ action: 'skipWaiting' })
              },
              textColor: 'black'
            }
          ]
        })
      }
    })
  }
  next()
}
