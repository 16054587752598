<template>
  <div class="provider-grid q-pa-sm">
    <div v-if="provider.logo" class="provider_logo">
      <img :src="provider.logo">
    </div>
    <div v-if="components.status" class="provider_status">
      <component :is="components.status" v-bind="provider" />
    </div>
    <div v-if="components.body" class="provider_body">
      <component :is="components.body" v-bind="provider" />
    </div>
    <div v-if="components.footer" class="provider_footer">
      <component :is="components.footer" v-bind="provider" />
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  props: {
    provider: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    components () {
      const providerComponents = require.context('./providers', true, /\.vue$/)
      let components = {}
      providerComponents.keys().forEach(name => {
        if (name.includes(this.provider.slug)) {
          const fileName = name.split('/').pop().split('.')[0]
          const component = providerComponents(name).default || providerComponents(name)

          Vue.component(`${this.provider.slug}-${fileName}`, component)
          components[fileName] = component
        }
      })
      return components
    }
  }
}
</script>
<style lang="stylus" scoped>
.provider-grid
  height 100%
  display grid
  grid-template-areas '. provider_logo .' 'provider_status provider_status provider_status' 'provider_body provider_body provider_body' 'provider_footer provider_footer provider_footer'
  grid-auto-rows min-content min-content 1fr 1fr
  grid-gap .25em

  .provider_logo
    grid-area provider_logo
    display flex
    justify-content center
    align-items flex-start
    padding-top 1em

    img
      height 64px
      object-fit contain

  .provider_status
    grid-area provider_status

  .provider_body
    grid-area provider_body
    display grid
    justify-content stretch
    align-items center

  .provider_footer
    grid-area provider_footer
</style>
