<template>
  <q-card>
    <q-card-section>
      <div class="upload">
        <div class="row">
          <small class="upload-text col-10 col-sm-11">
            {{ $t('tooltip.image_upload') }}
          </small>
          <div class="col-2 col-sm-1">
            <file-upload
              ref="upload"
              v-model="files"
              :multiple="true"
              :maximum="5"
              accept="image/*"
              class="btn-upload q-btn inline relative-position q-btn-item q-btn--round q-btn-round q-focusable q-hoverable bg-mobilleo text-white pointer"
              @input-filter="inputFilter"
            >
              <q-icon color="black" name="add" />
            </file-upload>
          </div>
        </div>
        <div class="row gutter-xs">
          <div
            v-for="(img, i) in files"
            :key="i"
            class="col-sm-2"
          >
            <div class="img-card" @click="() => removeImage(i)">
              <div class="img-overlay">
                <q-icon name="delete" size="24px" color="white" style="margin: auto" />
              </div>
              <img
                :src="img.blob"
                class="uploaded-img"
              >
            </div>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import fileUpload from 'vue-upload-component'
export default {
  components: {
    fileUpload
  },
  props: ['value'],
  computed: {
    files: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    removeImage (index) {
      this.files.splice(index, 1)
    },
    inputFile: function (newFile, oldFile) {
      this.$refs.upload.active = 1
    },
    inputFilter: function (newFile, oldFile, prevent) {
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
        this.$refs.upload.active = 1
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.btn-upload-wrapper
  display grid

.btn-upload
  margin auto
  line-height 3
  margin-left 10px
  font-size 12px

.upload

.upload-text
  line-height 1.4

.img-card
  position relative
  margin-top 12px
  height 50px
  width 100%
  display grid

.img-card:hover .img-overlay
  opacity 1

.uploaded-img
  cursor pointer
  width 100%
  height 100%
  object-fit cover

.img-overlay
  opacity 0
  display grid
  position absolute
  cursor pointer
  user-select none
  transition .3s
  top: 0
  left: 0
  width 100%
  height: 100%
  background rgba(0, 0, 0, 0.3)
</style>
