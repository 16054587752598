import { Model } from '@vuex-orm/core'

export default class Label extends Model {
  static entity = 'labels'

  static primaryKey = 'id'

  static fields () {
    return {
      id: this.number(),
      name: this.string(),
      description: this.string().nullable(),
      colour: this.string().nullable()
    }
  }
}
