<template>
  <img
    :src="source"
    :class="{missing}"
    :style="{width: width, height: height}"
    :title="title"
    @error="error"
  >
</template>

<script>
export default {
  name: 'MImage',
  props: {
    src: {
      type: String,
      default: ''
    },
    fallback: {
      type: String,
      default: require('assets/missing-64.png')
    },
    width: String,
    height: String,
    title: String
  },
  data () {
    return {
      missing: false,
      source: 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
    }
  },
  watch: {
    src: {
      immediate: true,
      handler (src, old) {
        this.missing = false
        this.source = this.src.replace('http://', '//')
      }
    }
  },
  methods: {
    error (event) {
      this.source = this.fallback
      this.missing = true
    }
  }
}
</script>

<style lang="stylus" scoped>
img
  max-width 100%
  object-fit cover
.missing
  object-fit scale-down
</style>
