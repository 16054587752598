<template>
  <peek-form
    v-if="organisation"
    :title="$t('transport_policy')"
    :footer="false"
  >
    <div class="q-ma-lg">
      <div class="q-mx-lg">
        <m-group-title
          :label="$t('transport_policy')"
        >
          <template v-slot:sublabel>
            <p>{{ $t('enable_disable_org_transport_policy') }}</p>
          </template>
        </m-group-title>
      </div>
      <m-content-type-enable
        v-model="enabledContentTypes"
        :loading="loading"
        :content-types="availableContentTypes"
        :org-types="orgTypes"
        :organisation="organisation"
        @toggle="toggle"
      />
    </div>
  </peek-form>
</template>
<script>
import authentication from 'mixins/authentication'
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import loading from 'utils/loading'
import { travelPolicy, travelPolicyEnable, travelPolicyDisable } from 'api/organisations'
import store from 'store'
import travelContents from 'mixins/travelContents'
import { MGroupTitle, MContentTypeEnable } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    MContentTypeEnable
  },
  mixins: [ travelContents ],
  data () {
    return {
      modify: null,
      organisation: null,
      availableContentTypes: [],
      enabledContentTypes: [],
      loading: true,
      orgTypes: []
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user',
      partner: 'partner'
    })
  },
  beforeMount () {
    this.loading = true
    if (this.$route.params?.organisation) this.organisation = this.lookupOrg(this.$route.params.organisation)
    else this.organisation = this.orgGetter
    if (!this.organisation) this.$router.push({ path: this.$route.path })

    const contentTypes = [].concat(...this.partner.mobility, ...this.partner.bookables)
    // Check the user's role. Only expose the content types for the role they have.
    contentTypes.forEach(type => {
      const isBackoffice = authentication.methods.hasPermission('can.view.anything')
      if (type === 'onwardtravel' && !isBackoffice) return
      const hasRole = authentication.methods.hasRole(type)
      const hasPermission = this.hasTypePermission(type)
      if (hasRole || hasPermission || isBackoffice) {
        this.availableContentTypes.push(type)
      }
    })

    // Decide enabled/disabled UI state for the content types the user should have access to.
    this.availableContentTypes.forEach(type => {
      this.enabledContentTypes.push(this.getTravelPreferenceName(type))
    })

    const partner = store.getters.partner
    loading.start({
      message: this.$t('loading.getting.organisation_travel_preferences'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    travelPolicy(this.organisation.slug).then(res => {
      const contentTypes = res.data[0]
      let typesToRemove = []
      Object.keys(contentTypes).forEach(type => {
        if (!contentTypes[type].enabled) typesToRemove.push(type)
      })
      if (typesToRemove) this.orgTypes = this.enabledContentTypes.filter(t => !typesToRemove.includes(t))
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      loading.stop()
      this.loading = false
    })
  },
  methods: {
    async toggle (type, enabled) {
      const pref = this.getTravelPreferenceName(type)
      loading.start({
        message: this.$t('loading.updating.organisation_travel_preferences'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      try {
        if (enabled) {
          await travelPolicyDisable(this.organisation.slug, pref)
          // Update the UI by manually updating orgTypes
          this.orgTypes = this.orgTypes.filter(enabledType => enabledType !== type)
        } else {
          await travelPolicyEnable(this.organisation.slug, pref)
          // Update the UI by manually updating orgTypes
          this.orgTypes.push(type)
        }

        const contentType = this.$tc(`content_type.${type}`)

        this.$q.notify({
          message: this.$t('transport_preference_type_updated', { type: contentType }),
          color: 'positive',
          icon: 'far fa-check-double'
        })
      } catch (err) {
        if (type === 'onwardtravel') {
          this.$q.notify({
            message: err.data.title,
            color: 'yellow',
            icon: 'fas fa-times-circle'
          })
        } else {
          this.$q.notify({
            message: this.$t('error.default'),
            color: 'negative',
            icon: 'fas fa-times-circle'
          })
        }
      }

      loading.stop()
    }
  }
}
</script>
