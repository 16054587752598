import http from './http'

const sortResults = response => {
  const result = []
  response.data.forEach(a => {
    result.push({
      label: a.text || a.address.label,
      value: a.lookup || a.address.place_id,
      icon: 'home', // or 'directions_walk' or 'home'
      append: a.lookup,
      code: a.lookup,
      type: 'address', // or 'branch'
      lat: a.address.lat,
      lng: a.address.lng,
      place_id: a.address.place_id,
      color: 'red-5',
      udprn: a.address.udprn
    })
  })
  return result
}

export function query (input) {
  const query = input.query || input
  return http.get('/location', {
    params: { query }
  })
    .then(sortResults)
}

export function formattedLookup (query) {
  return http.get('/locations/places/query', {
    params: { input: `lookup:${query}` }
  })
    .then(response => sortResults(response)[0])
}

export function details (lookup) {
  return http.get('/location/lookup', { params: { query: lookup } })
}
