<template>
  <div v-if="!bikehireLoading && !bikehireStatus.registered" class="register-grid">
    <div class="signin_text">
      {{ $t('travel_providers.signin_prompt') }}
    </div>
    <div class="signin_button">
      <q-btn :label="$t('travel_providers.signin_action')" color="primary" @click="showLogin = true" />
    </div>
    <div class="join_text">
      {{ $t('travel_providers.join_prompt') }}
    </div>
    <div class="join_button">
      <q-btn :label="$t('travel_providers.join_action')" color="primary" @click="showRegister = true" />
    </div>
    <q-dialog v-model="showLogin">
      <q-card>
        <brompton-login-form :user="authedUser" />
      </q-card>
    </q-dialog>
    <q-dialog v-model="showRegister">
      <q-card>
        <brompton-signup-form :user="authedUser" />
      </q-card>
    </q-dialog>
  </div>
  <div v-else-if="bikehireLoading" class="register-grid">
    <div class="signin_text">
      <q-skeleton type="text" />
    </div>
    <div class="signin_button">
      <q-skeleton type="rect" />
    </div>
    <div class="join_text">
      <q-skeleton type="text" />
    </div>
    <div class="join_button">
      <q-skeleton type="rect" />
    </div>
  </div>
  <div v-else class="delete-grid">
    <div class="signin_text">
      {{ $t('travel_providers.change_details') }}
    </div>
    <div class="signin_button">
      <!-- Awaiting API Functionality, see MFD1-914 and MBAPI-1024 -->
      <q-btn :label="$t('travel_providers.change_details_action')" color="primary" disable />
    </div>
  </div>
</template>
<script>
import bromptonLoginForm from 'pages/ondemand/bikehire/brompton/login.vue'
import bromptonSignupForm from 'pages/ondemand/bikehire/brompton/signup.vue'
import bikehireMixin from 'mixins/bikehire.js'
export default {
  components: {
    bromptonLoginForm,
    bromptonSignupForm
  },
  mixins: [bikehireMixin],
  data () {
    return {
      showLogin: false,
      showRegister: false
    }
  },
  computed: {
    authedUser () {
      return this.$store.getters.user
    }
  }
}
</script>
<style lang="stylus" scoped>
.register-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr 1fr

  .q-btn
    width 100%

.delete-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr

  .q-btn
    width 100%
</style>
