<template>
  <peek-form
    :title="$t('organisation.settings')"
    :footer="false"
  >
    <q-page padding>
      <m-group-title :label="$t('organisation.profile_details')" />
      <q-card class="bg-white text-dark">
        <q-list separator>
          <q-item>
            <q-item-section>
              <q-item-label label>
                {{ $t('profile_and_contact_information') }}
              </q-item-label>
              <q-item-label caption>
                {{ $t('organisation.information') }}
              </q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-btn flat color="primary" @click="navigateTo('/organisation/profile')">
                {{ $t('edit') }}
              </q-btn>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card>

      <template>
        <m-group-title :label="$t('personalisation')" />
        <q-card class="bg-white text-dark">
          <q-list separator>
            <q-item v-if="hasPermission('can.apply.organisation.brand')">
              <q-item-section>
                <q-item-label label>
                  {{ $t('branding') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.customise_organisation') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/branding')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item v-if="hasPermission('can.manage.labels')">
              <q-item-section>
                <q-item-label label>
                  {{ $tc('label.label', 2) }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('tooltip.create_labels') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/labels')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </template>

      <template v-if="userIsStaff && hasFeatureFlag('feature.mfd1.807.organisations.settings.use.peek')">
        <m-group-title :label="$t('organisation.config.label')" />
        <q-card class="bg-white text-dark">
          <q-list separator>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('organisation.config.system.label') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('organisation.config.system.caption') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/configuration/system')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('organisation.config.rental.label') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('organisation.config.rental.caption') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/configuration/rental')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('organisation.config.onwardtravel.label') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('organisation.config.onwardtravel.caption') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/configuration/onwardtravel')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label label>
                  {{ $t('organisation.config.hotel.label') }}
                </q-item-label>
                <q-item-label caption>
                  {{ $t('organisation.config.hotel.caption') }}
                </q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-btn flat color="primary" @click="navigateTo('/organisation/configuration/hotel')">
                  {{ $t('edit') }}
                </q-btn>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>

        <m-banner
          :title="$t('organisation.config.staffnotice.title')"
          class="cursor-pointer"
          @click.native="$router.push({ path: `/organisations/${organisation.slug}/profile` })"
        >
          <template v-slot:message>
            <div class="text-body2" style="white-space: pre-line;">
              {{ $t('organisation.config.staffnotice.message') }}
            </div>
          </template>
        </m-banner>
      </template>
    </q-page>
  </peek-form>
</template>
<script>
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle, MBanner } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    MBanner
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      user: 'user',
      userOrg: 'organisation'
    }),
    organisation () {
      return this.userIsStaff ? this.orgFromSlug : this.userOrg
    },
    orgFromSlug () {
      return this.$store.getters['organisations/getOrg'](this.$route.query.slug)
    },
    userIsStaff () {
      return this.hasPermission('organisations.index')
    }
  },
  async created () {
    if (!this.organisation) {
      await this.$store.dispatch('organisations/loadOrg', { orgId: this.$route.query.slug || this.userOrg.id })
    }
  },
  methods: {
    navigateTo (hashPath) {
      this.$router.push({
        hash: hashPath,
        query: {
          slug: this.$route.query.slug
        }
      })
    },
    hasFeatureFlag (flag) {
      if (!flag) return false
      return this.hasPermission(flag)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .q-item
    padding 16px
</style>
