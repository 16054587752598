<template>
  <peek-form
    :title="$t('organisation.config.rental.title', { org: organisation ? organisation.name : '' })"
    :footer="true"
  >
    <q-page v-if="resource" padding>
      <m-group-title :label="$t('organisation.config.rental.info')" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-input
            v-model="resource.attributes.vehicle_hire_requestor_id"
            label="Vehicle hire requestor ID"
          />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('organisation.config.rental.available_tariffs')" />
      <q-card>
        <q-card-section>
          <q-btn round dense icon="add" class="add-tariff" @click="addTariff" />

          <tariff-detail
            v-for="(t, i) in resource.attributes.vehicle_hire_tariffs"
            :key="i"
            v-model="resource.attributes.vehicle_hire_tariffs[i]"
            @removeTariff="() => removeTariff(i)"
          />
          <tariff-new
            v-for="(t, i) in unsavedTariffs"
            :key="i + resource.attributes.vehicle_hire_tariffs.length"
            v-model="unsavedTariffs[i]"
            @removeTariff="() => removeUnsavedTariff(i)"
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import _ from 'lodash'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle } from 'components/'
import tariffDetail from './tariff-detail'
import tariffNew from './tariff-new'

export default {
  components: {
    peekForm,
    MGroupTitle,
    tariffDetail,
    tariffNew
  },
  mixins: [authentication],
  data () {
    return {
      resource: null, // A copy of the organisation object, used to update the organisation
      unsavedTariffs: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      userOrg: 'organisation'
    }),
    organisation () {
      return this.userIsStaff ? this.orgFromSlug : this.userOrg
    },
    orgFromSlug () {
      return this.$store.getters['organisations/getOrg'](this.$route.query.slug)
    },
    userIsStaff () {
      return this.hasPermission('organisations.index')
    }
  },
  created () {
    if (this.organisation) this.resource = _.cloneDeep(this.organisation)
    else {
      this.$store.dispatch('organisations/loadOrg', { orgId: this.$route.query.slug || this.userOrg.id }).then(resOrg => {
        this.resource = resOrg
      })
    }
  },
  methods: {
    addTariff () {
      this.unsavedTariffs.push({
        label: null,
        code: null
      })
    },
    removeTariff (index) {
      this.resource.attributes.vehicle_hire_tariffs.splice(index, 1)
    },
    removeUnsavedTariff (index) {
      this.unsavedTariffs.splice(index, 1)
    },
    submit () {
      // Clone the resource, so we can make changes to it without affecting the user's visible inputs
      const org = _.cloneDeep(this.resource)

      // If every field of the first address is empty, remove it
      // (I'm not sure why this exists, I wrote it two years ago...)
      const isAddressEmpty = Object.values(org.attributes.addresses[0]).every(x => (x === null || x === ''))
      if (isAddressEmpty) org.attributes.addresses = []

      return this.$store.dispatch('organisations/updateOrg', { org })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
