<template>
  <div>
    <q-list class="bg-white">
      <q-item>
        <q-item-section avatar top>
          <m-image :src="note.author.picture" width="38px" height="38px" class="avatar" />
        </q-item-section>
        <q-item-section>
          <q-item-label
            label
            class="note-heading"
          >
            <strong>{{ note.author.display_name }}</strong> <small> at {{ note.created_at.date | time }} on {{ note.created_at.date | date }}</small>
          </q-item-label>
          <q-item-label>
            <m-editor-viewer v-if="notes.length" :content="note.body" style="padding-bottom: 0" />
            <m-editor-viewer v-else :content="note.body" />
          </q-item-label>
          <q-list v-if="notes.length">
            <q-item
              v-for="childNote in notes"
              :key="childNote.id"
              class="bg-white"
              multiline
            >
              <q-item-section side top aria-hidden="true">
                &#9584;
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  <small>{{ childNote.body }}</small>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>
<script>
import date from 'utils/date-time'
import { notes } from '../../../api/bookings'
import { MImage, MEditorViewer } from 'components/'

export default {
  components: { MImage, MEditorViewer },
  filters: {
    date (data) {
      return date.toShortDate(data)
    },
    time (data) {
      return date.toCivilTime(data)
    }
  },
  props: ['note'],
  data () {
    return {
      notes: null
    }
  },
  created () {
    this.GetNotes()
  },
  methods: {
    GetNotes () {
      notes(this.$route.query.booking)
        .then(notes => {
          this.notes = notes.data.filter(n => n.parent_id === this.note.id)
        })
        .catch(error => {
          const message = this.$t('error.custom', { error: error?.data?.message })
          this.$q.notify({
            message,
            color: 'negative',
            icon: 'clear'
          })
        })
    }
  }
}
</script>
