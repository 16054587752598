
export default {
  data () {
    return {
      taxOptions: [
        {
          label: 'Zero Rate',
          value: 'zero'
        },
        {
          label: 'Standard Rate',
          value: 'standard'
        },
        {
          label: 'Reduced Rate',
          value: 'reduced'
        },
        {
          label: 'Super-reduced Rate',
          value: 'superreduced'
        },
        {
          label: 'Parking Rate',
          value: 'parking'
        }
      ],
      vatRates: {
        zero: 0,
        standard: 0.2,
        reduced: 0.05,
        superreduced: 0,
        parking: 0
      }
    }
  },
  methods: {
    formatMoney (val) {
      return this.$n(Number(val) / 100, 'currency')
    },
    calculateGross (line) {
      const unitPrice = line.unitPrice
      const quantity = line.quantity
      const preGross = unitPrice * quantity

      let gross
      if (line.unitPriceIncludesTax) {
        gross = preGross
      } else {
        gross = preGross * (1 + this.vatRates[line.taxRate.value])
      }
      return gross
    }
  }
}
