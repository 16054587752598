<template>
  <peek-form
    :title="$t('mango.mango')"
    :subtitle="subtitle"
    :footer="false"
  >
    <div class="img-container">
      <img :src="require('assets/mango.png')">
    </div>
    <slot />
  </peek-form>
</template>

<script>
import peekForm from '../peekForm.vue'

export default {
  name: 'MangoWrapper',
  components: {
    peekForm
  },
  props: {
    subtitle: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.img-container
  max-width 60vw
  margin-inline auto
  margin-block-start 24px
  & img
    width 100%

</style>
