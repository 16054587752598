import travelContents from './travelContents'

/**
 * Conceptually similar to travelContents, but gives details into the providers who offer our content services.
 */

export default {
  mixins: [travelContents],
  data () {
    return {
      providers: {
        'mobilleo': {
          name: 'Mobilleo',
          logo: require('assets/mobilleo-logo-icon.png'),
          supportNumber: '0300 123 1089',
          links: {
            faq: 'https://support.mobilleo.com'
          }
        },
        'brompton-bike-hire': {
          name: 'Brompton Bike Hire',
          logo: require('assets/bike-providers/brompton_logo.svg'),
          supportNumber: '0204 538 3225',
          links: {}
        },
        'enterprise-carclub': {
          name: 'Enterprise Carclub',
          logo: require('assets/enterprise_e_logo.jpg'),
          supportNumber: '0345 266 9290',
          links: {
            signup: 'https://www.enterprisecarclub.co.uk/gb/en/locations.html',
            faq: 'https://www.enterprisecarclub.co.uk/gb/en/help-centre.html'
          }
        },
        'northlink-ferries': {
          name: 'Northlink Ferries',
          logo: require('assets/northlink-logo.png'),
          supportNumber: '0800 111 44 22',
          links: {
            signup: 'https://www.northlinkferries.co.uk/islander/',
            bookingInfo: 'https://www.northlinkferries.co.uk/booking-info/'
          }
        },
        'mipermit': {
          name: 'MiPermit',
          logo: require('assets/mipermit-logo.svg'),
          supportNumber: '0345 520 7007',
          links: {
            help: 'http://kb.mipermit.com/en/'
          }
        },
        'mango': {
          name: 'mango',
          logo: require('assets/mango-logo.svg'),
          supportNumber: '01773 712265',
          supportEmail: 'talk@mymango.travel',
          links: {
            help: 'https://www.trentbarton.co.uk/contact-us/contact-details'
          }
        },
        'bewegen-hi-bike': {
          name: 'Hi-Bike (Bewegen)',
          logo: require('assets/bike-providers/hi-bike-logo.png'),
          supportNumber: '+44 800 3688093',
          supportEmail: 'info@hi-bike.co.uk',
          links: {
            signup: 'https://hi-bike.co.uk/register',
            help: 'https://hi-bike.co.uk/',
            terms: 'https://hi-bike.co.uk/user-agreement'
          }
        }
      }
    }
  },
  computed: {
    partnerSlug () { return this.$store.getters.partner.slug },
    groups () {
      return [
        {
          name: 'Public Transport',
          links: this.filterLinks(

            [
              {
                type: 'train',
                name: this.getTravelContentName('train'),
                icon: this.getTravelContentIcon('train'),
                iconIsImage: false,
                provider: 'mobilleo'
              },
              {
                type: 'stagecoach-bus',
                name: this.getTravelContentName('stagecoach-bus'),
                icon: this.getTravelContentIcon('stagecoach-bus'),
                iconIsImage: true,
                provider: 'mobilleo'
              },
              {
                type: 'ferry',
                name: 'Northlink Ferries',
                icon: require('assets/northlink-icon.png'),
                iconIsImage: true,
                provider: 'northlink-ferries',
                is: ['gohi']
              },
              {
                type: 'bus',
                name: this.getTravelContentName('bus'),
                icon: this.getTravelContentIcon('bus'),
                iconIsImage: false,
                provider: 'mobilleo'
              },
              {
                type: 'tram',
                name: 'Commuter rail',
                icon: 'tram',
                iconIsImage: false,
                provider: 'mobilleo'
              },
              {
                type: 'mango',
                name: 'mango',
                icon: require('assets/mango-icon.svg'),
                iconIsImage: true,
                provider: 'mango',
                is: ['derbygo']
              }
            ]
          )
        },
        {
          name: 'Active Transport',
          links: [
            {
              type: 'brompton',
              name: 'Brompton Bike Hire',
              icon: require('assets/bike-providers/brompton_icon.png'),
              iconIsImage: true,
              provider: 'brompton-bike-hire'
            },
            {
              type: 'hi-bike',
              name: 'Hi-Bike (Bewegen)',
              icon: require('assets/bike-providers/hi-bike_icon.png'),
              iconIsImage: true,
              provider: 'bewegen-hi-bike'
            }
          ]
        },
        {
          name: 'Shared Mobility',
          links: [
            {
              type: 'carclub',
              name: this.getProviderName('enterprise-carclub'),
              icon: this.getProviderLogo('enterprise-carclub'),
              iconIsImage: true,
              provider: 'enterprise-carclub'
            },
            {
              type: 'ridehailing',
              name: 'Ridehailing & Taxi',
              icon: this.getTravelContentIcon('taxi'),
              iconIsImage: false,
              provider: 'mobilleo'
            }
          ]
        },
        {
          name: 'Personal Options',
          links: [
            {
              type: 'rental',
              name: this.getTravelContentName('rental') || 'Vehicle Rental',
              icon: this.getTravelContentIcon('rental'),
              iconIsImage: false,
              provider: 'mobilleo'
            },
            {
              type: 'flight',
              name: this.getTravelContentName('flight') || 'Flights',
              icon: this.getTravelContentIcon('flight'),
              iconIsImage: false,
              provider: 'mobilleo'
            },
            {
              type: 'hotel',
              name: this.getTravelContentName('hotel') || 'Hotels',
              icon: this.getTravelContentIcon('hotel'),
              iconIsImage: false,
              provider: 'mobilleo'
            }
          ]
        }
      ]
    }
  },
  methods: {
    getProvider (provider) {
      return this.providers[provider] || null
    },
    getProviderName (provider) {
      return this.getProvider(provider).name || null
    },
    getProviderLogo (provider) {
      return this.getProvider(provider).logo || null
    },
    getProviderLinks (provider) {
      return this.getProvider(provider).links || null
    },
    filterLinks (links) {
      return links.filter(item => {
        if (item.is && !item.is.includes(this.partnerSlug)) return false
        return true
      })
    }
  }
}
