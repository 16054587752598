<template>
  <peek-form
    :title="$t('add_new_card')"
    :footer="true"
    :loading="saving"
    @save="attemptSave"
  >
    <div class="row max-width q-pa-md" :class="{ disabled: saving }">
      <form class="col-12">
        <div id="sp-container" />
      </form>
      <div class="col-12 q-pa-sm">
        <q-input
          v-model="card_name"
          class="col-8"
          :label="$t('payment_card.name')"
          :error="$v.card_name.$error"
        />
      </div>
      <div class="col-12 q-pa-sm">
        <address-form v-model="address" :errors="{ booking: { ...$v.address } }" />
      </div>
    </div>
  </peek-form>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators'
import peekForm from 'peeks/peekForm.vue'
import addressForm from 'pages/payments/card/addressForm.vue'
import loading from 'utils/loading'
import loadScript from 'utils/loadScript'
import { saveCard } from 'api/user'
import { sessionKey } from 'api/sagepay'
import { mapGetters } from 'vuex'

export default {
  components: {
    peekForm,
    addressForm
  },
  data () {
    return {
      sessionKey: null,
      checkoutReference: null,
      card_name: '',
      address: {
        address_1: '',
        address_2: '',
        city: null,
        post_code: '',
        country: '',
        state: ''
      },
      merchant_session_key: null,
      saving: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  async beforeMount () {
    const partner = this.$store.getters.partner
    loading.start({
      message: this.$t('loading.validating_payment'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      loadScript(process.env.VUE_APP_SAGEPAY_URL)
    } catch (err) {
      console.error(err)
      this.cards = []
      this.$q.notify({
        message: this.$t('error.retrieving_cards'),
        color: 'negative',
        icon: 'clear'
      })
    }

    try {
      const { data } = await sessionKey()
      this.sessionKey = data.merchant_session_key

      if (window.sagepayCheckout) {
        this.$nextTick(() => {
          this.checkoutReference = window.sagepayCheckout({
            merchantSessionKey: this.sessionKey,
            onTokenise: response => {
              if (response.success) this.submit(response.cardIdentifier)
            }
          })
          this.checkoutReference.form()
        })
      }
    } catch (err) { }

    loading.stop()
  },
  beforeDestroy () {
    this.destroySagepayCheckout()
  },
  methods: {
    attemptSave () {
      sessionKey().then(response => {
        this.sessionKey = response.data.merchant_session_key
        this.checkoutReference.tokenise({ newMerchantSessionKey: this.sessionKey })
      })
    },
    destroySagepayCheckout () {
      if (this.checkoutReference !== null) {
        this.checkoutReference.destroy()
        this.checkoutReference = null
      }
    },
    async submit (cardIdentifier = null) {
      const data = {
        merchant_session_key: this.sessionKey,
        card_identifier: cardIdentifier,
        ...this.address,
        card_name: this.card_name
      }

      try {
        this.saving = true
        this.$v.$touch()

        if (this.$v.$error) {
          this.$q.notify({
            message: this.$t('check_validation'),
            color: 'negative',
            icon: 'clear'
          })
          this.saving = false
          return
        }

        const { data: { merchant_reference } } = await saveCard(this.user.username, data)
        saveCard(this.user.username, {
          transaction_id: merchant_reference,
          ...this.address,
          card_name: this.card_name
        })
          .then(() => {
            this.$router.push({ hash: '/user-settings/payments' })
          })
          .finally(() => {
            this.saving = false
          })
      } catch (err) {
        this.saving = false
        console.error(err)
        this.$q.notify({
          message: this.$t('error.saving_card'),
          color: 'negative',
          icon: 'clear'
        })
      }
    }
  },
  validations () {
    return {
      address: {
        first_name: { required },
        last_name: { required },
        address_1: { required },
        country: { required },
        city: { required },
        post_code: { required },
        state: { required: requiredIf(() => this.address.country === 'US') }
      },
      card_name: { required }
    }
  }
}
</script>
