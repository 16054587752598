import getPartnerFromHostname from 'utils/partner'

const meta = {
  public: true,
  layout: 'login'
}

const partner = getPartnerFromHostname().slug

export const login = {
  name: 'login',
  path: '/login',
  alias: ['gohi'].includes(partner) ? undefined : '/',
  components: {
    default: () =>
      import(/* webpackChunkName: "unauthed" */ 'pages/login/index.vue')
  },
  meta
}

export const logout = {
  name: 'Logout',
  path: '/logout',
  components: {
    default: () =>
      import(/* webpackChunkName: "unauthed" */ 'pages/logout/index.vue')
  },
  props: true,
  meta: {
    ...meta,
    layout: 'blank'
  }
}

export const signup = {
  name: 'signup',
  path: '/signup',
  alias: ['gohi'].includes(partner) ? '/' : undefined,
  components: {
    default: () =>
      import(
        /* webpackChunkName: "unauthed" */ 'pages/signup/complete/index.vue'
      )
  },
  meta
}

export const memberInviteAccept = {
  name: 'memberInviteAccept',
  path: '/signup/member/:org',
  props: { default: true },
  components: {
    default: () =>
      import(
        /* webpackChunkName: "unauthed" */ 'pages/signup/complete/index.vue'
      )
  },
  meta
}

export const verifyEmail = {
  name: 'verifyEmail',
  path: '/users/:username/verify/:verification_code',
  props: { default: true },
  components: {
    default: () =>
      import(
        /* webpackChunkName: "unauthed" */ 'pages/verification/complete/index.vue'
      )
  },
  meta
}

export const verificationResend = {
  name: 'login-verification-resend',
  path: '/verification/resend',
  props: { default: true },
  components: {
    default: () =>
      import(
        /* webpackChunkName: "unauthed" */ 'pages/verification/resend/index.vue'
      )
  },
  meta
}

const mangoValidation = {
  name: 'mango-validation',
  path: '/mango/signin',
  props: { default: true },
  components: {
    default: () =>
      import(/* webpackChunkName: "unauthed" */ 'pages/verification/mango/index.vue'),
    footer: null,
    appbar: null
  },
  meta: {
    ...meta,
    layout: 'mobile'
  }
}

export default {
  login,
  logout,
  signup,
  memberInviteAccept,
  verifyEmail,
  verificationResend,
  mangoValidation
}
