import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('middleoffice') ? next() : next('/dashboard')
}

const invoices = {
  name: 'organisation-invoices',
  path: '/organisation/invoices',
  components: {
    default: () => import(/* webpackChunkName: "organisation" */ 'pages/invoices/index.vue')
  },
  beforeEnter
}

export const bookings = {
  name: 'organisation-bookings',
  path: '/organisation/bookings',
  components: {
    default: () => import(/* webpackChunkName: "organisation" */ 'pages/bookings/backoffice-bookings/index.vue')
  },
  beforeEnter
}

export default { invoices, bookings }
