import { hasRole } from 'utils/authentication'

const beforeEnter = (to, from, next) => {
  if (!hasRole('admin')) return next('/')
  else return next()
}

export const partner = {
  name: 'partner-reports',
  path: '/partner/reports',
  components: {
    default: () => import(/* webpackChunkName: "partner" */ 'pages/partner/reports/index.vue')
  },
  beforeEnter
}

export default { partner }
