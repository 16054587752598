export const iframe = {
  name: 'sagepay-threeds-authentication',
  path: '/threeDSAuthentication',
  components: {
    default: () => import(/* webpackChunkName: "3dsecure" */'pages/sagepay/threeDSAuthentication.vue')
  },
  meta: { layout: 'blank' }
}

export default { iframe }
