import { Notify, colors } from 'quasar'
import i18n from 'i18n'
import * as Sentry from '@sentry/vue'

export const toTitleCase = str => {
  if (!str) return ''
  return str.slice(0, 1).toUpperCase() + str.slice(1).toLowerCase()
}

export const flushQueue = (time = 0) => {
  return new Promise((resolve) => setTimeout(resolve, time))
}

export const handleErrors = (error, customMsg) => {
  let errorMsg = customMsg

  if (error?.data?.errors) {
    for (const key in error.data.errors) {
      Notify.create({ message: i18n.t('error.custom', { error: error.data.errors[key] }) })
    }
    return
  } else if (error?.data?.message && !errorMsg) {
    errorMsg = error.data.message
  } else if (error?.data?.detail?.[0] && !errorMsg) {
    errorMsg = error.data.detail[0]
  } else if (error?.data?.title) {
    if (!errorMsg) errorMsg = error.data.title
    // If the response has a title, it may not be useful for users, so pass it to Sentry
    Sentry.captureException(error)
  } else if (!errorMsg) {
    // We have no usable information, so send a generic message and pass the error to Sentry
    errorMsg = 'Unexpected error'
    Sentry.captureException(error)
  }

  Notify.create({ message: i18n.t('error.custom', { error: errorMsg }) })
}

export const derbyEmailValidation = (value) => /^[A-Za-z0-9._%+-]+@(unimail.)?derby.ac.uk$/.test(value)
export const onwardTravelEmailValidation = (value) => /^[A-Za-z0-9._%+-]+@rac.co.uk|@i247group.com$/.test(value)

export const createContrast = (hex) => {
  const { hexToRgb } = colors
  const { r, g, b } = hexToRgb(hex)
  return `rgb(${255 - r},${255 - g},${255 - b})`
}

// if (red*0.299 + green*0.587 + blue*0.114) > 186 use #000000 else use #ffffff
export const textContrast = (hex) => {
  const { hexToRgb } = colors
  const { r, g, b } = hexToRgb(hex)
  return (r * 0.299) + (g * 0.587) + (b * 0.114) > 150 ? '#000' : '#fff'
}

export const textStringContrast = (color) => {
  const { hexToRgb, getPaletteColor } = colors
  const hex = getPaletteColor(color)
  const { r, g, b } = hexToRgb(hex)
  return (r * 0.299) + (g * 0.587) + (b * 0.114) > 150 ? 'black' : 'white'
}

export const kmToMiles = (value) => {
  const miles = (value / 1.60934).toFixed(1)
  return miles
}
