export default ({ store }) => {
  window.nativeStore = {
    set: (key, value) => {
      return store.dispatch('native/set', { key, value })
    },
    store: (data) => {
      return store.dispatch('native/store', data)
    },
    get: (key) => {
      if (!key) return store.getters['native/all']
      return store.getters['native/get'](key)
    }
  }
}
