import Label from 'store/models/label'
import Booking from 'store/models/booking'
import Request from 'store/models/request'
import Journey from 'store/models/journey'
import {
  index as getAllLabels,
  search as searchLabels,
  create as createLabel,
  update as updateLabel,
  destroy as deleteLabel,
  attach as attachLabels,
  detach as detachLabels
} from 'api/labels'

const labelables = {
  'bookings': Booking,
  'requests': Request,
  'journeys': Journey
}

const findItemFromResource = (resource, reference) => {
  var model = labelables[resource]
  return { model, item: model?.find(reference) || null }
}

const actions = {
  async loadAllLabels (context) {
    const response = await getAllLabels()
    const labels = response.data
    await Label.insert({ data: labels })
  },
  async searchLabels (context, query) {
    const response = await searchLabels(query)
    const labels = response.data
    await Label.insert({ data: labels })
  },
  async createLabel (context, label) {
    const response = await createLabel(label)
    const newLabel = response.data
    await Label.insert({ data: newLabel })
  },
  async updateLabel (context, { id, label }) {
    const response = await updateLabel(id, label)
    const newLabel = response.data
    await Label.insert({ data: newLabel })
  },
  async deleteLabel (context, labelId) {
    const label = await Label.find(labelId)
    await label.$delete()
    await deleteLabel(label.id).catch((e) => {
      Label.insert({ data: label })
    })
  },
  async attachLabels (context, { resource, reference, labels }) {
    const response = await attachLabels(resource, reference, labels)
    const attachedLabels = response.data
    await Label.insert({ data: attachedLabels })
    var { model, item } = findItemFromResource(resource, reference)
    if (item) {
      model.update({
        where: reference,
        data: {
          labels: attachedLabels
        }
      })
    }
  },
  async detachLabels (context, { resource, reference, labels }) {
    var { model, item } = findItemFromResource(resource, reference)
    if (item) {
      model.update({
        where: reference,
        data: {
          labels: item.labels.filter(label => {
            return !labels.includes(label.name)
          })
        }
      })
    }
    await detachLabels(resource, reference, labels)
  }
}

const mutations = {

}

export default {
  actions,
  mutations,
  namespaced: true
}
