import store from 'store'

export function hasRole (roles = null) {
  if (typeof roles === 'string') roles = [roles]
  return roles.some(role => {
    return store.state.authentication.roles && store.state.authentication.roles.find(value => value === role)
  })
}

export function hasPermission (permissions = null) {
  if (typeof permissions === 'boolean') return permissions
  if (typeof permissions === 'string') permissions = [permissions]
  return permissions.some(permission => {
    return store.state.authentication.roles && store.state.authentication.permissions.find(value => value === permission)
  })
}

export function hasOrganisation (slug = null) {
  if (!slug) return false
  const storeOrganisation = store.state.authentication.organisation
  if (!storeOrganisation) return false
  return storeOrganisation.slug === slug
}

export default { hasRole, hasPermission }
