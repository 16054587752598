<template>
  <peek-form
    :title="$t('organisation.profile')"
    :footer-title="$t('concierge.some_info_will_be_visible')"
    @save="submit"
  >
    <q-page v-if="resource" padding>
      <m-group-title label="Company Info" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-input
            v-if="userIsStaff"
            v-model="resource.name"
            :label="$t('name.company')"
          />

          <q-input
            v-model="resource.attributes.legal_name"
            :label="$t('name.legal')"
          />

          <q-input
            v-model="resource.attributes.telephone_number"
            type="tel"
            :label="$t('phone_number')"
          />

          <q-input
            v-model="resource.attributes.email_address"
            type="email"
            :label="$t('email_address')"
          />

          <q-input
            v-model="resource.attributes.vat_number"
            :label="$t('vat_number')"
          />

          <q-input
            v-model="resource.attributes.company_number"
            :label="$t('company_business_number')"
          />

          <q-select
            v-model="resource.attributes.number_of_employees"
            :options="employeesOption"
            :label="$t('number_of.employees')"
            emit-value
          />

          <q-input
            v-if="userIsStaff"
            v-model="resource.attributes.account_reference"
            :label="$t('account_reference_number')"
          />

          <q-checkbox
            v-model="resource.attributes.requester_confirmation_emails"
            :label="$t('requesters_receive_booking_confirmation')"
          />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('company_contact_info')" />
      <q-card class="bbg-white text-dark q-mt-md">
        <q-card-section>
          <q-input
            v-model="resource.attributes.key_contact_name"
            :label="$t('key_contact.name')"
          />

          <q-input
            v-model="resource.attributes.key_contact_phone_number"
            type="tel"
            :label="$t('key_contact.phone')"
          />

          <q-input
            v-model="resource.attributes.key_contact_email_address"
            type="email"
            :label="$t('key_contact.email')"
          />
        </q-card-section>
      </q-card>

      <m-group-title :label="$t('billing_info')" />
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section>
          <q-input
            v-model="resource.attributes.billing_contact_name"
            :label="$t('billing_contact.name')"
          />

          <q-input
            v-model="resource.attributes.billing_contact_phone_number"
            type="tel"
            :label="$t('billing_contact.phone')"
          />

          <q-input
            v-model="resource.attributes.billing_contact_email_address"
            type="email"
            :label="$t('billing_contact.email')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].line_one"
            :label="$t('address_line_one')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].line_two"
            :label="$t('address_line_two')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].state"
            :label="$t('county')"
          />

          <q-input
            v-model="resource.attributes.addresses[0].post_code"
            :label="$t('post_code')"
          />

          <q-select
            v-model="resource.attributes.addresses[0].country"
            :options="countriesList"
            filter
            :label="$t('country')"
            emit-value
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import _ from 'lodash'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import countries from 'store/countries'
import peekForm from 'peeks/peekForm.vue'
import { MGroupTitle } from 'components/'

export default {
  components: { peekForm, MGroupTitle },
  mixins: [authentication],
  data () {
    return {
      resource: null // A copy of the organisation object, used to update the organisation
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      userOrg: 'organisation'
    }),
    employeesOption () {
      let numberOfEmployees = ['1', '1-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10000', '10000+']
      return numberOfEmployees.map(num => {
        return {
          label: num === '1' ? this.$t('self_employed') : this.$t('num.employees_range', { numstr: num }),
          value: num
        }
      })
    },
    countriesList () {
      return countries.map(country => ({ label: country.en_short_name, value: country.en_short_name }))
    },
    organisation () {
      return this.userIsStaff ? this.orgFromSlug : this.userOrg
    },
    orgFromSlug () {
      return this.$store.getters['organisations/getOrg'](this.$route.query.slug)
    },
    userIsStaff () {
      return this.hasPermission('organisations.index')
    }
  },
  created () {
    if (this.organisation) this.resource = _.cloneDeep(this.organisation)
    else {
      this.$store.dispatch('organisations/loadOrg', { orgId: this.$route.query.slug || this.userOrg.id }).then(resOrg => {
        this.resource = resOrg
      })
    }

    // The following are adding default values that for some reason are not being set, and WILL cause render issues if not done here
    if (this.resource.attributes.addresses.length < 1) {
      this.resource.attributes.addresses = [{}]
    }

    if (!this.resource.attributes.travel_policy) {
      this.resource.attributes.travel_policy = {
        hotel: {
          meal_allowance: 0
        }
      }
    }
  },
  methods: {
    submit () {
      // Clone the resource, so we can make changes to it without affecting the user's visible inputs
      const org = _.cloneDeep(this.resource)

      // If every field of the first address is empty, remove it
      // (I'm not sure why this exists, I wrote it two years ago...)
      const isAddressEmpty = Object.values(org.attributes.addresses[0]).every(x => (x === null || x === ''))
      if (isAddressEmpty) org.attributes.addresses = []

      return this.$store.dispatch('organisations/updateOrg', { org })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
