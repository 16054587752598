<template>
  <q-layout view="lHr lpr lFr" class="layout-peek" container>
    <q-header>
      <q-toolbar :class="`shadow-2 text-dark bg-${bgColor} toolbar`">
        <q-btn icon="arrow_back" flat round dense @click="$router.back()" />
        <q-toolbar-title>
          {{ title }}
          <div v-if="subtitle" class="subtitle">
            {{ subtitle }}
          </div>
        </q-toolbar-title>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <slot />
    </q-page-container>
    <q-footer v-if="footer">
      <q-toolbar text-color="light" class="q-pa-md bg-white shadow-2 footer-toolbar">
        <span class="text-body2 text-light ellipsis-2-lines">{{ footerTitle || $t('data_saved_securely') }}</span>
        <div class="footer-toolbar button-holder">
          <m-cta-button outlined @ctaClick="$router.back()">
            {{ $t('cancel') }}
          </m-cta-button>
          <m-cta-button
            :disabled="disableCallToAction"
            @ctaClick="$emit('save')"
          >
            {{ callToAction || $t('save.save') }}
          </m-cta-button>
        </div>
      </q-toolbar>
    </q-footer>
  </q-layout>
</template>

<script>
import { MCtaButton } from 'components'
export default {
  name: 'PeekForm',
  components: { MCtaButton },
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    subtitle: {
      type: String,
      default: null
    },
    footer: {
      type: Boolean,
      default: true
    },
    footerTitle: {
      type: String,
      default: null
    },
    callToAction: {
      type: String,
      default: null
    },
    disableCallToAction: {
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    bgColor () {
      return this.$q.platform.is.mobile ? 'primary' : 'white'
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-peek
  height calc(100% - env(safe-area-inset-bottom))

.toolbar
  min-height 64px

.footer-toolbar
  display flex
  justify-content space-between

.subtitle
  font-size 75%
  white-space normal

.button-holder
  min-width 200px
</style>
