import http from './http'

export function smart (params) {
  return http.get(`/smartsearch`, { params })
}

export function getAvailableContents (params) {
  return http.get(`/smartsearch/contents`, { params })
}

export function getContextData (type, params) {
  return http.get(`/smartsearch/${type}`, { params })
}

export function contextual (token, type) {
  return http.get(`/smartsearch/${token}/${type}`)
}

export function text (params) {
  return http.get(`/search/text`, { params })
}
