<template>
  <span>
    <q-item class="invoice">
      <q-item-section>
        {{ value.number }}
      </q-item-section>
      <q-item-section>
        {{ value.raised_at.date | date }}
      </q-item-section>
      <q-item-section v-if="value.user">
        {{ value.user.display_name }}
      </q-item-section>
      <q-item-section v-if="value.organisation">
        {{ value.organisation.name }}
      </q-item-section>
      <q-item-section side>
        {{ value.gross_amount.text }}
      </q-item-section>
    </q-item>
    <q-item v-for="item in value.items" :key="value.numer + item.description" :inset-level="inset ? 0.5 : null">
      <q-item-section class="ellipsis text-caption">
        {{ item.description }}
      </q-item-section>
      <q-item-section side>
        {{ item.gross_amount.text }}
      </q-item-section>
    </q-item>
  </span>
</template>
<script>
import date from 'utils/date-time'
export default {
  filters: {
    date (data) {
      return data ? date.toShortDate(data) : null
    }
  },
  props: { value: Object, inset: Boolean }
}
</script>
<style lang="stylus" scoped>
.invoice
  border-bottom 1px solid rgba(0, 0, 0, 0.125)
.q-item
  background-color #fafafa
  box-shadow 0px 4px 5px rgba(0, 0, 0, 0.125)
  &:hover
    background-color #efefef
</style>
