import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

/**
 * Pretty Ronseal, this is the Sentry plugin in which we config and initiate the plugin.
 * If we're in production, the DSN will be grabbed from the .env.production file.
 *
 * If you're wanting to use Sentry in your local environment, you'll need to set up your own account
 * and set your DSN in your own .env.local file.
 */

// If exception includes one of these messages, don't send to Sentry
const ignoreErrors = [
  'Failed to fetch'
]

export default ({ Vue, router }) => {
  if (!process.env.VUE_APP_SENTRY_DSN) {
    console.error('Cannot start Sentry, because DSN has not been provided.')
    return
  }

  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'mobilleo.net', /^\//]
      })
    ],
    tracesSampleRate: 0.2,
    trackComponents: true,
    hooks: ['activate', 'create', 'mount', 'update'],
    beforeBreadcrumb (breadcrumb, hint) {
      if (breadcrumb.category === 'ui.click') {
        const { target } = hint.event
        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel
        } else if (target.innerText) {
          breadcrumb.message = target.innerText
        }
      }
      return breadcrumb
    },
    beforeSend (event, hint) {
      if (ignoreErrors.includes(hint.originalException.message)) {
        return null
      }
      return event
    }
  })
}
