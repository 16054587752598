<template>
  <div class="map-container shadow-5">
    <gmap-map
      v-if="points"
      ref="map"
      v-bind="gmaps"
      :center="markers.start"
      class="map flex items-center justify-center"
      @idle="mapLoaded"
    >
      <gmap-polyline
        :path="points"
        :options="{strokeColor, strokeWeight: 3}"
      />
      <gmap-marker
        v-for="(marker, index) in markers"
        :key="index"
        :position="marker"
        :icon="{
          url: require(`assets/geo.png`),
          size: {width: 46, height: 46, f: 'px', b: 'px'},
          scaledSize: {width: 46, height: 46, f: 'px', b: 'px'},
          anchor: {x: 23, y: 23}
        }"
      />
    </gmap-map>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['points'],
  data () {
    return {
      loaded: false,
      gmaps: {
        options: {
          disableDefaultUI: true,
          draggable: false,
          zoomable: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    markers () {
      if (this.points) {
        return {
          start: this.points.slice(0)[0],
          end: this.points.slice(-1)[0]
        }
      }
      return null
    },
    strokeColor () {
      return this.partner.hex
    }
  },
  methods: {
    mapLoaded () {
      if (this.loaded) return
      this.loaded = true
      let bounds = new window.google.maps.LatLngBounds()
      bounds.extend(this.markers.start)
      bounds.extend(this.markers.end)
      this.$refs.map.resize()
      this.$refs.map.fitBounds(bounds)
    }
  }
}
</script>

<style lang="stylus" scoped>
.map, .map-container
  height 25vh
  width 100%
  font-size 8vmax
  color rgba(255, 255, 255, .7)
  overflow hidden
  background #d1cfcf
  text-overflow: clip;
</style>
