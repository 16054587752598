<template>
  <peek-form
    :title="$tc('content_type.onwardtravel')"
    :subtitle="$t('mobility_budget.modify_preferences')"
    :footer-title="$t('tooltip.tailor_your_choices')"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$tc('budget')" />
      <q-card class="bg-white">
        <q-card-section>
          <m-money-field
            v-model="policy.budget"
            :label="$t('onwardtravel.cost_per_mile.policy')"
            :error="$v.policy.budget.$error"
            :hint="$t('permitted_cost.taxi')"
            @blur="$v.policy.budget.$touch"
          />
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import { MGroupTitle, MMoneyField } from 'components/'

export default {
  components: {
    peekForm,
    MMoneyField,
    MGroupTitle
  },
  data () {
    return {
      organisation: null,
      loading: true,
      policy: {
        vehicle_groups: [],
        budget: null
      }
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user'
    })
  },
  beforeMount () {
    this.loading = true
    if (this.$route.query && this.$route.query.slug) this.organisation = this.lookupOrg(this.$route.query.slug)
    else this.organisation = this.orgGetter
    if (!this.organisation) this.$router.push({ path: this.$route.path })

    this.policy = this.organisation.attributes.transport_policy['onwardtravel']
  },
  validations: {
    policy: {
      vehicle_groups: { },
      budget: { }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (!this.$v.$error) {
        this.$store.dispatch('organisations/setTransportBudget', {
          slug: this.organisation.slug,
          params: {
            onwardtravel: this.policy
          }
        }).then(() => {
          this.$router.back()
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
</style>
