import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './en'

const messages = {
  en
}

const numberFormats = {
  'en': {
    currency: {
      style: 'currency',
      currency: 'GBP'
    }
  }
}

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  numberFormats
})

export default i18n
