<template>
  <q-dialog ref="dialogRef" persistent>
    <q-card style="min-width: 900px">
      <q-card-section class="text-capitalize text-h6">
        {{ mode }} Credit Line
      </q-card-section>
      <q-card-section class="row q-col-gutter-sm">
        <div class="col-2">
          <q-input
            v-model="quantity"
            label="Quantity"
            type="number"
            filled
            square
          />
        </div>
        <div class="col-4">
          <q-input
            v-model="description"
            :hint="`${description.length}/255 max characters`"
            label="Description"
            autogrow
            filled
            square
          />
        </div>
        <div class="col-2">
          <q-input
            v-model="unitPrice"
            label="Unit Price"
            type="number"
            prefix="£"
            filled
            square
          />
        </div>
        <div class="col-2">
          <q-select
            v-model="taxRate"
            :options="taxOptions"
            label="Tax Rate"
            filled
            square
          />
        </div>
        <div class="col">
          <q-checkbox v-model="includesTax" label="Includes tax" />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn v-close-popup label="cancel" />
        <q-btn
          v-close-popup
          :label="`${mode} line`"
          color="green"
          :disable="$v.$invalid"
          @click="onSubmit"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { required, numeric, minValue, maxLength } from 'vuelidate/lib/validators'
export default {
  name: 'CreditDialog',
  props: {
    mode: {
      type: String,
      default: 'add'
    }
  },
  data () {
    return {
      index: null,
      quantity: 0,
      description: '',
      unitPrice: 0,
      taxRate: null,
      includesTax: false,
      taxOptions: [
        {
          label: 'Zero Rate',
          value: 'zero'
        },
        {
          label: 'Standard Rate',
          value: 'standard'
        },
        {
          label: 'Reduced Rate',
          value: 'reduced'
        },
        {
          label: 'Super-reduced Rate',
          value: 'superreduced'
        },
        {
          label: 'Parking Rate',
          value: 'parking'
        }
      ]
    }
  },
  methods: {
    openDialog (initial = {}, index) {
      this.quantity = initial.quantity || 0
      this.description = initial.description || ''
      this.unitPrice = initial.unitPrice / 100 || 0
      this.taxRate = initial.taxRate || null
      this.includesTax = initial.includesTax || false
      this.$refs.dialogRef.show()
      this.index = !isNaN(index) ? index : null // If there is an index param, check if it's a number because 0 would be false
    },
    onSubmit () {
      this.$emit('submit', {
        quantity: this.quantity,
        description: this.description,
        unitPrice: this.unitPrice * 100,
        taxRate: this.taxRate,
        includesTax: this.includesTax
      }, !isNaN(this.index) ? this.index : null)
    }
  },
  validations: {
    quantity: {
      required,
      numeric,
      minValue: minValue(1)
    },
    description: {
      required,
      maxLength: maxLength(255)
    },
    unitPrice: {
      required,
      minValue: minValue(0.01)
    },
    taxRate: {
      required
    }
  }
}
</script>
