import { cloneDeep } from 'lodash'

// initial state, we store it like this so we can reset easier
const initial = {
  client: null,
  environment: null,
  style: null
}

// Copy inital state over to the state var
const state = cloneDeep(initial)
const getters = {
  search (state) {
    return {
      client: state.client,
      env: state.environment,
      style: state.style
    }
  }
}

const actions = {
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
