<template>
  <div class="overview row">
    <div class="col-5">
      {{ $t('onwardtravel.cost_per_mile.policy') }}
    </div>
    <div
      class="col-6 text-dark"
    >
      {{ policy && policy.budget ? `£${Number(policy.budget / 100).toFixed(2)}` : $t('no_budget_set') }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['policy']
}
</script>

<style lang="stylus" scoped>
.overview > div, .repeater > div
  padding 6px 0

</style>
