<template>
  <div>
    <q-list v-if="lines.length" separator bordered>
      <preview-line
        v-for="(line, index) in lines"
        :key="index"
        :show-remove="lines.length > 1"
        v-bind="{line, index}"
        @editLine="editLine"
        @removeLine="removeLine"
      />
    </q-list>
    <div class="text-center q-mt-md">
      <q-chip icon="add_circle" :label="lines.length ? 'Add extra credit line' : 'Create credit line'" color="primary" text-color="white" class="cursor-pointer" @click.native="addLine()" />
    </div>
    <credit-dialog ref="dialogRef" mode="add" @submit="submitLine" />
  </div>
</template>
<script>
import previewLine from './preview-line'
import creditTaxCalculations from 'mixins/creditTaxCalculations.js'
import creditDialog from '../creditDialog.vue'
export default {
  components: {
    previewLine,
    creditDialog
  },
  mixins: [creditTaxCalculations],
  props: ['model', 'value', 'error'],
  data () {
    return {
      salesLineModal: false,
      modifyLine: 0,
      mode: 'add',
      lines: []
    }
  },
  methods: {
    submitLine (line, index) {
      if (index !== null) {
        this.lines.splice(index, 1, line) // Triggers vue render, just replaces the element specified
      } else {
        this.lines.push(line)
      }
      this.$emit('updateLines', this.lines)
    },
    addLine () {
      this.$refs.dialogRef.openDialog()
      this.mode = 'add'
    },
    removeLine (line = null) {
      if (line !== null) this.lines.splice(line, 1)
      this.salesLineModal = false
    },
    editLine (index) {
      this.mode = 'edit'
      this.$refs.dialogRef.openDialog(this.lines[index], index)
    }
  }
}
</script>
