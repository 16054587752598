<template>
  <q-card v-if="org" class="bg-white text-dark">
    <q-list no-border separator>
      <policy-list-item
        v-for="type in contentTypes"
        :key="type.name"
        :info="type"
        :policy="policy ? policy[type.name] : null"
        :org="org"
      />
    </q-list>
  </q-card>
</template>

<script>
import policyListItem from './policy-list-item'
export default {
  components: { policyListItem },
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      contentTypes: [
        {
          name: 'onwardtravel',
          title: this.$tc('content_type.onwardtravel', 2)
        }
      ]
    }
  },
  computed: {
    policy () {
      return this.org ? this.org.attributes.transport_policy : null
    }
  }
}
</script>

<style>

</style>
