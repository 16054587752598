<template>
  <div v-if="!bikehireLoading" class="status" :class="{ active: bikehireStatus.registered }">
    {{ $tc('active_inactive', bikehireStatus.registered) }}
  </div>
  <div v-else class="status">
    <q-skeleton type="text" />
  </div>
</template>
<script>
import bikehireMixin from 'mixins/bikehire.js'
export default {
  mixins: [bikehireMixin]
}
</script>
<style lang="stylus" scoped>
.status
  display grid
  padding 1em 0
  justify-content center
  align-items center
  color $negative

  &.active
    color $positive
</style>
