<template>
  <peek-form
    :title="$t('organisation.labels')"
    :footer="false"
  >
    <div class="q-pa-md">
      <div class="row q-col-gutter-sm">
        <div class="col">
          <m-group-title :label="$t('existing_labels')" :sublabel="$t('label.existing_tooltip')" />
        </div>
        <div class="col-12 col-md-5 col-xl-3 column justify-center">
          <q-btn
            :disable="loading"
            color="primary"
            :label="$t('label.create')"
            class="full-width"
            dense
            no-caps
            @click="$router.push({ hash: '/organisation/labels/form' })"
          />
        </div>
      </div>
      <div class="q-my-sm">
        <q-list v-if="labels.length" class="bg-white" bordered separator>
          <q-item v-for="label in labels" :key="`label-${label.name}`" clickable @click="editLabel(label.$id)">
            <q-item-section>
              <div>
                <m-label
                  :name="label.name"
                  :description="label.description"
                  :colour="label.colour"
                  square
                  dense
                />
              </div>
            </q-item-section>
            <q-item-section v-if="hasPermission('can.update.labels')" side>
              <q-btn
                :disable="loading"
                :label="$t('edit')"
                color="primary"
                dense
                flat
                @click="editLabel(label.$id)"
              />
            </q-item-section>
            <q-item-section v-if="hasPermission('can.destroy.labels')" side>
              <q-btn
                :disable="loading"
                :label="$t('delete')"
                color="primary"
                dense
                flat
                @click="destroy(label.$id)"
              />
            </q-item-section>
          </q-item>
        </q-list>
        <div v-if="loading" class="text-center text-caption q-ma-md">
          {{ $t('loading.checking.labels') }}
        </div>
        <m-empty-state v-else-if="labels.length < 1" icon="label" :label="$t('no_existing_labels')" />
      </div>
    </div>
  </peek-form>
</template>
<script>
import Label from 'store/models/label'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { MGroupTitle, MLabel, MEmptyState } from 'components/'

export default {
  components: {
    peekForm,
    MGroupTitle,
    MLabel,
    MEmptyState
  },
  mixins: [authentication],
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['organisation']),
    labels () {
      return Label.all()
    }
  },
  mounted () {
    this.loadLabels()
  },
  methods: {
    loadLabels () {
      this.loading = true
      this.$store.dispatch('entities/labels/loadAllLabels').then(() => {
        this.loading = false
      })
    },
    destroy (labelId) {
      this.$store.dispatch('entities/labels/deleteLabel', labelId).then(() => {
        this.$q.notify({
          message: this.$t('label.deleted'),
          color: 'positive',
          icon: 'delete'
        })
      })
    },
    editLabel (labelId) {
      this.$router.push({ hash: '/organisation/labels/form', query: { label: labelId } })
    }
  }
}
</script>
<style lang="stylus" scoped>
  .display-chip
    animation-name alive
    animation-duration 0.75s
  a
    cursor pointer
    &:hover
      color primary
      text-decoration underline

  @keyframes alive
    0%
      transform scale(1.5)
    100%
      transform scale(1)
</style>
