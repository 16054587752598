<template>
  <div v-if="loading" />
  <div v-else-if="!hasPermission('feature.mbapi.312.mango')" class="text-center">
    <h6 class="q-py-md">
      Coming soon to DerbyGo
    </h6>
    <div class="text-body2 q-mb-sm">
      mango saves you money on bus travel in Derby & across the East Midlands.
    </div>
    <div class="text-body2 q-mb-sm">
      Using our clever little app you scan on as you board, and scan off as
      you leave.
    </div>
    <div class="text-body2 q-mb-sm">
      We do the rest & make sure you always pay the right fare.
    </div>
    <div class="text-body2 q-mb-sm">
      There are really juicy discounts for students too!
    </div>
    <div class="text-body2 q-mb-sm">
      Go to
      <a class="mango-link" href="https://www.trentbarton.co.uk/mangoapp">mango</a>
    </div>
  </div>
  <div v-else-if="!hasCredentials" class="register-grid">
    <div class="signin_text">
      {{ $t('travel_providers.mango.signin_prompt') }}
    </div>
    <div class="signin_button">
      <a :href="mangoSigninLink">{{ $t('travel_providers.signin_action') }}</a>
    </div>
    <div class="join_text">
      {{ $t('travel_providers.mango.join_prompt') }}
    </div>
    <div class="join_button">
      <q-btn
        :label="$t('travel_providers.join_action')"
        color="primary"
        @click="$router.push({ hash: '/mango/signup' })"
      />
    </div>
  </div>
  <div v-else class="delete-grid">
    <div class="signin_text">
      {{ $t('travel_providers.change_details') }}
    </div>
    <div class="signin_button">
      <q-btn
        :disable="loading"
        :label="$t('travel_providers.change_details_action')"
        color="primary"
        @click="deleteDialog = true"
      />
    </div>
    <q-dialog v-model="deleteDialog">
      <q-card>
        <div class="text-h6 q-mb-sm">
          {{ $t('confirm.are_you_sure') }}
        </div>
        <div
          v-for="text in $t('mango.delete_blurb')"
          :key="text"
          class="text-body2 q-mb-sm"
        >
          {{ text }}
        </div>

        <q-card-actions align="between">
          <q-btn color="primary" outline @click="deleteDialog = false">
            {{ $t('confirm.go_back') }}
          </q-btn>
          <q-btn color="primary" @click="removeDetails">
            {{ $t('travel_providers.delete_details') }}
          </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
import { removeMangoDetails } from 'api/prepay'
import loading from 'utils/loading'
import authentication from 'mixins/authentication'

export default {
  mixins: [authentication],
  props: {
    links: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false,
      deleteDialog: false,
      mangoSigninLink: process.env.VUE_APP_MANGO_SIGNIN
    }
  },
  computed: {
    ...mapGetters({
      userLookup: 'userLookup',
      partner: 'partner'
    }),
    hasCredentials () {
      return !!this.$store.getters['mango/getCredentials']
    }
  },
  async mounted () {
    if (!this.hasCredentials) {
      this.loading = true
      try {
        loading.start({
          partnerSlug: this.partner.slug,
          spinnerColor: this.partner.hex,
          message: this.$t('loading.checking.your_details')
        })
        await this.$store.dispatch(
          'mango/fetchCredentials',
          this.userLookup.value
        )
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
        this.loading = false
      }
    }
  },
  methods: {
    async removeDetails () {
      try {
        this.loading = true
        loading.start({
          partnerSlug: this.partner.slug,
          spinnerColor: this.partner.hex,
          message: 'Updating details'
        })
        await removeMangoDetails(this.userLookup.value)
        this.$store.dispatch('mango/storeCredentials', null)
        this.$q.notify({
          type: 'positive',
          icon: 'check',
          message: this.$t('mango.details_deleted')
        })
      } catch (err) {
        handleErrors(err)
      } finally {
        loading.stop()
        this.loading = false
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.register-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr 1fr

  .q-btn
    width 100%

.delete-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr

  .q-btn
    width 100%

.q-card
  width 80vw
  min-height 50vw
  padding 8px
  text-align center
  .q-card__actions
    padding 0
    padding-top 8px
    .q-btn
      min-width 45%

.signin_button a
  display block
  background-color var(--q-color-primary)
  color white
  border-radius 4px
  padding-block 6px
  margin-block 4px
  text-transform uppercase
  text-align center
  font-size 14px
  line-height 1.715em
  font-weight 500
</style>
