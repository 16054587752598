import _ from 'lodash'

const initalState = {
  invoicesToCredit: [],
  salesCreditLines: []
}

const state = _.cloneDeep(initalState)

const getters = {
  getInvoicesToCredit: state => {
    return _.cloneDeep(state.invoicesToCredit)
  }
}

const actions = {
  setInvoicesToCredit ({ commit }, invoices) {
    commit('setInvoicesToCredit', invoices)
  },
  clearInvoicesToCredit ({ commit }) {
    commit('clearInvoicesToCredit')
  }
}

const mutations = {
  setInvoicesToCredit (state, invoices) {
    state.invoicesToCredit = invoices
  },
  clearInvoicesToCredit (state) {
    state.invoicesToCredit = []
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
