import { hasRole } from 'utils/authentication'
import getPartnerFromHostname from 'utils/partner'

const partner = getPartnerFromHostname()

const beforeEnter = (to, from, next) => {
  if (!['gohi', 'swift'].includes(partner.slug)) next('/')
  else (hasRole('guest')) ? next('guest-mode') : next()
}

export const index = {
  name: 'Tickets',
  path: '/tickets',
  meta: {
    label: 'Tickets'
  },
  components: {
    default: () => import(/* webpackChunkName: "tickets" */ 'pages/tickets/index.vue')
  },
  beforeEnter
}

export const show = {
  name: 'ticket-show',
  path: '/tickets/:reference',
  components: {
    default: () => import(/* webpackChunkName: "tickets" */ 'pages/tickets/show/index.vue')
  },
  meta: {
    label: 'Your Ticket'
  },
  beforeEnter
}

export default { index, show }
