/* eslint-disable no-console */
import { register } from 'register-service-worker'
import { Notify } from 'quasar'
import router from 'router'
import i18n from 'i18n'
/**
 * Here we setup our service-worker helper more docs of which can be found at
 * https://github.com/yyx990803/register-service-worker
 */
register('/service-worker.js', {
  updatefound () {
    /**
     * A service worker update is found and so we observe for when it
     * changes so that we can reload the browser and load in the new assets.
     *
     * We can't do this in service-worker.js as they're not allowed access to
     * the window object.
     */
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      window.location.reload()
    })
  },
  updated (sw) {
    /**
     * If current route is public then we're not logged in and so we can
     * just refresh the browser without showing any update dialog.
     */
    if (router.currentRoute.meta.public) {
      sw.waiting.postMessage({ action: 'skipWaiting' })
      return
    }

    /**
     * Show a little warning to the user to inform them of the update,
     * we won't activate the new servicer worker until they either close
     * all tabs down or hit refresh.
     */
    Notify.create({
      message: i18n.t('update_available'),
      timeout: 0,
      color: 'yellow',
      textColor: 'black',
      icon: 'update',
      position: 'bottom-right',
      actions: [
        {
          label: i18n.t('refresh'),
          handler () {
            sw.waiting.postMessage({ action: 'skipWaiting' })
          },
          textColor: 'black'
        }
      ]
    })
  }
})
