export const index = {
  name: 'members',
  path: '/members',
  components: {
    default: () => import(/* webpackChunkName: "members" */ 'pages/members/index.vue')
  }
}

export const show = {
  name: 'members-view',
  path: '/organisations/:organisation/members/:username',
  redirect: '/organisations/:organisation/members/:username/profile',
  components: {
    default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/index.vue')
  },
  children: [
    {
      path: 'profile',
      redirect: 'bookings'
    },
    {
      path: 'bookings',
      components: {
        default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/bookings.vue')
      }
    },
    {
      path: 'journeys',
      components: {
        default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/journeys.vue')
      }
    },
    {
      path: 'invoices',
      components: {
        default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/invoices.vue')
      }
    },
    {
      path: 'notes',
      components: {
        default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/notes.vue')
      }
    },
    {
      path: 'history',
      components: {
        default: () => import(/* webpackChunkName: "members" */ 'pages/members/view/history.vue')
      }
    }
  ]
}

export default { index, show }
