import { Model } from '@vuex-orm/core'

export default class Permission extends Model {
  static entity = 'permissions'

  static primaryKey = 'slug'

  static fields () {
    return {
      name: this.string(),
      slug: this.string(),
      description: this.string('').nullable(),
      roles: this.attr([])
    }
  }
}
