import { hasRole } from 'utils/authentication'
/**
 * Route requires authed user to have required role before they can navigate to it.
 */
export default (to, from, next) => {
  if (to.matched.some(record => record.meta.role && !hasRole(record.meta.role))) {
    return next({
      name: 'dashboard'
    })
  }
  next()
}
