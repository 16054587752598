<template>
  <peek-form
    :title="title"
    :footer="false"
  >
    <provider-list v-if="!provider" />
    <provider-view v-else :provider="provider" />
  </peek-form>
</template>
<script>
import providerList from './provider-list.vue'
import providerView from './provider-view.vue'
import peekForm from '../peekForm.vue'

import providers from 'mixins/providers.js'
import travelContents from 'mixins/travelContents.js'
export default {
  name: 'TravelProvidersIndex',
  components: {
    providerList,
    providerView,
    peekForm
  },
  mixins: [ travelContents, providers ],
  computed: {
    contentType () {
      return this.$route.query?.content || null
    },
    providerSlug () {
      return this.$route.query?.provider || null
    },
    title () {
      return this.provider?.name || this.$t('travel_providers.travel_providers')
    },
    provider () {
      if (!this.contentType && !this.providerSlug) return null
      const providerSlug = this.providerSlug || this.getTravelContentProvider(this.contentType)
      return {
        slug: providerSlug,
        ...this.providers[providerSlug]
      }
    }
  }
}
</script>
<style lang="stylus" scoped>

</style>
