<template>
  <peek-form
    :title="title"
    :footer-title="footerTitle"
    :call-to-action="callToAction"
    @save="submit"
  >
    <q-page v-if="contextual" class="bg-white column items-center no-wrap">
      <route-map :points="contextual.map.points" />
      <div class="row justify-around q-pa-lg">
        <info-card
          :type="contextual.content_type"
          :title="contextual.title"
          :description="contextual.description"
          :depart="contextual.depart_at"
          :arrive="contextual.arrive_at"
          :price="type !== 'bus' && type !== 'tram' ? contextual.price : ''"
          :duration="contextual.duration"
          :weather="contextual.weather"
          :co2="contextual.co2"
          :show-carclub-logo="contextual.route.some(r => { return r.type.includes('carclub') })"
        />
        <timeline
          :type="type"
          :route="contextual.route"
        />
      </div>
    </q-page>
    <div v-else class="loading">
      <component :is="spinnerName" size="64" :color="partner.hex" />
    </div>
  </peek-form>
</template>

<script>
import { MSpinnerArrow, MSpinnerGeo, MSpinnerKinto } from 'components/'
import peekForm from '../peekForm.vue'
import routeMap from './route-map.vue'
import infoCard from './info-card.vue'
import timeline from './timeline'
import { mapGetters } from 'vuex'
import travelContents from 'mixins/travelContents'
import date from 'utils/date-time'
import authentication from 'mixins/authentication'
import rentalModel from 'pages/ondemand/car-hire/model'
import railModel from 'pages/ondemand/train/model'
import flightModel from 'pages/ondemand/flights/model'
import busModel from 'pages/ondemand/bus/model'

export default {
  name: 'SmartSearchDetails',
  components: {
    MSpinnerArrow,
    MSpinnerGeo,
    MSpinnerKinto,
    peekForm,
    routeMap,
    infoCard,
    timeline
  },
  mixins: [authentication, travelContents],
  computed: {
    ...mapGetters({
      smartsearch: 'smartsearch/results',
      parameters: 'smartsearch/parameters',
      contextualIndex: 'smartsearch/contextual',
      user: 'user',
      partner: 'partner'
    }),
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    },
    carclubCredentialsUnset () {
      return this.type === 'carclub' && !this.user.has_carclub
    },
    callToAction () {
      return this.canBook
        ? this.carclubCredentialsUnset
          ? this.$t('carclub.add_details')
          : this.$t('book')
        : this.$t('done')
    },
    canBook () {
      if (this.type === 'tram') return false
      if (this.type === 'bus' && !['gohi', 'swift'].includes(this.partner.slug)) return false
      return this.hasTypePermission(this.type)
    },
    footerTitle () {
      return this.canBook
        ? this.carclubCredentialsUnset
          ? this.$t('carclub.credentials.required')
          : this.$t('smartsearch.suggested_route')
        : this.type === 'bus' || this.type === 'tram'
          ? this.$t('smartsearch.information_currently')
          : this.$t('smartsearch.information_only')
    },
    contextual () {
      return this.contextualIndex[this.type]
    },
    title () {
      return this.$t('place_to_place', { origin: this.parameters.origin.label, destination: this.parameters.destination.label })
    },
    type () {
      return this.$route.query.type
    }
  },
  async created () {
    if (!this.contextualIndex[this.type]) {
      try {
        await this.$store.dispatch('smartsearch/contextualInfo', this.type)
      } catch (err) {
        this.$router.push('/smart-search')
        this.$q.notify({ color: 'negative', icon: 'clear', message: err.data.message })
      }
    }
  },
  methods: {
    icon () {
      return this.getTravelContentIcon(this.type)
    },
    color () {
      return this.getTravelContentColor(this.type)
    },
    route () {
      return this.getTravelContentRoute(this.type, 'results')
    },
    /**
     * As the API is not returning what we need as part of the contexutal
     * response we need to build these up for each content type, this is a
     * total PITA but only option we have right now.
     */
    submit () {
      if (!this.canBook) {
        this.$router.back()
        return
      }
      if (this.type === 'rental') {
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...rentalModel(this.$route),
            traveller: this.parameters.user,
            requester: this.parameters.requester,
            destination: this.contextual.query_parameters.destination,
            return: this.contextual.query_parameters.return,
            pickup: {
              location: {
                value: this.contextual.query_parameters.origin
              },
              date: this.contextual.query_parameters.pickup
            },
            dropoff: {
              location: {
                value: this.contextual.query_parameters.destination
              },
              date: this.contextual.query_parameters.return
                ? this.contextual.query_parameters.return
                : date.addToDate(this.contextual.query_parameters.return, { days: 1 })
            }
          } }
        ).then(() => {
          this.$router.push({
            name: this.route()
          })
        })
      } else if (this.type === 'rail') {
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...railModel(),
            traveller: this.parameters.user,
            requester: this.parameters.requester,
            origin: {
              station: null,
              value: this.contextual.query_parameters.origin
            },
            destination: {
              station: null,
              value: this.contextual.query_parameters.destination
            },
            depart: this.contextual.query_parameters.depart,
            return: this.contextual.query_parameters.return
              ? this.contextual.query_parameters.return
              : null,
            adults: 1,
            children: 0
          } }
        ).then(() => {
          this.$router.push({
            name: this.route()
          })
        })
      } else if (this.type === 'flight') {
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...flightModel(),
            traveller: this.parameters.user,
            requester: this.parameters.requester,
            origin: {
              label: this.parameters.origin.label,
              value: this.contextual.query_parameters.origin
            },
            destination: {
              label: this.parameters.destination.label,
              value: this.contextual.query_parameters.destination
            },
            outbound: this.contextual.query_parameters.outbound_flight_time_from,
            inbound: this.contextual.query_parameters.inbound_flight_time_from
              ? this.contextual.query_parameters.inbound_flight_time_from
              : null,
            children: 0,
            infants: 0,
            adults: 1
          } }
        ).then(() => {
          this.$router.push({
            name: this.route()
          })
        })
      } else if (this.type === 'bus') {
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...busModel(),
            traveller: this.parameters.user,
            requester: this.parameters.requester,
            origin: {
              value: this.contextual.query_parameters.origin
            },
            destination: {
              value: this.contextual.query_parameters.destination
            },
            depart: this.contextual.query_parameters.depart
          } }
        ).then(() => {
          this.$router.push({
            name: this.route()
          })
        })
      } else if (this.type === 'ridehailing') {
        this.$store.dispatch('ondemand/stash', {
          parameters: {
            ...this.parameters,
            time: this.parameters.depart
          }
        })
        if (this.hasRole('middleoffice') || this.hasRole('backoffice') || this.hasRole('admin')) {
          Promise.all([
            this.$store.dispatch('rideHailing/setTraveller', this.parameters.user),
            this.$store.dispatch('rideHailing/setRequester', this.parameters.requester),
            this.$store.dispatch('rideHailing/setLocation', {
              field: 'origin',
              location: {
                label: this.parameters.origin.label,
                value: this.contextual.query_parameters.origin
              }
            }),
            this.$store.dispatch('rideHailing/setLocation', {
              field: 'destination',
              location: {
                label: this.parameters.destination.label,
                value: this.contextual.query_parameters.destination
              }
            })
          ])
            .then(() => {
              this.$store.dispatch('rideHailing/setValue', { prop: 'time', payload: this.contextual.query_parameters.time })
              this.$store.dispatch('rideHailing/search', true)

              this.$router.push({ name: 'ondemand-office-ride-hailing-results' })
            })
        } else {
          this.$router.push({ name: 'ondemand-ride-hailing-results' })
        }
      } else if (this.type === 'carclub') {
        if (this.user.has_carclub) {
          Promise.all([
            this.$store.dispatch('carClub/setTraveller', this.parameters.user),
            this.$store.dispatch('carClub/setRequester', this.parameters.requester),
            this.$store.dispatch('carClub/setSearchLocation', {
              label: this.parameters.origin.label,
              value: this.contextual.query_parameters.location
            }),
            this.$store.dispatch('carClub/setSearchStartDateTime', date.newDate(this.contextual.query_parameters.start)),
            this.$store.dispatch('carClub/setSearchEndDateTime', date.newDate(this.contextual.query_parameters.end))
          ])
            .then(() => {
              this.$router.push({ name: 'ondemand-carclub-results' })
            })
        } else {
          this.$router.push({ hash: '#/user-settings/carclub' })
        }
      } else {
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.loading
  display grid
  width 100%
  height 100%

  *
    margin auto
</style>
