<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['organisation'])
  },
  mounted () {
    this.$router.push(`/organisations/${this.$route.query.slug || this.organisation.slug}/booking-auth`)
  }
}
</script>
