import Vue from 'vue'

/**
 * This module is used to store data from Native app wrappers (iOS, Android, etc)
 * They can use the setter methods defined in /src/plugins/native-store.js
 */

const state = {
  data: {
    deviceId: '12345'
  }
}

const getters = {
  get: state => key => {
    return state.data[key]
  },
  all: state => state.data
}

const actions = {
  set: ({ commit }, { key, value }) => {
    commit('set', { key, value })
    return { key, value }
  },
  store: ({ commit }, data) => { // WARNING: this is destructive and will overwrite existing data
    commit('store', data)
    return data
  },
  reset: ({ commit }) => {
    commit('store', {})
  }
}

const mutations = {
  set: (state, { key, value }) => {
    Vue.set(state.data, key, value)
  },
  store: (state, data) => {
    state.data = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
