import { hasPermission } from 'utils/authentication'

const beforeEnter = (to, from, next) => {
  if (!hasPermission('search.bikeshare')) return next('/')
  else return next()
}

const meta = {
  layout: {
    name: 'basic',
    bottomsheet: true,
    components: { footer: 'MMobileFooter' }
  }
}

const findBike = {
  name: 'ondemand-bikeshare',
  path: '/on-demand/bikeshare',
  components: {
    default: () =>
      import(
        /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/index.vue'
      )
  },
  meta,
  beforeEnter
}

const bikeDetails = {
  name: 'ondemand-bikeshare-details',
  path: '/on-demand/bikeshare/details',
  components: {
    default: () =>
      import(
        /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/details.vue'
      )
  },
  meta,
  beforeEnter
}

const requestCode = {
  name: 'ondemand-bikeshare-code',
  path: '/on-demand/bikeshare/code',
  components: {
    default: () =>
      import(
        /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/code.vue'
      )
  },
  meta,
  beforeEnter
}

const authenticate = {
  name: 'ondemand-bikeshare-authenticate',
  path: '/on-demand/bikeshare/authenticate',
  components: {
    default: () =>
      import(
        /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/authenticate.vue'
      )
  },
  meta,
  beforeEnter
}

const signUp = {
  name: 'ondemand-bikeshare-signup',
  path: '/on-demand/bikeshare/signup',
  components: {
    default: () =>
    import(
      /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/signup.vue'
    )
  },
  meta,
  beforeEnter
}

const success = {
  name: 'ondemand-bikeshare-success',
  path: '/on-demand/bikeshare/success',
  components: {
    default: () =>
    import(
      /* webpackChunkName: "ondemand-bikeshare" */ 'pages/ondemand/bikeshare/success.vue'
    )
  },
  meta,
  beforeEnter
}

export default { findBike, bikeDetails, requestCode, authenticate, signUp, success }
