import _ from 'lodash'

const initial = {
  cycleNumber: null
}

const state = _.cloneDeep(initial)

const getters = {
  cycleNumber: state => state.cycleNumber
}

const actions = {
  setCycleNumber ({ commit }, val) {
    commit('cycleNumber', val)
  }
}

const mutations = {
  cycleNumber (state, val) {
    state.cycleNumber = val
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
