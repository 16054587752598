<template>
  <div style="padding-bottom: 60px;">
    <!-- Title bar -->
    <div class="titleBar q-px-lg q-pt-md q-pb-sm row grid-title">
      <div class="col text-h6 btn-offset">
        {{ $t('profile') }}
      </div>
      <div class="col row justify-end">
        <q-btn
          icon="far fa-times"
          flat
          dense
          unelevated
          color="primary"
          :to="{hash: ''}"
        />
      </div>
    </div>
    <q-scroll-area v-if="!hasRole('guest')" class="scroller">
      <!-- Avatar -->
      <div class="avatarSection text-center">
        <q-avatar class="cursor-pointer shadow-5" size="80px" color="primary-tint">
          <m-image :src="user.picture || require('assets/avatar.png')" />
        </q-avatar>
        <div class="text-primary text-h6">
          {{ user.display_name }}
        </div>
      </div>

      <!-- Mobility Budget -->
      <hr class="q-separator q-separator--horizontal col-grow q-my-sm">
      <div class="budgetSection text-center">
        <div class="col text-h6">
          {{ $tc('journey.journey', 2) }}
        </div>
        <div class="q-ma-lg">
          <div class="row q-col-gutter-sm">
            <template>
              <div v-for="button in mainButtons" :key="`support-${button.label}`" class="col-6">
                <q-btn
                  color="grey-3"
                  text-color="grey-9"
                  class="fit rounding"
                  :icon="`fas fa-${button.icon}`"
                  :label="button.label"
                  unelevated
                  no-caps
                  :to="button.link"
                  align="left"
                />
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- Finance -->
      <template v-if="hasRole('onwardtravel.reports')">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ finance.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template>
            <div v-for="button in finance.buttons" :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                text-color="grey-9"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Organisation Settings -->
      <template v-if="hasRole('middleoffice') && !hasRole(['backoffice', 'admin', 'middleoffice.outsource'])">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ policyManager.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template>
            <div v-for="button in policyButtons" :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                text-color="grey-9"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- My Travel -->
      <template v-if="!hasRole(['backoffice', 'admin', 'middleoffice.outsource'])">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ myTravel.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template>
            <div v-for="button in myTravelButtons" :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                text-color="grey-9"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Memberships -->
      <template v-if="!hasRole(['backoffice', 'admin', 'middleoffice.outsource']) && !['derbygo'].includes(partner.slug)">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ memberships.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template>
            <div v-for="button in membershipsButtons" :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                text-color="grey-9"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>

      <!-- Roles & Permissions -->
      <template v-if="showAdminSection">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ $t('system') }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <div class="col-6 ">
            <q-btn
              color="grey-3"
              text-color="grey-9"
              class="fit rounding"
              icon="fas fa-user-tag"
              :label="$tc('role', 2)"
              unelevated
              no-caps
              align="left"
              :to="{ hash: '/system-settings/roles'}"
            />
          </div>
          <div class="col-6 ">
            <q-btn
              color="grey-3"
              text-color="grey-9"
              class="fit rounding"
              icon="fas fa-ballot-check"
              :label="$tc('permission', 2)"
              unelevated
              no-caps
              align="left"
              :to="{ hash: '/system-settings/permissions'}"
            />
          </div>
          <div class="col-6 ">
            <q-btn
              color="grey-3"
              text-color="grey-9"
              class="fit rounding"
              icon="fas fa-terminal"
              :label="$t('debug')"
              unelevated
              no-caps
              align="left"
              :to="{ hash: '/system-settings/debug'}"
            />
          </div>
        </div>
      </template>

      <!-- Support -->
      <template v-if="isNotOutsource">
        <div class="titleBar q-px-lg q-pt-md q-pb-xs row">
          <div class="col text-h6">
            {{ support.sectionTitle }}
          </div>
        </div>
        <div class="q-px-lg row q-col-gutter-md">
          <template>
            <div v-for="button in supportButtons" :key="`support-${button.label}`" class="col-6 ">
              <q-btn
                color="grey-3"
                text-color="grey-9"
                class="fit rounding"
                :icon="`fas fa-${button.icon}`"
                :label="button.label"
                unelevated
                no-caps
                align="left"
                :to="button.link"
              />
            </div>
          </template>
        </div>
      </template>
      <template>
        <div class="version-number">
          Version: {{ version }}
        </div>
      </template>
    </q-scroll-area>
    <m-guest-blocker v-else />

    <div class="footer">
      <m-cta-button :label="$t('sign_out')" base-size="14px" @ctaClick="logout" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import profileConfig from './config.js'
import authentication from 'mixins/authentication'
import { get as getMonthBudget } from 'api/dashboard'
import { MGuestBlocker, MImage, MCtaButton } from 'components'

var peekElement = null

const environment = process.env.NODE_ENV

export default {
  components: { MGuestBlocker, MImage, MCtaButton },
  mixins: [authentication],
  data () {
    return {
      ...profileConfig,
      spend: null,
      budget: null
    }
  },
  computed: {
    ...mapGetters(['user', 'organisation', 'partner']),
    version () {
      return process.env.VUE_APP_VERSION_NUMBER
    },
    withinBudget () {
      return this.spend?.amount <= this.budget?.amount
    },
    mainButtons () {
      return this.main.buttons.filter(this.filterButton)
    },
    policyButtons () {
      return this.policyManager.buttons.filter(this.filterButton)
    },
    myTravelButtons () {
      return this.myTravel.buttons.filter(this.filterButton)
    },
    membershipsButtons () {
      return this.memberships.buttons.filter(this.filterButton)
    },
    supportButtons () {
      return this.support.buttons.filter(this.filterButton)
    },
    isNotOutsource () {
      return !this.hasRole('middleoffice.outsource')
    },
    showBudget () {
      return !['gohi', 'derbygo'].includes(this.partner.slug) && this.isNotOutsource && this.hasRole(['member', 'middleoffice'])
    },
    showAdminSection () {
      return this.hasRole('admin') || environment === 'development'
    }
  },
  created () {
    if (!authentication.methods.hasRole(['backoffice', 'finance', 'sdc', 'support', 'sales', 'admin'])) {
      getMonthBudget().then(res => {
        this.spend = res.data.spend
        this.budget = res.data.current_month_budget
      })
    }
  },
  mounted () {
    if (!peekElement) peekElement = document.getElementById('peek')
    peekElement.setAttribute('style', 'width: 435px; background-color: #ffffff')
  },
  beforeDestroy () {
    peekElement.setAttribute('style', 'width: 50vw; background-color: #f4f4f4')
    peekElement = null
  },
  methods: {
    filterButton (button) {
      if (button.permissions) {
        if (button.permissions.is && !this.hasPermission(button.permissions.is)) return false
        if (button.permissions.not && this.hasPermission(button.permissions.not)) return false
      }

      if (button.roles) {
        if (button.roles.is && !this.hasRole(button.roles.is)) return false
        if (button.roles.not && this.hasRole(button.roles.not)) return false
      }

      if (button.partner) {
        if (button.partner.is && !button.partner.is.includes(this.partner.slug)) return false
        if (button.partner.not && button.partner.not.includes(this.partner.slug)) return false
      }

      return true
    },
    externalUrl (url) {
      if (url) window.open(url, '_blank')
    },
    logout () {
      this.$router.push({ hash: '' })
      this.$router.push({ name: 'Logout' })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .scroller
    height calc(100vh - 118px)
    width 100%

  .titleBar
    font-weight 1200
    display flex
    flex-direction column
    align-items center

  .budgetBox
    height 104px
    position relative

  .budgetState
    color white
    border-top-left-radius $generic-border-radius
    border-top-right-radius $generic-border-radius
    font-weight 300
    &.statePositive
      background-color #84cb58
    &.stateNegative
      background-color negative

  .rounding
    border-radius $generic-border-radius

  .q-btn
    font-weight 500
    font-size .75em

  .footer
    width 100%
    background-color white
    position absolute
    bottom 0
    left 0
    right 0
    padding 0 16px

  .editIcon
    position absolute
    z-index 10
    background-color white
    border-radius 100%
    font-size .25em
    line-height .25em
    padding 4px
    bottom 0
    right 0

  .loadingBudget
    .spinner
      margin 0
      position absolute
      left calc(50% - 22px)
      top calc(50% - 22px)

  .grid-title
    display: grid
    grid-template-columns: auto 21px
    justify-items: center

  .btn-offset
    padding-left: 20px

  .version-number
    font-style italic
    margin-left 24px
    margin-top 8px
    color #aaa
    font-size 80%
.avatarSection
  margin-top 4px
</style>
