<template>
  <q-item
    clickable
  >
    <q-item-section>
      <q-item-label>
        {{ label }}
      </q-item-label>
      <q-item-label caption lines="2">
        {{ text }}
      </q-item-label>
    </q-item-section>

    <q-item-section side>
      <q-btn label="..." flat size="lg">
        <q-menu
          anchor="top right"
          self="top left"
        >
          <q-list style="min-width: 100px">
            <q-item v-close-popup clickable>
              <q-item-section @click="$emit('edit', index)">
                {{ $t('edit') }}
              </q-item-section>
            </q-item>
            <q-item v-close-popup clickable>
              <q-item-section @click="$emit('delete', { id:addressId, label })">
                {{ $t('delete') }}
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    addressId: {
      type: Number,
      required: true
    }
  }
}
</script>

<style>

</style>
