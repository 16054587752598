import http from 'api/http'

export function index (params) {
  return http.get('/permissions?limit=1000', { params })
}

export function get (slug, params) {
  return http.get(`/permissions/${slug}`, { params })
}

export function post (permission) {
  return http.post('/permissions', permission)
}

export function put (slug, permission) {
  return http.put(`/permissions/${slug}`, permission)
}

export function attachRoles (slug, roles) {
  return http.put(`/permissions/${slug}/roles`, roles)
}

export function detachRoles (slug, roles) {
  return http.delete(`/permissions/${slug}/roles`, roles)
}

export function attachToUser (username, slug) {
  return http.post(`/users/${username}/permissions/${slug}`)
}

export function removeFromUser (username, slug) {
  return http.delete(`/users/${username}/permissions/${slug}`)
}
