import Vue from 'vue'
import VueRouter from 'vue-router'
import autoImport from 'utils/autoImport'

/**
 * Register all the routes - harder than I expected...
 *
 * 1. Grab default exports (must be better way of getting resolved stuff)
 * 2. If we have a path it's a single route exported
 * 3. Otherwise it must be multiple routes exported
 */
let routes = []
autoImport(
  require.context('./routes', false, /^.*\.js$/),
  (context, fileName) => {
    context = context(fileName).default
    if (context.path) {
      routes.push(context)
    } else {
      routes = [
        ...routes,
        ...Object.values(context)
      ]
    }
  }
)
if (process.env.NODE_ENV !== 'test') {
  Vue.use(VueRouter)
}

/**
 * Catch and discard all the redundant navigation errors on router.push
 *   Deliberate redirects
 *   Navigation to same page
 */
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  // If we're setting resolve/reject in the push function already, just go with that solution
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch(() => {})
  // Uncomment below to catch specific errors
  // .catch((err) => {
  // const { isNavigationFailure, NavigationFailureType } = VueRouter
  // Handles redirections via navigation guard i.e. calling next()
  // if (isNavigationFailure(err, NavigationFailureType.redirected)) {
  //   return err
  // }
  // Handles duplicated navigation i.e. from /tram to /tram
  // if (isNavigationFailure(err, NavigationFailureType.duplicated)) {
  //   console.log('dupe')
  //   return err
  // }
  // If it's a real error, rethrow
  // return Promise.reject(err)
  // })
}

const Router = new VueRouter({
  /*
   * NOTE! Change Vue Router mode from quasar.conf.js -> build -> vueRouterMode
   *
   * When going with "history" mode, please also make sure "build.publicPath"
   * is set to something other than an empty string.
   * Example: '/' instead of ''
   */

  mode: process.env.VUE_APP_HISTORY_MODE || 'history',
  base: process.env.VUE_APP_PUBLIC_PATH || '/',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

/**
 * Force Service Worker reload when we receive a chunkError
 * Forcing Service Worker to skipWaiting will also force a page reload,
 * clearing browser cache
*/
function reloadServiceWorker () {
  if (navigator.serviceWorker) {
    console.log('Reloading service worker')
    navigator.serviceWorker.getRegistration().then(sw => {
      if (sw && sw.active) sw.update()
      if (sw && sw.waiting) {
        sw.waiting.postMessage({ action: 'skipWaiting' })
      } else {
      // Just in case sw isn't waiting??
        window.location.reload()
      }
    })
  } else {
    console.log('Reloading window')
    window.location.reload()
  }
}

Router.onError((error) => {
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    reloadServiceWorker()
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    reloadServiceWorker()
  }
})

/**
 * Global Guards
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-guards
 */
autoImport(
  require.context('./beforeEach', false, /^.*\.js$/),
  (context, fileName) => { Router.beforeEach(context(fileName).default) }
)

/**
 * Global Resolve Guards
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-resolve-guards
 */
autoImport(
  require.context('./beforeResolve', false, /^.*\.js$/),
  (context, fileName) => { Router.beforeResolve(context(fileName).default) }
)

/**
 * Global After Hooks
 * https://router.vuejs.org/guide/advanced/navigation-guards.html#global-after-hooks
 */
autoImport(
  require.context('./afterEach', false, /^.*\.js$/),
  (context, fileName) => { Router.afterEach(context(fileName).default) }
)

export default Router
