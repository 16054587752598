<template>
  <peek-form
    :title="`${amendMode ? 'Edit' : 'Create'} Permission`"
    :loading="submitting"
    :disable-call-to-action="disabled"
    @save="save()"
  >
    <q-page padding>
      <div v-if="!loading" class="column q-col-gutter-md">
        <div class="col">
          <m-group-title label="Permission settings" />
          <q-card>
            <q-card-section>
              <q-input v-model="permission.name" :label="$t('admin.permissions.name')" />
              <q-input v-model="permission.slug" :disable="amendMode" :label="$t('admin.permissions.slug')" :rules="[v => v && !v.includes(' ') || $t('admin.permissions.slug_rules')]" />
              <q-input v-model="permission.description" :label="$t('admin.permissions.description')" type="textarea" maxlength="255" />
            </q-card-section>
          </q-card>
        </div>
        <div class="col">
          <m-group-title :label="$t('admin.permissions.attached_roles')" />
          <q-card>
            <q-card-section>
              <attached-roles v-model="permission.roles" />
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div v-else-if="loading" class="text-center">
        {{ $t('loading.loading') }}
      </div>
    </q-page>
  </peek-form>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import Permission from 'store/models/permission'
import peekForm from 'peeks/peekForm.vue'
import attachedRoles from './attachedRoles.vue'
import { MGroupTitle } from 'components/'

export default {
  name: 'PeekSystemSettingsRolesForm',
  components: { peekForm, attachedRoles, MGroupTitle },
  data () {
    return {
      permission: {
        name: null,
        slug: null,
        description: null,
        roles: []
      },
      loading: false,
      submitting: false
    }
  },
  computed: {
    amendMode () {
      return !!this.$route.query.permission
    },
    disabled () {
      this.$v.$touch()
      return this.$v.$error
    }
  },
  beforeMount () {
    if (this.amendMode) {
      this.loading = true
      var permission = Permission.find(this.$route.query.permission)
      if (!permission) {
        this.$store.dispatch('entities/permissions/query', {
          slug: this.$route.query.permission
        }).then((res) => {
          permission = Permission.find(res.slug)
          this.permission = {
            ...permission,
            roles: permission.roles.map(role => role.slug)
          }
          this.loading = false
        })
      } else {
        this.permission = {
          ...permission,
          roles: permission.roles.map(role => role.slug)
        }
        this.loading = false
      }
    }
  },
  methods: {
    save () {
      this.$v.$touch()
      if (this.$v.$error) {
        this.$q.notify(this.$t('error.default'))
        return
      }
      this.submitting = true
      var method = this.amendMode ? 'update' : 'create'
      var payload = this.amendMode ? { slug: this.$route.query.permission, permission: this.permission } : this.permission
      this.$store.dispatch(`entities/permissions/${method}`, payload)
        .then(() => {
          this.$router.go(-1)
        })
        .finally(() => {
          this.submitting = false
        })
    }
  },
  validations: {
    permission: {
      name: { required },
      slug: { required },
      description: { required }
    }
  }
}
</script>
