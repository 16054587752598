export const tfwmHolding = {
  name: 'tfwm-holding',
  path: '/holding',
  components: {
    default: () => import(/* webpackChunkName: "tfwm-holding" */ `pages/tfwm-holding/index.vue`)
  },
  meta: {
    layout: 'blank'
  }
}

export default { tfwmHolding }
