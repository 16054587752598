import store from 'store/modules/ondemand'

const beforeEnter = (to, from, next) => {
  !store.state.stash.parameters ? next({ name: 'ondemand-hotel' }) : next()
}

export const form = {
  name: 'ondemand-hotel',
  path: '/on-demand/hotel',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/index.vue')
  },
  meta: {
    label: 'Hotel: Search'
  }
}

export const availability = {
  name: 'ondemand-hotel-availability',
  path: '/on-demand/hotel/availability',
  meta: {
    label: 'Hotel: Availability'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/availability/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/sidebar/index.vue')
  },
  beforeEnter
}

export const details = {
  name: 'ondemand-hotel-details',
  path: '/on-demand/hotel/:token',
  meta: {
    label: 'Hotel: Details'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/details/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/sidebar/index.vue')
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-hotel-summary',
  path: '/on-demand/hotel/summary/:token',
  meta: {
    label: 'Hotel: Summary'
  },
  components: {
    default: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/summary/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-hotel" */ 'pages/ondemand/hotel/sidebar/index.vue')
  },
  beforeEnter
}

export default { form, availability, details, summary }
