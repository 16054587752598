import store from 'store'

/**
 * Bikehire integration is a bit more complex than other integrations, so
 * here is a mixin to make life a helluva lot easier.
 */

const statusesIfUnregistered = ['Not registered', 'Missing phone number']

// API doesn't return an explicit "not registered" field, so we have to check for certain messages in the status field.
const logo = require('assets/bike-providers/brompton_logo.svg')

export default {
  data () {
    return {
      brompton_logo: logo,
      bikehireLoading: true,
      bikehireStatus: {
        registered: false,
        status: '',
        phone_number: ''
      }
    }
  },
  methods: {
    async setBikehireUser (username) {
      this.bikehireLoading = true
      const bikehireStatus = {}
      const memberDetails = await store.dispatch('bikehire/setBikehireStatus', username)
      this.bikehireLoading = false

      bikehireStatus.registered = !statusesIfUnregistered.includes(memberDetails.status)
      return {
        ...bikehireStatus,
        ...memberDetails
      }
    },
    async getBikehireStatus (username) {
      username = username || store.state.authentication.user.username
      const bikehireStatus = await this.setBikehireUser(username)
      return bikehireStatus
    }
  },
  async beforeMount () {
    const memberStatus = await this.getBikehireStatus()
    this.bikehireStatus = memberStatus
  }
}
