<template>
  <div class="spinner" :style="{'--spinner-size': spinnerSize}">
    <div class="spinner-part top">
      <div class="spinner-rotator" />
    </div>
    <div class="spinner-part bottom">
      <div class="spinner-rotator" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MSpinnerKinto',
  props: {
    size: {
      type: [String, Number],
      default: '128px'
    }
  },
  computed: {
    spinnerSize () {
      if (typeof this.size === 'number') return this.size + 'px'
      return this.size
    }
  }
}
</script>

<style lang="stylus" scoped>
.spinner
  width var(--spinner-size)
  height var(--spinner-size)

.spinner-part
  overflow: hidden;
  height: calc(var(--spinner-size)/2)
  width: var(--spinner-size)

.spinner-part.bottom
  transform: rotate(180deg) scale(-1, 1);

.spinner-rotator
  width: var(--spinner-size)
  height: var(--spinner-size)
  border: calc(var(--spinner-size)/2) solid transparent;
  border-right-color: #00708d
  border-top-color: #00708d
  border-radius: 50%;
  box-sizing: border-box;
  animation: spinner-animation 3000ms ease-in-out infinite;
  transform: rotate(-200deg);

@keyframes spinner-animation {
    0% {
      border-width: calc(var(--spinner-size)/2)
    }
    25% {
      border-width: calc(var(--spinner-size)/23.33)
    }
    50% {
      transform: rotate(115deg);
      border-width: calc(var(--spinner-size)/2)
    }
    75% {
      border-width: calc(var(--spinner-size)/23.33)
    }
    100% {
      border-width: calc(var(--spinner-size)/2)
    }
  }
</style>
