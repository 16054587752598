const home = {
  name: 'home',
  path: '/home',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomsheet: true,
      components: {
        appbar: 'MMobileAppbar',
        footer: 'MMobileFooter'
      }
    },
    label: 'Home'
  }
}

const cards = {
  name: 'home-cards',
  path: '/home/nearby/:type?/:mapConfig?',
  props: true,
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/search/index-cards.vue'),
  meta: {
    layout: {
      name: 'mobile',
      bottomcards: true,
      components: {
        appbar: false,
        footer: 'MMobilityNearbyCards'
      },
      label: ''
    },
    back: 'home'
  }
}

const place = {
  name: 'home-place',
  path: '/home/place/:reference',
  component: () => import(/* webpackChunkName: "mobile" */ 'pages/home/place/index.vue'),
  meta: {
    layout: {
      name: 'mobile',
      components: {
        appbar: false,
        footer: false
      }
    }
  }
}

export default { home, place, cards }
