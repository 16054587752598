import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') ? next() : next('/dashboard')
}

export const index = {
  name: 'organisations',
  path: '/organisations',
  components: {
    default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/index.vue')
  },
  beforeEnter
}

export const show = {
  name: 'organisations-view',
  path: '/organisations/:organisation',
  redirect: '/organisations/:organisation/profile',
  components: {
    default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/index.vue')
  },
  beforeEnter,
  children: [
    {
      path: 'profile',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/profile.vue')
      }
    },
    {
      path: 'contents',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/contents.vue')
      }
    },
    {
      path: 'members',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/members.vue')
      }
    },
    {
      path: 'bookings',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/bookings.vue')
      }
    },
    {
      path: 'billing',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/billing.vue')
      }
    },
    {
      path: 'notes',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/notes.vue')
      }
    },
    {
      path: 'pricing',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/pricing.vue')
      }
    },
    {
      path: 'history',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/history.vue')
      }
    },
    {
      path: 'onward-travel',
      components: {
        default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/view/onward-travel.vue')
      }
    }
  ]
}

export const bookingAuth = {
  name: 'organisations-booking-auth',
  path: '/organisations/:organisation/booking-auth',
  components: {
    default: () => import(/* webpackChunkName: "organisations" */ 'pages/organisations/booking-auth/index.vue')
  },
  beforeEnter (to, from, next) {
    const isStaff = authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin')
    const isMiddleOfficeOfThisOrganisation = authentication.methods.hasRole('middleoffice') && authentication.methods.hasOrganisation(to.params.organisation)

    if (isStaff || isMiddleOfficeOfThisOrganisation) {
      next()
    } else next(from)
  }
}

export default { index, show, bookingAuth }
