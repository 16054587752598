const index = {
  name: 'public-transport-form',
  path: '/public-transport',
  components: {
    default: () => import(/* webpackChunkName: "public-transport" */ 'pages/public-transport/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-bus" */ 'pages/ondemand/bus/sidebar/index.vue')
  },
  meta: {
    label: 'Ticket Search'
  }
}

export default { index }
