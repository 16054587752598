<template>
  <peek-form :footer="false" :title="$tc('transport_budget.default')" :subtitle="$t('how_you_prefer_to_travel')">
    <q-page padding>
      <policy-card :org="organisation" />
    </q-page>
  </peek-form>
</template>

<script>
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import policyCard from './policy-card'

export default {
  components: { peekForm, policyCard },
  data () {
    return {
      organisation: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user'
    })
  },
  beforeMount () {
    this.loading = true
    if (this.$route.query && this.$route.query.slug) this.organisation = this.lookupOrg(this.$route.query.slug)
    else this.organisation = this.orgGetter
    if (!this.organisation) this.$router.push({ path: this.$route.path })
  }
}
</script>

<style scoped lang="stylus">
  .budget
    margin-bottom: 24px
</style>
