import i18n from 'i18n'

const titleList = i18n.t('title_list').map(title => { return { label: title, value: title } })

export default {
  titles: titleList,
  carAcquisitionTypes: [
    { label: i18n.t('own_vehicle'), value: 'own' },
    { label: i18n.t('lease_vehicle'), value: 'lease' },
    { label: i18n.t('pool_car'), value: 'pool' },
    { label: i18n.t('company_owned_vehicle'), value: 'company' }
  ],
  fuelPaymentMethods: [
    { label: i18n.t('cash'), value: 'cash' },
    { label: i18n.t('payment_card.credit'), value: 'credit_card' },
    { label: i18n.t('payment_card.company'), value: 'company_card' },
    { label: i18n.t('fuel_card'), value: 'fuel_card' }
  ],
  fuelReimbursementRates: [
    { label: i18n.t('num.pence', { num: 11 }), value: '11' },
    { label: i18n.t('num.pence', { num: 14 }), value: '14' },
    { label: i18n.t('num.pence', { num: 21 }), value: '21' }
  ],
  carclubProviders: [
    { label: i18n.t('no_membership'), value: '' },
    { label: i18n.t('interested_in_knowing_more'), value: 'interested' },
    { label: i18n.t('city_car_club'), value: 'city_car_club' },
    { label: i18n.t('other_car_club'), value: 'other_car_club' }
  ],
  restrictTransportTypesList: [
    { label: i18n.tc('content_type.car'), value: 'car' },
    { label: i18n.tc('content_type.train'), value: 'train' },
    { label: i18n.tc('content_type.bus'), value: 'bus' },
    { label: i18n.tc('content_type.flight'), value: 'flight' },
    { label: i18n.t('sea'), value: 'sea' },
    { label: 'Hire car', value: 'hire_car' },
    { label: i18n.t('content_type.carclub'), value: 'car_club' },
    { label: i18n.t('bikes'), value: 'bikes' },
    { label: i18n.tc('content_type.tube'), value: 'tube' }
  ],
  loungeProviders: [
    { label: i18n.t('lounge.no_membership'), value: 'none' },
    { label: i18n.t('priority_pass'), value: 'priority_pass' },
    { label: i18n.t('lounge.pass'), value: 'lounge_pass' },
    { label: i18n.t('lounge.club'), value: 'lounge_club' },
    { label: i18n.t('lounge.buddy'), value: 'lounge_buddy' },
    { label: i18n.t('british_airways'), value: 'british_airways' },
    { label: i18n.t('virgin_atlantic'), value: 'virgin_atlantic' }
  ],
  flightSeatPrefs: [
    { label: i18n.t('flight.seat.window'), value: 'window' },
    { label: i18n.t('flight.seat.aisle'), value: 'aisle' },
    { label: i18n.t('flight.seat.wing'), value: 'wing' }
  ],
  blockStatuses: [
    { label: i18n.t('all'), value: null },
    { label: i18n.t('active'), value: false },
    { label: i18n.t('blocked'), value: true }
  ]
}
