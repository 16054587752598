<template>
  <q-item class="relative">
    <q-item-section>
      <q-item-label label class="header">
        <div>{{ info.title }}</div>
        <q-btn :to="{hash: `/organisation/transport-budget/${info.name}`, query: { slug: org.slug }}" flat color="primary">
          {{ $t('edit') }}
        </q-btn>
      </q-item-label>
      <q-item-label caption>
        <component :is="overview" :policy="policy" />
      </q-item-label>
    </q-item-section>
  </q-item>
</template>

<script>
import onwardtravelOverview from '../onwardtravel/overview.vue'
export default {
  props: ['info', 'policy', 'org'],
  computed: {
    overview () {
      if (this.info.name === 'onwardtravel') {
        return onwardtravelOverview
      }
      return false
    }
  }
}
</script>

<style lang="stylus" scoped>
.header
  display flex
  justify-content space-between
</style>
