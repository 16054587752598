import Cookies from 'js-cookie'
import login from 'api/login'
import jwtDecode from 'jwt-decode'
import { get, put } from 'api/user'
import { setCredentials, deleteCredentials } from 'api/carclub'
import { setCredentials as setFerryCredentials, removeCredentials as removeFerryCredentials } from 'api/ferry'
import getPartnerFromHostname, { getPartnerFromLabel } from 'utils/partner'
import User from 'store/models/user'
import store from 'store'
import i18n from 'i18n'

// initial state
const state = {
  partner: getPartnerFromHostname(),
  organisation: null,
  user: null,
  roles: null,
  permissions: null,
  token: Cookies.get('token'),
  expires: null,
  loading: false
}

// getters
const getters = {
  authentication: state => state,
  hasPermission: (state, getters) => (permissions = null) => {
    if (typeof permissions === 'string') permissions = [permissions]
    return permissions.some(permission => {
      return state.roles && state.permissions.find(value => value === permission)
    })
  },
  user: state => {
    return {
      ...state.user,
      is: (state, getters) => (role) => {
        return !!state.roles.find(value => value === role)
      },
      can: (state, getters) => (permission) => {
        return !!state.permissions.find(value => value === permission)
      }
    }
  },
  userLookup: state => {
    if (!state.user) return null

    const authorisers = []
    if (state.user.meta.line_manager_1) authorisers.push({ email: state.user.meta.line_manager_1 })
    if (state.user.meta.line_manager_2) authorisers.push({ email: state.user.meta.line_manager_2 })

    return {
      label: state.user.display_name,
      value: state.user.username,
      sublabel: `${i18n.tc('organisation.organisation')}: ${state.organisation ? state.organisation.name : i18n.t('individual')}`,
      organisation: state.organisation ? state.organisation.slug : 'Individual',
      has_account: state.organisation ? state.organisation.has_account : false,
      permissions: state.permissions,
      avatar: state.user.picture,
      has_carclub: state.user.has_carclub,
      has_northlink: state.user.meta?.ferry?.northlink,
      requires_booking_approval: authorisers.length > 0,
      authorisers,
      travel_preferences: state.user.travel_preferences
    }
  },
  roles: state => {
    return state.roles
  },
  permissions: state => {
    return state.permissions
  },
  organisation: state => {
    return state.organisation
  },
  preferences: state => {
    return state.user.travel_preferences
  },
  partner: state => {
    return state.partner
  },
  session: state => {
    return {
      'expires': state.expires,
      'token': state.token
    }
  },
  geoip: state => {
    return state.user ? state.user.geoip || {} : {}
  },
  loading: state => state.loading
}

// actions
const actions = {
  login ({ dispatch }, { username, password }) {
    return login(username, password).then(response => {
      dispatch('saveAuthUser', response.data)
      return response
    })
  },

  guestMode ({ dispatch }) {
    return login(process.env.VUE_APP_GUEST_USERNAME, process.env.VUE_APP_GUEST_PASSWORD).then(response => {
      const roles = ['guest'].concat(response.data.roles)
      dispatch('saveAuthUser', { ...response.data, roles })
      return response
    })
  },

  saveAuthUser ({ commit, rootGetters }, user) {
    commit('AUTH_USER', user)
    if (user.organisation) {
      commit('AUTH_ORGANISATION', user.organisation.data)
      let brand = user.organisation.data.brand
      if (brand?.colors?.brand) {
        store.dispatch('setBrand', brand.colors.brand)
      }
    }
    commit('AUTH_ROLES', user.roles)
    commit('AUTH_PERMISSIONS', user.permissions)
    // const partner = getPartnerFromHostname()
    const partnerWhiteLabel = rootGetters['partner/whiteLabel']
    const partner = partnerWhiteLabel ? getPartnerFromLabel(partnerWhiteLabel) : getPartnerFromHostname()
    commit('updatePartner', partner)
  },

  saveAuthToken ({ commit }, token) {
    let decoded = jwtDecode(token)
    commit('AUTH_TOKEN', token)
    commit('AUTH_EXPIRES', decoded.exp * 1000)
    Cookies.set('token', token, {
      expires: 14
    })
  },

  'get_user' ({ commit, state }, params) {
    const user = getters.user(state)
    return get(user).then(response => {
      commit('AUTH_USER', response.data)
      return response
    })
  },

  'update_user' ({ commit, state }, params) {
    const user = getters.user(state)
    return put(user, params).then(response => {
      commit('AUTH_USER', response.data)
      return response
    })
  },

  setCarClubCredentials ({ commit }, params) {
    return setCredentials(params)
      .then(res => {
        commit('allowCarClub')
      })
  },

  deleteCarClubCredentials ({ commit }) {
    return deleteCredentials()
      .then(res => {
        commit('disallowCarClub')
      })
  },

  setNorthlinkCredentials ({ commit }, params) {
    return setFerryCredentials(params)
      .then(() => {
        commit('allowFerry')
      })
  },

  removeNorthlinkCredentials ({ commit }) {
    return removeFerryCredentials()
      .then(() => {
        commit('disallowFerry')
      })
  },

  updateOrg ({ commit }, org) {
    commit('updateOrg', org)
  },

  updateTravelPreferences ({ commit }, preferences) {
    commit('updateTravelPreferences', preferences)
  },

  updateSingleTravelPreference ({ commit }, { key, value }) {
    commit('updateSingleTravelPreference', { key, value })
  },

  logout ({ commit, dispatch }) {
    commit('AUTH_LOGOUT')
    dispatch('backoffice/reset')
    dispatch('individuals/reset')
    dispatch('members/reset')
    dispatch('entities/invoices/deleteAll')
    dispatch('entities/users/deleteAll')
    dispatch('organisations/reset')
    dispatch('requests/reset')
    dispatch('rideHailing/reset')
    dispatch('addresses/reset')
    dispatch('native/reset')
    window.localStorage.clear()
    Cookies.remove('token')
  }
}

// mutations
const mutations = {
  AUTH_USER (state, user) {
    state.user = {
      username: user.username,
      display_name: user.display_name,
      has_carclub: user.has_carclub,
      email: user.email,
      picture: user.picture,
      monthly_budget: user.monthly_budget,
      meta: user.meta,
      links: user.links,
      geoip: user.geo_ip,
      travel_preferences: user.travel_preferences
    }
    // Fix for auth endpoint returning `organisations`
    user.organisation = user.organisation || user.organisations
    User.insert({ data: user })
  },

  AUTH_ORGANISATION (state, organisation) {
    state.organisation = organisation
  },

  AUTH_ROLES (state, roles) {
    state.roles = roles
  },

  AUTH_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },

  AUTH_TOKEN (state, token) {
    state.token = token
  },

  AUTH_EXPIRES (state, expires) {
    state.expires = expires
  },

  AUTH_TOKEN_EXPIRED (state) {
    state.expires = null
    state.token = null
  },

  allowCarClub (state) {
    state.user.has_carclub = true
  },

  disallowCarClub (state) {
    state.user.has_carclub = false
  },

  allowFerry (state) {
    state.user.meta.ferry.northlink = true
  },

  disallowFerry (state) {
    state.user.meta.ferry.northlink = false
  },

  AUTH_LOGOUT (state) {
    state.organisation = null
    state.user = null
    state.roles = null
    state.permissions = null
    state.token = null
    state.expires = null
    state.geolocation = null
    state.userLookup = null
  },
  updateOrg (state, org) {
    state.organisation = org
  },
  updatePartner (state, partner) {
    state.partner = partner
  },
  toggleLoading (state, bool) {
    state.loading = bool
  },
  updateTravelPreferences (state, preferences) {
    state.user.travel_preferences = preferences
  },
  updateSingleTravelPreference (state, { key, value }) {
    state.user.travel_preferences[key] = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
