import { hasRole } from 'utils/authentication'
import store from 'store/modules/onwardtravel'

const meta = {
  permission: hasRole('backoffice') ? null : 'search.onwardtravel'
}

const beforeEnter = (to, from, next) => {
  next()
  !store.state.time ? next({ name: 'ondemand-onwardtravel' }) : next()
}

const search = {
  name: 'ondemand-onwardtravel',
  path: '/on-demand/onwardtravel',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/index.vue')
  },
  meta
}

const results = {
  name: 'ondemand-onwardtravel-results',
  path: '/on-demand/onwardtravel/results',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/results.vue'),
    right: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/sidebar.vue')
  },
  meta,
  beforeEnter
}

const details = {
  name: 'ondemand-onwardtravel-details',
  path: '/on-demand/onwardtravel/details',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/details.vue'),
    right: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/sidebar.vue')
  },
  meta,
  beforeEnter
}

const summary = {
  name: 'ondemand-onwardtravel-summary',
  path: '/on-demand/onwardtravel/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/summary.vue'),
    right: () => import(/* webpackChunkName: "ondemand-rac-onwardtravel" */ 'pages/ondemand/onwardtravel/sidebar.vue')
  },
  meta,
  beforeEnter
}

export default { search, results, details, summary }
