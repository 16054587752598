import { cloneDeep } from 'lodash'
import { nearby as getNearby, sociabilityByLatLng, sociabilityByPlaceIds } from 'api/locations'
import { departures as trainTimetable } from 'api/train'
import { timetable as busTimetable } from 'api/bus'
import { Notify } from 'quasar'
import i18n from 'i18n'
import { hasPermission } from 'utils/authentication'
import getPartnerFromHostname from 'utils/partner'

const initalState = {
  items: null,
  location: null,
  searchType: null,
  loading: false,
  departures: {},
  station: {},
  selected: null
}

const state = cloneDeep(initalState)

function getLocation (getters, fresh = false) {
  if (fresh || !state.location) {
    return getters['map/getCenterAsUrlValue']
  } else return state.location
}

const getters = {
  items: state => state.items,
  location: state => state.location,
  searchType: state => state.searchType,
  loading: state => state.loading,
  departures: state => state.departures,
  station: state => state.station,
  selected: state => state.selected
}

const actions = {
  searchNearby ({ commit, rootGetters, dispatch }, { location, searchType, fresh, distance }) {
    const forcedDistance = ['bus', 'tram'].includes(searchType) ? 1000 : distance || 15000
    const partner = getPartnerFromHostname().slug
    commit('setLoading', true)
    actions.resetItems({ commit })
    commit('saveSelected', null)
    const typeToSearch = searchType || state.searchType
    const distanceToSearch = forcedDistance
    actions.resetSearchType({ commit })
    const locationToSearch = location || getLocation(rootGetters, fresh)
    if (searchType === 'mango') {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [])
      commit('saveSearchType', 'mango')
      commit('setLoading', false)
    } else if (searchType === 'ferry' && !hasPermission('feature.mfd1.263.ferry.flow')) {
      commit('saveLocation', locationToSearch)
      commit('saveItems', [])
      commit('saveSearchType', 'ferry-soon')
      commit('setLoading', false)
    } else {
      getNearby({ location: locationToSearch, type: typeToSearch, distance: distanceToSearch }, partner)
        .then(async response => {
          let items = response.data
          const place_ids = items.filter(i => i.attributes.place_id).map(i => i.attributes.place_id)
          if (['derbygo'].includes(partner) && place_ids.length) {
            items = items.map(i => ({ ...i, sociability: null }))
            await sociabilityByPlaceIds(place_ids).then(response => {
              const sociabilityLocations = response.data
              sociabilityLocations.forEach(location => {
                const place_id = location.google_place_id
                const index = items.findIndex(i => i.attributes.place_id === place_id)
                items[index].sociability = location
              })
            })
          }
          commit('saveItems', items)
          commit('saveLocation', locationToSearch)
          commit('saveSearchType', typeToSearch)
          if (items.length > 0) {
            const { latitude, longitude } = items[0]
            commit('saveSelected', { id: 'card-0', lat: latitude, lng: longitude })
          }
        })
        .finally(() => {
          commit('setLoading', false)
        })
    }
  },
  searchSociability ({ commit, rootGetters, dispatch }, { lat, lng, radius }) {
    commit('setLoading', true)
    actions.resetItems({ commit })
    commit('saveSelected', null)
    actions.resetSearchType({ commit })
    sociabilityByLatLng(lat, lng, radius || 1)
      .then(response => {
        const items = response.data.map(item => {
          const sociabilityUrlSplit = item.sociability_url.split('/')
          let venueType = sociabilityUrlSplit[sociabilityUrlSplit.length - 2]

          venueType = venueType[0].toUpperCase() + venueType.substr(1)

          return {
            ...item,
            type: venueType,
            name: item.business_name,
            attributes: {
              place_id: item.google_place_id
            },
            top_tags: item.top_tags.filter(tags => tags.length <= 16) // Hacky, but best answer I can come up with to fix wrapping...
          }
        })

        commit('saveItems', items)
        commit('saveLocation', `${lat},${lng}`)
        commit('saveSearchType', 'sociability')

        const { latitude, longitude } = response.data[0]
        commit('saveSelected', { id: 'card-0', lat: latitude, lng: longitude })
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  setTimetable ({ commit, state }, item) {
    const id = item.attributes.atcocode || item.attributes.atoc
    if (state.station[id] && item.limit <= state.departures[id].length) {
      console.log(`${id} already up to date`)
      return
    } else {
      console.log(`Retrieving timetable for ${id}`)
    }
    commit('setLoading', true)
    // Create a Promise base on item type
    const busOrTrain = (item) => {
      if (item.type === 'bus') {
        return busTimetable(item.attributes.atcocode).then(res => {
          // Shape bus data
          const departures = []
          res.data.departures.forEach(bus => {
            const { direction, operator, line, aimed_departure_time, best_departure_estimate } = bus

            const delay = aimed_departure_time !== best_departure_estimate

            departures.push({
              destination: direction,
              operator: operator.name,
              info: line.number,
              id: `${direction}_${aimed_departure_time}`,
              time: aimed_departure_time,
              delay,
              delay_info: best_departure_estimate
            })
          })
          return { data: departures }
        })
      } else if (item.type === 'rail') {
        return trainTimetable(item.description, { limit: item.limit || 3 }).then(res => {
          // Shape train data
          const departures = []
          if (!res.data || !res.data.length) {
            Notify.create({
              message: i18n.t('error.unable_to.retrieve_timetable'),
              color: 'negative',
              icon: 'clear'
            })
            return
          }
          res.data.forEach(train => {
            const { destination, operator, platform, service_id, arrives, departs, delay_reason, cancel_reason } = train

            const delay = delay_reason !== null
            const cancelled = cancel_reason !== null

            const timeString = arrives?.scheduled_at.date || departs.scheduled_at.date

            const time = timeString.slice(11, 16)

            departures.push({
              destination: destination.name,
              operator: operator.name,
              info: platform ? i18n.t('platform_num', { num: platform }) : i18n.t('ends_here'),
              id: service_id,
              time,
              delay,
              cancelled,
              delay_info: cancel_reason || delay_reason
            })
          })

          return { data: departures }
        })
      } else {
        return Promise.reject(Error('Type is neither bus or train'))
      }
    }

    return busOrTrain(item)
      .then((response) => {
        commit('saveDepartures', { id: id, departures: response.data })
        commit('saveStation', { id: id, upToDate: true })
        setTimeout(() => {
          console.log(`${id} no longer up to date`)
          commit('saveStation', { id: id, upToDate: false })
        }, 60000)
      })
      .catch(err => {
        console.log(err)
        commit('saveDepartures', { id: id, departures: [] })
        commit('saveStation', { id: id, upToDate: true })
        setTimeout(() => {
          console.log(`${id} no longer up to date`)
          commit('saveStation', { id: id, upToDate: false })
        }, 60000)
        return { msg: err.message }
      })
      .finally(() => {
        commit('setLoading', false)
      })
  },
  setSelected ({ commit, dispatch }, item) {
    commit('saveSelected', item)
  },
  resetItems ({ commit }) {
    commit('saveItems', null)
  },
  resetLocation ({ commit }) {
    commit('saveLocation', null)
  },
  resetSearchType ({ commit }) {
    commit('saveSearchType', null)
  },
  resetDepartures ({ commit }) {
    commit('resetDepartures')
  },
  resetStation ({ commit }) {
    commit('saveStation', null)
  },
  resetAll ({ commit }) {
    actions.resetItems({ commit })
    actions.resetLocation({ commit })
    actions.resetSearchType({ commit })
    actions.resetDepartures({ commit })
    actions.resetStation({ commit })
  }
}

const mutations = {
  saveItems (state, items) {
    state.items = items
  },
  saveLocation (state, location) {
    state.location = location
  },
  saveSearchType (state, searchType) {
    state.searchType = searchType
  },
  saveDepartures (state, { id, departures }) {
    state.departures = {
      ...state.departures,
      [id]: departures
    }
    state.departures[id] = departures
  },
  resetDepartures (state) {
    state.departures = {}
  },
  saveStation (state, { id, upToDate }) {
    state.station = {
      ...state.station,
      [id]: upToDate
    }
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  saveSelected (state, item) {
    state.selected = item
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
