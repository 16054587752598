import http from './http'

export async function addPurchaseOrder (number, po) {
  const response = await http.post(`/invoices/${number}/purchase-order`, { po_number: po })
  return response.data
}

export function getPdfLink (number) {
  return http.get(`/invoices/${number}/pdf`)
}

export function invoices (params) {
  return http.get('/invoices', {
    params: {
      ...params
    }
  })
}

export function createCreditInvoice (params) {
  return http.post('/invoices/credit', params)
}
