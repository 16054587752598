const initial = {
  markers: [
  ],
  center: {
    lat: 53.836457,
    lng: -1.782097
  },
  searchLocation: {
    latitude: null,
    longitude: null
  }
}

// Copy inital state over to the state var
const state = {
  ...initial
}

const getters = {
  getCenterAsJson: state => state.center,
  getCenterAsUrlValue: state => `${state.center.lat},${state.center.lng}`,
  getMarkers: state => state.markers,
  searchLocation: state => state.searchLocation
}

const actions = {
  setCenter ({ commit }, { lat, lng }) {
    commit('center', { lat, lng })
  },
  setMarkers ({ commit }, markers) {
    commit('markers', markers)
  },
  setSearchLocation ({ commit }, location) {
    commit('searchLocation', location)
  },
  addMarkers ({ commit }, markers) {
    commit('updateMarkers', markers)
  },
  resetMarkers ({ commit }) {
    commit('resetMarkers')
  }
}

const mutations = {
  center (state, { lat, lng }) {
    state.center = { lat, lng }
  },
  markers (state, markers) {
    state.markers = markers
  },
  searchLocation (state, location) {
    state.searchLocation = { ...location }
  },
  updateMarkers (state, markers) {
    state.markers.push(markers)
  },
  resetMarkers (state) {
    state.markers = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
