<template>
  <div v-cloak id="q-app">
    <transition enter-active-class="animated fadeIn">
      <keep-alive>
        <component :is="layout" v-if="layout" :key="layout" ref="layout" />
      </keep-alive>
    </transition>
    <peeks v-if="$route.hash && !($route.hash.startsWith('#code'))" />
  </div>
</template>

<script type="text/javascript">
import tokenExpiration from 'mixins/token-expiration'
import authentication from 'mixins/authentication'
import getPartnerFromHostname from 'utils/partner'
import peeks from 'peeks/index.vue'
import { mapGetters } from 'vuex'

const partner = getPartnerFromHostname()

export default {
  name: 'App',
  components: {
    basic: () => import(`layouts/basic`),
    blank: () => import(`layouts/blank`),
    login: () => import(`layouts/login/${partner.slug}`),
    mobile: () => import(`layouts/mobile`),
    peeks
  },
  mixins: [
    tokenExpiration,
    authentication
  ],
  computed: {
    ...mapGetters(['ui']),
    layout () {
      /**
       * If the route has a layout use it
       */
      if (this.$route.meta.layout?.name) return this.$route.meta.layout.name
      /**
       * Support old string layouts too
       */
      if (this.$route.meta.layout && typeof this.$route.meta.layout === 'string') return this.$route.meta.layout
      /**
       * Backoffice don't get the mobile template
       */
      if (this.hasPermission('can.book.for.anyone')) return 'basic'
      /**
       * Are we mobile or desktop?
       */
      if (this.$route.path !== '/' && this.$store.state.authentication.user) {
        return this.$q.platform.is.mobile ? 'mobile' : 'basic'
      }

      /**
       * Layout MUST be defined
       */
      return null
    }
  }
}
</script>

<style lang="stylus">
  [v-cloak]
    display none
  body.platform-ios
    &.no-scroll
      position fixed
    @media screen and (orientation:landscape)
      .q-page.layout-padding
        padding-left min(env(safe-area-inset-left), 12px)
        padding-right min(env(safe-area-inset-right), 12px)
  // TODO: once defined this should be called via in-component styling referencing partner config
  @font-face {
    font-family: "Trueno"
    src: local("Trueno"), url('./fonts/Trueno/TruenoLight-E2pg.otf') format('truetype')
  }
</style>
