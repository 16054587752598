<template>
  <peek-form
    v-if="organisation"
    :title="$t('organisation.branding', { org: organisation.name })"
    :footer-title="$t('concierge.some_info_will_be_visible')"
    @save="submit"
  >
    <q-page v-if="modify" padding>
      <m-group-title :label="$t('branding')" />
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section v-if="modify.colors">
          <m-field-colour-picker v-model="modify.colors.brand" :label="$t('organisation.branding_colour')" />
        </q-card-section>
        <q-card-section v-if="picture" class="column justify-center">
          <m-group-title :sublabel="$t('organisation.branding_logo')" />
          <file-upload
            ref="upload"
            v-model="files"
            @input-filter="inputFilter"
          >
            <m-image
              key="picture"
              :src="picture"
              class="shadow-1 q-pa-sm"
              :style="{ background: modify.colors.brand }"
            />
            <div class="text-caption">
              {{ $t('pic_formats') }}
            </div>
          </file-upload>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import fileUpload from 'vue-upload-component'
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import { colors } from 'quasar'
import { updateBrandingLogo } from 'api/organisations'
import { MGroupTitle, MImage, MFieldColourPicker } from 'components/'

export default {
  components: { MFieldColourPicker, peekForm, MGroupTitle, MImage, fileUpload },
  data () {
    return {
      files: [],
      modify: null,
      organisation: null
    }
  },
  computed: {
    ...mapGetters({
      orgGetter: 'organisation',
      lookupOrg: 'organisations/getOrg',
      authedUser: 'user',
      brandingLogo: 'partner/brandingLogo'
    }),
    picture () {
      return this.files.length > 0 ? this.files[0].blob : null || (this.modify.logo || require(`assets/${this.brandingLogo}`))
    }
  },
  beforeMount () {
    if (this.$route.query && this.$route.query.slug) {
      this.organisation = this.lookupOrg(this.$route.query.slug)
    } else {
      this.organisation = this.orgGetter
    }

    if (!this.organisation) {
      this.$router.push({ path: this.$route.path })
    } else {
      this.setBrandFromOrg()
    }
  },
  methods: {
    setBrandFromOrg () {
      this.modify = null
      var brand = this.organisation.brand || { logo: null, colors: { brand: colors.getBrand('brand') || '#000000' } }
      if (brand.colors.brand == null) brand.colors.brand = colors.getBrand('brand') || '#000000'
      this.modify = brand
    },
    submit () {
      let data = new FormData()
      data.append('logo', this.files.length > 0 ? this.files[0].file : null)
      if (this.files.length > 0) {
        updateBrandingLogo(this.organisation.slug, data).then(response => {
          this.updateColor()
        })
      } else {
        this.updateColor()
      }
    },
    inputFilter (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) return prevent()
      }
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) newFile.blob = URL.createObjectURL(newFile.file)
    },
    updateColor () {
      return new Promise((resolve, reject) => {
        this.$store.dispatch('organisations/updateOrgBranding', {
          org: {
            slug: this.organisation.slug,
            brand: {
              colors: this.modify.colors
            }
          }
        })
          .then(() => {
            this.$store.dispatch('organisations/loadOrg', {
              orgId: this.organisation.slug
            })
            const authStoreOrg = this.$store.getters.organisation
            if (this.organisation.slug === authStoreOrg.slug) {
              this.$store.dispatch('updateOrg', {
                ...authStoreOrg,
                brand: {
                  ...authStoreOrg.brand,
                  logo: authStoreOrg.brand.logo + `?${new Date().getTime()}`
                }
              })
            }
            resolve()
          })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-card, .m-group-title
    margin 0 0 16px !important
  .q-toolbar
    border-top 1px solid #e0e0e0
  .avatar
    width 400px
    height 400px

  .btn-upload
    line-height 2
</style>
