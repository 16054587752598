<template>
  <peek-form
    :title="amendMode ? $t('label.edit') : $t('label.create')"
    :call-to-action="attaching ? 'Attach' : 'Save'"
    :loading="submitting"
    :disable-call-to-action="!isLabelValid(label ? label.name : null)"
    @save="amendMode ? update() : submit()"
  >
    <div v-if="label" class="q-pa-md">
      <div class="text-center q-ma-lg">
        <div>
          <m-label
            :name="label.name || $t('preview_chip')"
            :description="label.description"
            :colour="label.colour"
            square
          />
        </div>
        <div v-if="!attaching" class="text-caption">
          {{ $t('label.see_description_tooltip') }}
        </div>
      </div>
      <m-group-title
        :label="amendMode ? $t('label.edit_form') : $t('label.create_form')"
        :sublabel="amendMode ? $t('tooltip.preview_changes') : $t('tooltip.preview_label')"
        class="q-ml-sm"
      />
      <q-card>
        <q-card-section>
          <div class="column q-col-gutter-md">
            <div class="col">
              <q-input
                v-model="label.name"
                :label="$t('label.name')"
                :hint="$t('label.name_tooltip')"
                :disable="submitting"
                :rules="[ val => !val || isLabelValid(val) || $t('label.validation')]"
                autofocus
                @blur="normalize"
              />
            </div>
            <template v-if="!attaching">
              <div class="col">
                <q-input
                  v-model="label.description"
                  :label="$t('label.description')"
                  :hint="$t('label.description_tooltip')"
                  :disable="submitting"
                  :maxlength="255"
                  autogrow
                  counter
                />
              </div>
              <div class="col column text-center q-col-gutter-sm">
                <div class="col text-label">
                  {{ $t('label.colour') }}
                </div>
                <div class="col">
                  <m-defined-colour-picker v-model="label.colour" />
                </div>
              </div>
            </template>
          </div>
        </q-card-section>
      </q-card>
    </div>
  </peek-form>
</template>
<script>
import Label from 'store/models/label'
import peekForm from 'peeks/peekForm.vue'
import { mapGetters } from 'vuex'
import colours from 'mixins/colours.js'
import labelUtil from 'utils/label-utils'
import { MLabel, MGroupTitle, MDefinedColourPicker } from 'components/'

const blankLabel = () => {
  return {
    name: '',
    description: '',
    colour: null
  }
}
export default {
  components: {
    peekForm,
    MLabel,
    MGroupTitle,
    MDefinedColourPicker
  },
  mixins: [colours],
  data () {
    return {
      submitting: false,
      label: null
    }
  },
  computed: {
    ...mapGetters(['organisation', 'ui']),
    labels () {
      return Label.all()
    },
    amendMode () {
      return !!this.$route.query.label
    },
    attaching () {
      return !!(this.$route.query.resource && this.$route.query.reference)
    }
  },
  mounted () {
    const editable = this.$route.query.label
    this.label = blankLabel()
    if (editable) {
      this.label = Label.find(editable)
      if (!this.label) {
        this.$store.dispatch('entities/labels/loadAllLabels').then(() => {
          this.label = Label.find(editable)
          if (!this.label) this.$router.push({ hash: '/organisation/labels' })
        })
      }
    }
  },
  methods: {
    isLabelValid: val => labelUtil.isLabelValid(val),
    normalize () {
      const label = this.label
      if (label.name) this.label.name = labelUtil.convertLabelToKebabCase(label.name)
    },
    submit () {
      if (this.attaching) this.attach()
      else this.create()
    },
    update () {
      this.submitting = true
      this.$store.dispatch('entities/labels/updateLabel', {
        id: this.label.id,
        label: {
          name: this.label.name,
          description: this.label.description,
          colour: this.label.colour
        }
      })
        .then(() => {
          this.$q.notify({
            message: this.$t('label.updated'),
            color: 'positive'
          })
          this.$router.push({ hash: '/organisation/labels' })
        })
        .catch((error) => {
          this.$q.notify({
            message: this.$t('error.unable_to.update_label', { error: error?.data?.errors?.name[0] }),
            color: 'negative'
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    create () {
      this.submitting = true
      this.$store.dispatch('entities/labels/createLabel', this.label)
        .then(() => {
          this.$q.notify({
            message: this.$t('label.created'),
            color: 'positive',
            icon: 'fiber_new'
          })
          this.$router.push({ hash: '/organisation/labels' })
        })
        .catch((error) => {
          this.$q.notify({
            message: this.$t('error.unable_to.update_label', { error: error?.data?.errors?.name[0] }),
            color: 'negative'
          })
        })
        .finally(() => {
          this.submitting = false
        })
    },
    attach () {
      this.submitting = true
      this.$store.dispatch('entities/labels/attachLabels', {
        resource: this.$route.query.resource,
        reference: this.$route.query.reference,
        labels: [this.label]
      })
        .then(() => {
          this.$q.notify({
            message: this.$t('label.created_attached'),
            color: 'positive',
            icon: 'create'
          })
          this.$router.go(-1)
        })
        .catch((error) => {
          this.$q.notify({
            message: this.$t('error.unable_to.update_label', { error: error?.data?.errors?.name[0] }),
            color: 'negative'
          })
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>
