import _ from 'lodash'

const initial = {
  params: {}
}

const state = _.cloneDeep(initial)

const getters = {
  params: state => state.params,
  token: state => state.params.token,
  requester: state => state.params.requester,
  journey: state => state.params.journey,
  reasonFor: state => state.params.reasonFor,
  labels: state => state.params.labels,
  travelType: state => state.params.travelType,
  acceptance: state => state.params.acceptance,
  amount: state => state.params.amount
}

const actions = {
  setParams: ({ commit }, payload) => {
    commit('params', payload)
  }
}

const mutations = {
  params (state, params) {
    state.params = params
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
