import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') ? next() : next('/dashboard')
}

export const index = {
  name: 'sdc',
  path: '/sdc',
  components: {
    default: () => import(/* webpackChunkName: "backoffice" */ 'pages/sdc/index.vue')
  },
  beforeEnter
}

export const show = {
  name: 'sdc-view',
  path: '/sdc/:username',
  redirect: '/sdc/:username/profile',
  components: {
    default: () => import(/* webpackChunkName: "backoffice" */ 'pages/sdc/view/index.vue')
  },
  beforeEnter,
  children: [
    {
      path: 'profile',
      redirect: 'notes'
    },
    {
      path: 'notes',
      components: {
        default: () => import(/* webpackChunkName: "backoffice" */ 'pages/sdc/view/notes.vue')
      }
    },
    {
      path: 'history',
      components: {
        default: () => import(/* webpackChunkName: "backoffice" */ 'pages/sdc/view/history.vue')
      }
    }
  ]
}

export default { index, show }
