import http from './http'

export function index (organisation, params) {
  return http.get(`/organisations/${organisation}/members`, { params })
}

export function overview (organisation, params) {
  return http.get(`/organisations/${organisation}/members-list`, { params })
}

export function individualsIndex (params) {
  return http.get('/individuals', { params })
}

export function get (organisation, username, params) {
  return http.get(`/organisations/${organisation}/members/${username}`, { params })
}

export function put (organisation, username, params) {
  return http.put(`/organisations/${organisation}/members/${username}`, params)
}

export function post (organisation, params) {
  return http.post(`/organisations/${organisation}/members/invite/create`, params)
}

export function block (organisation, username, params) {
  return http.post(`/organisations/${organisation}/members/${username}/block`, params)
}

export function unblock (organisation, username, params) {
  return http.post(`/organisations/${organisation}/members/${username}/unblock`, params)
}

export function resetPasswordEmail (email) {
  return http.post('/password/email', { email: email })
}

export function notes (slug) {
  return http.get(`/users/${slug}/notes?orderBy=created_at:desc`)
}

export function addNote (slug, text) {
  return http.post(`/users/${slug}/notes`, { body: text })
}

export function bookings (page, username) {
  return http.get(`/users/${username}/bookings?page=${page}&include=user,booker`)
}

export function activities (username, params) {
  return http.get(`/users/${username}/activity`, { params })
}

export function invoices (organisation, username, params) {
  return http.get(`/organisations/${organisation}/members/${username}/invoices`, {
    params: {
      ...params
    }
  })
}

export function search (org, params) {
  return http.get(`/organisations/${org}/members/search`, { params })
}

export function downloadMembersCSV (org) {
  return http.post(`/organisations/${org}/members/export-csv`)
}
