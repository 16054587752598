import { hasPermission } from 'utils/authentication'
/**
 * Route requires authed user to have required permission before they can navigate to it.
 */
export default (to, from, next) => {
  if (to.matched.some(record => record.meta.permission && !hasPermission(record.meta.permission))) {
    return next({
      name: 'dashboard'
    })
  }
  next()
}
