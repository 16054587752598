import _ from 'lodash'
import { getMangoCustomer, setChargeACcountSettings } from 'api/prepay'

const initial = {
  credentials: null,
  validated: false
}

const state = _.cloneDeep(initial)

const getters = {
  getCredentials: state => state.credentials,
  getValidated: state => state.validated
}

const actions = {
  storeCredentials ({ commit }, credentials) {
    commit('credentials', credentials)
  },
  validateCredentials ({ commit }, valid = true) {
    commit('validated', valid)
  },
  async fetchCredentials ({ commit }, username) {
    const credentials = await getMangoCustomer(username)
    if (credentials) commit('credentials', credentials)
    return credentials
  },
  async createChargeAccount ({ commit }, username) {
    return setChargeACcountSettings(username)
  }
}

const mutations = {
  credentials (state, credentials) {
    state.credentials = credentials
  },
  validated (state, validated) {
    state.validated = validated
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
