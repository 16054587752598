import date from 'utils/date-time'
import { DateTime } from 'luxon'

export default (data, timezone) => {
  const splitDate = data.split(/-|:|\.| /)
  splitDate.splice(splitDate.length - 1, 1)
  const toNumber = splitDate.map(time => Number(time))
  const utcTime = DateTime.utc(...toNumber)
  const GMT = utcTime.setZone(timezone)

  return date.toShortDateTime(GMT.toString())
}

export function toLocalISO (data, timezone) {
  const splitDate = data.split(/-|:|\.| /)
  splitDate.splice(splitDate.length - 1, 1)
  const toNumber = splitDate.map(time => Number(time))
  const utcTime = DateTime.utc(...toNumber)
  const GMT = utcTime.setZone(timezone)

  return GMT.toString()
}
