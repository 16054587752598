<template>
  <mango-wrapper :subtitle="$t('mango.signup')">
    <form>
      <div v-if="page === 1" class="page">
        <!-- Title -->
        <label>
          <div class="input-wrapper" :class="{ error: $v.params.title.$error }">
            <span>{{ $t('title') }}</span>
            <input v-model="params.title">
          </div>
          <div v-if="$v.params.title.$error" role="alert">
            {{ $t('validation.required.title') }}
          </div>
        </label>
        <!-- Name -->
        <label>
          <div class="input-wrapper">
            <span>{{ $t('name.short') }}</span>
            <input :value="name" disabled>
          </div>
        </label>
        <!-- Date of Birth -->
        <m-date-field
          :value="params.date_of_birth"
          :label="$t('date.birth')"
          :error="$v.params.date_of_birth.$error"
          :error-message="$t('validation.required.date_of_birth')"
          :max="maxDate"
          rounded
          outlined
          civil
          class="m-field"
          @input="val => handleDate(val)"
        />
        <!-- Phone -->
        <m-phone-field
          :dialcode="dialcode"
          :phone="phone"
          :phone-error="$v.dialcode.$error || $v.phone.$error"
          borderless
          class="m-phone-field"
          @dialcode="val => (dialcode = val)"
          @phone="val => (phone = val)"
        />
      </div>

      <div v-if="page === 2" class="page">
        <!-- Address 1 -->
        <label>
          <div
            class="input-wrapper"
            :class="{ error: $v.params.address_line1.$error }"
          >
            <span>{{ $t('address_line_one') }}</span>
            <input v-model="params.address_line1">
          </div>
          <div v-if="$v.params.address_line1.$error" role="alert">
            {{ $t('validation.required.address_line_one') }}
          </div>
        </label>
        <!-- City -->
        <label>
          <div class="input-wrapper" :class="{ error: $v.params.town.$error }">
            <span>{{ $t('city') }}</span>
            <input v-model="params.town">
          </div>
          <div v-if="$v.params.town.$error" role="alert">
            {{ $t('validation.required.town') }}
          </div>
        </label>
        <!-- Post Code -->
        <label>
          <div
            class="input-wrapper"
            :class="{ error: $v.params.postcode.$error }"
          >
            <span>{{ $t('post_code') }}</span>
            <input v-model="params.postcode">
          </div>
          <div v-if="$v.params.postcode.$error" role="alert">
            {{ $t('validation.required.post_code') }}
          </div>
        </label>
      </div>

      <div v-if="page === 3" class="page">
        <!-- Email -->
        <label>
          <div class="input-wrapper" :class="{ error: $v.params.email.$error}">
            <span>{{ $t('email') }}</span>
            <input v-model="params.email" :disabled="!canAlterEmail">
          </div>
          <div v-if="$v.params.email.$error" role="alert">
            {{ $t('validation.email') }}
          </div>
        </label>
        <!-- Password -->
        <label>
          <div
            class="input-wrapper"
            :class="{ error: $v.params.password.$error }"
          >
            <span>{{ $t('password') }}</span>
            <input v-model="params.password" type="password">
          </div>
          <div v-if="$v.params.password.$error" role="alert">
            {{ $t('validation.required.password') }}
          </div>
        </label>
        <!-- Confirm Password -->
        <label>
          <div
            class="input-wrapper"
            :class="{ error: $v.confirm_password.$error }"
          >
            <span>{{ $t('confirm.password') }}</span>
            <input v-model="confirm_password" type="password">
          </div>
          <div v-if="$v.confirm_password.$error" role="alert">
            {{ $t('validation.passwords_must_match') }}
          </div>
        </label>
        <m-checkbox
          v-model="params.contact_by_email"
          :message="$t('mango.contact_by_email')"
          style="margin-block: 2px"
        />
        <m-checkbox
          v-model="params.contact_by_phone"
          :message="$t('mango.contact_by_phone')"
          style="margin-block: 2px"
        />
        <m-checkbox
          v-model="params.contact_by_post"
          :message="$t('mango.contact_by_post')"
          style="margin-block: 2px"
        />

        <label style="margin-block:2px">
          <div class="checkbox-wrapper" :class="{ error: $v.tnc.$error }">
            <input v-model="tnc" type="checkbox">
            <i18n path="mango.accept_tnc">
              <a
                href="https://mobilleo.s3.eu-west-2.amazonaws.com/mango/Mango+terms+conditions.pdf"
                target="_blank"
              >{{ $t('terms_and_conditions') }}</a>
            </i18n>
          </div>
          <div v-if="$v.tnc.$error" role="alert">
            {{ $t('validation.required.tnc') }}
          </div>
        </label>
      </div>

      <div class="button-wrapper">
        <button type="submit" @click.prevent="submit">
          {{ $t('continue') }}
        </button>
        <button v-if="page > 1" @click.prevent="page > 1 && page--">
          {{ $t('confirm.go_back') }}
        </button>
      </div>
    </form>
  </mango-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import mangoWrapper from '../mango-wrapper'
import { MDateField, MPhoneField, MCheckbox } from 'components/'
import date from 'utils/date-time'
import { signupMango } from 'api/prepay'
import { required, sameAs, email } from 'vuelidate/lib/validators'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'
import handlePhoneNumber from 'utils/phone'

const canAlterEmail = process.env.VUE_APP_MANGO_FREE_EMAIL_FIELD

export default {
  name: 'MangoSignupForm',
  components: {
    mangoWrapper,
    MDateField,
    MPhoneField,
    MCheckbox
  },
  data () {
    return {
      page: 1,
      params: {
        email: null,
        address_line1: null,
        town: null,
        postcode: null,
        password: null,
        date_of_birth: null,
        title: null,
        contact_by_email: false,
        contact_by_phone: false,
        contact_by_post: false
      },
      dialcode: '44',
      phone: null,
      confirm_password: null,
      tnc: false,
      canAlterEmail
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    maxDate () {
      return date.subtractFromDate(date.now(), { years: 5 })
    },
    name () {
      const {
        meta: { first_name, last_name }
      } = this.user
      return first_name + ' ' + last_name
    }
  },
  mounted () {
    const { countryCallingCode, nationalNumber } = handlePhoneNumber(
      this.user.meta.mobile
    )
    this.dialcode = countryCallingCode
    this.phone = nationalNumber
    this.params.email = this.user.email
  },
  methods: {
    handleDate (val) {
      this.params.date_of_birth = date.toCivilDate(val)
    },
    async submit () {
      if (this.page === 3) {
        try {
          const partner = this.$store.getters.partner
          loading.start({
            partnerSlug: partner.slug,
            spinnerColor: partner.hex,
            message: this.$t('loading.submitting_details')
          })
          this.$v.$touch()
          if (this.$v.$error) return
          const phone = this.dialcode + this.phone
          const { username } = this.$store.getters.user
          await signupMango(username, { ...this.params, phone_number: phone })
          this.$router.push({ hash: '/mango/validate' })
        } catch (err) {
          handleErrors(err)
        } finally {
          loading.stop()
        }
      }
      if (this.page === 2) {
        this.$v.params.address_line1.$touch()
        this.$v.params.town.$touch()
        this.$v.params.postcode.$touch()
        if (this.$v.params.address_line1.$error) return
        if (this.$v.params.town.$error) return
        if (this.$v.params.postcode.$error) return
        this.page++
      }
      if (this.page === 1) {
        this.$v.params.title.$touch()
        this.$v.params.date_of_birth.$touch()
        this.$v.phone.$touch()
        if (this.$v.params.title.$error) return
        if (this.$v.params.date_of_birth.$error) return
        if (this.$v.phone.$error) return
        this.page++
      }
    }
  },
  validations: {
    params: {
      email: { required, email },
      address_line1: { required },
      town: { required },
      postcode: { required },
      password: { required },
      date_of_birth: { required },
      title: { required }
    },
    dialcode: { required },
    phone: { required },
    confirm_password: {
      sameAs: sameAs(function () {
        return this.params.password
      })
    },
    tnc: {
      accepted: function () {
        return this.tnc
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
form
  min-height calc(100vh - 160px) // 96px is the height of the Mango image
  display flex
  flex-direction: column
  justify-content space-between
  & div.page
    display flex
    flex-direction column
    padding-inline 16px

label
  margin 12px 0 24px
  font-size 80%

.input-wrapper, .checkbox-wrapper
  display flex
  padding 4px 12px
  &.error
    color red
    & span
      animation shake 0.2s

.input-wrapper
  flex-direction: column
  border 1px solid #ccc
  border-radius 20px
  & input
    font-size initial
    padding-bottom 4px
  &.error
    border 2px solid red
    padding 3px 11px // Re-work the padding to avoid layout shift from the thicker border

.checkbox-wrapper a
  font-weight 600

input[type=checkbox]
  appearance checkbox
  margin-inline-end 8px
  width 15px
  height 15px
  margin-top 2.5px
  accent-color var(--q-color-primary)

@keyframes shake {
  0% {
    transform translateX(-2px)
  }
  33% {
    transform translateX(2px)
  }
  66% {
    transform translateX(-2px)
  }
  100% {
    transform translateX(2px)
  }
}

@keyframes slide-down {
  0% {
    opacity 0%
    transform: translateY(-1.2em)
    }
  100% {
    oppacity 100%
    transform: translateY(0)
    }
}

div[role=alert]
  height 1.2em
  color red
  margin 0 16px -1.2em
  transform: translateY(0px)
  animation slide-down 0.8s

label.m-field
  font-size initial
  & >>> .q-field__bottom
    margin-bottom 4px

.button-wrapper
  background-color white
  padding 16px
  button
    width 100%
    border-radius 16px
    padding-block 12px
    background-color white
    color var(--q-color-primary)
    border 1px solid var(--q-color-primary)
    &:first-of-type
      background-color var(--q-color-primary)
      color white
      border none
      margin-block 16px

.m-phone-field
  margin 12px 0 24px
</style>
