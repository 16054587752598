import { cloneDeep, pickBy } from 'lodash'

// initial state, we store it like this so we can reset easier
const initial = {
  formUrl: null,
  paReq: null,
  creq: null,
  termUrl: null,
  threeDSSessionData: null,
  merchantData: null
}

// Copy inital state over to the state var
const state = cloneDeep(initial)
const getters = {
  formFields (state) {
    return {
      formUrl: state.formUrl,
      paReq: state.paReq,
      creq: state.creq,
      termUrl: state.termUrl,
      threeDSSessionData: state.threeDSSessionData,
      merchantData: state.merchantData
    }
  }
}

const actions = {
  setFormFields ({ commit }, params) {
    commit('setFormFields', pickBy(params))
  }
}

const mutations = {
  setFormFields (state, params) {
    Object.keys(params).forEach(k => {
      state[k] = params[k]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
