<template>
  <peek-form :title="$t('legal_agreements')" :footer="false">
    <q-page padding>
      <q-list>
        <q-item
          tag="a"
          href="https://www.mobilleo.com/legal/terms"
          target="_blank"
        >
          {{ $t('terms_and_conditions') }}
        </q-item>
        <q-item
          tag="a"
          href="https://www.mobilleo.com/legal/privacy-policy"
          target="_blank"
        >
          {{ $t('privacy_policy') }}
        </q-item>
      </q-list>
    </q-page>
  </peek-form>
</template>

<script>
import PeekForm from 'peeks/peekForm'

export default {
  components: { PeekForm }
}
</script>
