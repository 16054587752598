import { index, get, post, put, attachRoles, detachRoles } from 'api/system/permissions'
import Permission from 'store/models/permission'
import Role from 'store/models/role'
import { Notify } from 'quasar'
import { isEqual } from 'lodash'

const notificationFn = ({ error, message, color, icon }) => {
  if (!message) message = (error?.data?.message || error?.data?.title) || 'An unexpected error while manipulating permission, please try again later'
  Notify.create({
    message: message,
    color: error ? 'negative' : color || null,
    icon: icon || error ? 'error' : null,
    position: 'top-right'
  })
}

const actions = {
  load ({ commit }, params) {
    return index({
      include: 'roles',
      ...params
    })
      .then((response) => {
        commit('savePermission', response.data)
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  },
  query ({ commit }, { slug, params }) {
    return get(slug, {
      include: 'roles',
      ...params
    })
      .then((response) => {
        commit('savePermission', response.data)
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  },
  create ({ commit }, permission) {
    return post(permission)
      .then(async (response) => {
        notificationFn({
          message: 'Sucessfully created new permission',
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('savePermission', response.data)
        if (permission.roles?.length) {
          await attachRoles(response.data.slug, { roles: permission.roles }).then(() => {
            notificationFn({
              message: 'Sucessfully attached role(s) to permission',
              color: 'positive',
              icon: 'mdi-check'
            })
          })
            .catch((error) => {
              notificationFn({ error })
            })
        }
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  },
  async update ({ commit }, { slug, permission }) {
    const preUpdateRoles = Permission.find(slug).roles.map(role => role.slug)
    if (!isEqual(preUpdateRoles, permission.roles)) {
      var roleAction = detachRoles
      var roleData = preUpdateRoles
      if (permission.roles.length) {
        roleAction = attachRoles
        roleData = permission.roles
      }
      await roleAction(slug, { roles: roleData }).then(() => {
        notificationFn({
          message: 'Sucessfully attached role(s) to permission',
          color: 'positive',
          icon: 'mdi-check'
        })
      })
        .catch((error) => {
          notificationFn({ error })
        })
    }
    return put(slug, permission)
      .then((response) => {
        commit('savePermission', response.data)
        notificationFn({
          message: 'Sucessfully updated permission',
          color: 'positive',
          icon: 'mdi-check'
        })
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  }
}

const mutations = {
  savePermission (state, data) {
    if (data?.length) {
      data = data.map(perm => {
        return {
          ...perm,
          roles: perm?.roles?.data
        }
      })
    } else if (data?.roles?.data) {
      data = {
        ...data,
        roles: data.roles.data
      }
      Role.insertOrUpdate({ data: data.roles })
    }
    Permission.insertOrUpdate({ data })
  }
}

export default {
  actions,
  mutations,
  namespaced: true
}
