import store from 'store/modules/ondemand'

const meta = {
  permission: 'search.tram'
}

const beforeEnter = (to, from, next) => {
  !store.state.stash.parameters ? next({ name: 'ondemand-tram' }) : next()
}

export const form = {
  name: 'ondemand-tram',
  path: '/on-demand/tram',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-tram" */ 'pages/ondemand/tram/index.vue')
  },
  meta: {
    ...meta,
    label: 'Tram: Search'
  }
}

export const routes = {
  name: 'ondemand-tram-routes',
  path: '/on-demand/tram/routes',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-tram" */ 'pages/ondemand/tram/routes/index.vue')
  },
  meta: {
    ...meta,
    label: 'Tram: Routes'
  },
  beforeEnter
}

export const summary = {
  name: 'ondemand-tram-summary',
  path: '/on-demand/tram/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-tram" */ 'pages/ondemand/tram/summary/index.vue'),
    right: () => import(/* webpackChunkName: "ondemand-tram" */ 'pages/ondemand/tram/sidebar/index.vue')
  },
  meta: {
    ...meta,
    label: 'Tram: Summary'
  },
  beforeEnter
}

export default { form, routes, summary }
