export default {
  name: 'DerbyGO',
  slug: 'derbygo',
  title: 'DerbyGO',
  email: 'derbygo@mobilleo.com',
  brandingLogo: 'derbygo/derbygo-logo-white.svg',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['derbygo'],
  hex: '#013565',
  hasTravelType: false,
  hasReasonFor: false,
  spinnerType: 'arrow',
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.mobilleo.android',
    ios: 'https://itunes.apple.com/gb/app/mobilleo/id1249273159?mt=8'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
  privacyURL: 'https://www.fodmobilitygroup.com/wp-content/uploads/2023/02/FOD-Mobility-Group-Privacy-Policy-23.pdf',
  sociabilityUrl: 'https://www.sociability.app/derbygo',
  uniUrl: 'https://www.derby.ac.uk/life/our-campuses/derby/',
  collegeUrl: 'https://www.derby-college.ac.uk/our-colleges',
  pages: {
    login: {
      form: {
        class: 'login-form',
        email: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        password: {
          dark: false,
          color: 'primary',
          labelColor: 'primary',
          filled: false,
          class: 'login-field'
        },
        signIn: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        skipLogin: {
          class: 'login-button',
          color: 'primary',
          align: 'between',
          iconRight: 'fal fa-angle-right'
        },
        forgotPassword: {
          class: 'login-button text-primary',
          align: 'between',
          outline: true
        },
        validation: {
          class: 'space row col text-center text-negative strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {},
        verificationButton: {
          class: 'login-button login-white',
          color: 'primary',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'login-form',
          welcomeMessage: {
            class: 'login-text'
          },
          email: {
            class: 'login-field',
            dark: false,
            color: 'primary',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            text: 'Send password reset email',
            align: 'between',
            iconRight: 'fal fa-angle-right',
            size: 'md'
          },
          backButton: {
            class: 'login-button',
            align: 'between',
            flat: true,
            iconRight: 'fal fa-angle-right'
          }
        }
      },
      reset: {
        form: {
          class: 'login-form',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'white',
            filled: false
          },
          password: {
            dark: false,
            color: 'white',
            filled: false
          },
          passwordConfirmation: {
            dark: false,
            color: 'white',
            filled: false
          },
          resetButton: {
            class: 'login-button login-white',
            color: 'primary',
            align: 'between',
            flat: false,
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    signup: {
      complete: {
        form: {
          class: 'login-form full-width',
          welcomeMessage: {
            class: 'login-field'
          },
          email: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          organisationName: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          name: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          password: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          passwordConfirmation: {
            dark: false,
            color: 'primary',
            labelColor: 'primary',
            filled: false,
            class: 'login-field'
          },
          completeButton: {
            class: 'login-button login-white',
            size: 'md',
            color: 'primary',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          },
          skipLogin: {
            class: 'login-button',
            color: 'primary',
            size: 'md',
            align: 'between',
            iconRight: 'fal fa-angle-right'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: 'login-button'
      }
    }
  },
  components: {
    mGmaps: {
      markers: []
    },
    mImageModal: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {}
      }
    }
  },
  contents: {
    bus: { showInSmartSearch: true },
    tram: { showInSmartSearch: false },
    subway: { showInSmartSearch: false },
    rail: { showInSmartSearch: true },
    bikehire: { showInSmartSearch: true },
    driving: { showInSmartSearch: true },
    bicycle: { showInSmartSearch: true },
    walking: { showInSmartSearch: true },
    carclub: { showInSmartSearch: false },
    rental: { showInSmartSearch: false },
    ridehailing: { showInSmartSearch: false },
    flight: { showInSmartSearch: false }
  },
  mobility: [
    'mango'
  ],
  bookables: [
    'train',
    'bus',
    'ridehailing',
    'parking',
    'bikehire'
  ],
  nearby: [
    'sociability',
    'derbyUniversity',
    'derbyCollege',
    'restaurant',
    'bar',
    'cafe'
  ]
}
