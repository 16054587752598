import Vue from 'vue'
import { Notify } from 'quasar'
import _ from 'lodash'
import { getUserFavouriteAddresses, saveFavouriteAddress, deleteFavouriteAddress, updateFavouriteAddress } from 'api/address'
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'

const initial = {
  recent: [],
  saved: []
}

const state = initial

const getters = {
  recent: (state) => state.recent,
  saved: state => state.saved
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  saveRecentLocations ({ commit, getters }, locations) {
    const recent = getters.recent
    let newRecentLocations = [...recent]

    locations.forEach(loc => {
      if (loc.label !== 'Home' && loc.label !== 'Work') {
        newRecentLocations.unshift(loc)
      }
    })
    newRecentLocations = _.uniqBy(newRecentLocations, loc => loc.label)
    newRecentLocations = newRecentLocations.slice(0, 8)
    commit('saveRecentLocations', newRecentLocations)
  },
  async getSavedLocations ({ commit }) {
    try {
      const res = await getUserFavouriteAddresses()
      const addresses = res.data.map(address => ({
        ...address,
        ...(!address.label ? { label: address.name } : {})
      }))
      commit('add', { type: 'saved', addresses })
    } catch (err) {
      handleErrors(err)
    }
  },
  async updateAddresses ({ commit, dispatch }, address) {
    await commit('add', { type: 'saved', addresses: address })
    await dispatch('saveLocations')
  },
  async save ({ dispatch }, address) {
    try {
      await saveFavouriteAddress(address)
      Notify.create({
        type: 'positive',
        message: i18n.t('address_saved', { address: address.label })
      })
      await dispatch('getSavedLocations')
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: i18n.t('error.address_not_saved', { address: address.label })
      })
    }
  },
  async deleteAddress ({ dispatch }, { id, label }) {
    try {
      await deleteFavouriteAddress(id)
      await dispatch('getSavedLocations')
      Notify.create({
        type: 'positive',
        message: i18n.t('address_deleted', { address: label })
      })
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: i18n.t('error.address_not_deleted', { address: label })
      })
    }
  },
  async updateAddress ({ dispatch }, address) {
    try {
      await updateFavouriteAddress(address)
      await dispatch('getSavedLocations')
      Notify.create({
        type: 'positive',
        message: i18n.t('address_updated', { address: address.label })
      })
    } catch (err) {
      Notify.create({
        type: 'negative',
        message: i18n.t('error.address_not_updated', { address: address.label })
      })
    }
  }
}

const mutations = {
  saveRecentLocations (state, locs) {
    state.recent = locs
  },
  storeAddresses (state, addresses) {
    state.saved = addresses
  },
  reset (state) {
    state = initial
  },
  add (state, { type, addresses }) {
    Vue.set(state, type, addresses)
  },
  remove (state, index) {
    state.splice(index, 1)
  },
  edit (state, address, index) {
    state[index] = address
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
