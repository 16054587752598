import http from './http'

export function index (params) {
  return http.get('/bookings', {
    params: {
      include: 'user,requester',
      ...params
    }
  })
}

export function get (reference, params) {
  return http.get(`/bookings/${reference}`, { params })
}

export function update (reference, params) {
  return http.put(`/bookings/${reference}`, params)
}

export function create (params) {
  return http.post(`/bookings`, params)
}

export function notes (slug, orderBy = 'created_at:desc') {
  return http.get(`/booking/${slug}/notes?orderBy=${orderBy}`)
}

export function addNote (slug, text) {
  return http.post(`/booking/${slug}/notes`, { body: text })
}

export function invoices (slug, params) {
  return http.get(`/bookings/${slug}/invoices`, { params })
}

export function documents (slug, params) {
  return http.get(`/bookings/${slug}/documents`, { params })
}

export function activity (reference, params) {
  return http.get(`/bookings/${reference}/activity`, { params })
}

/*
Automatic booking cancellation is only available for select content-types.
There is a good chance this API call _will_ fail. So make sure to always set up
a catch for that situation.
*/
export function cancel (reference) {
  return http.get(`/bookings/${reference}/cancel`)
}
