import { index, get, post, put, attachPermissions, detachPermissions } from 'api/system/roles'
import Role from 'store/models/role'
import Permission from 'store/models/permission'
import { Notify } from 'quasar'
import { isEqual } from 'lodash'

const notificationFn = ({ error, message, color, icon }) => {
  if (!message) message = (error?.data?.message || error?.data?.title) || 'An unexpected error while manipulating role, please try again later'
  Notify.create({
    message: message,
    color: error ? 'negative' : color || null,
    icon: icon || error ? 'error' : null,
    position: 'top-right'
  })
}

const actions = {
  load ({ commit }, params) {
    return index({
      include: 'permissions',
      ...params
    })
      .then((response) => {
        commit('saveRole', response.data)
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  },
  query ({ commit }, { slug, params }) {
    return get(slug, {
      include: 'permissions',
      ...params
    })
      .then((response) => {
        commit('saveRole', response.data)
        return response.data
      })
      .catch((error) => {
        notificationFn({ error })
      })
  },
  create ({ commit }, role) {
    return post(role)
      .then(async (response) => {
        notificationFn({
          message: 'Sucessfully created new role',
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('saveRole', response.data)
        if (role.permissions.length) {
          await attachPermissions(response.data.slug, { permissions: role.permissions }).then(() => {
            notificationFn({
              message: 'Sucessfully attached permission(s) to role',
              color: 'positive',
              icon: 'mdi-check'
            })
          })
            .catch((error) => {
              notificationFn({ error })
            })
        }
        return response.data
      })
  },
  async update ({ commit }, { slug, role }) {
    const preUpdatePermissions = Role.find(slug).permissions.map(perm => perm.slug)
    if (!isEqual(preUpdatePermissions, role.permissions)) {
      var permissionAction = detachPermissions
      var permissionData = preUpdatePermissions
      if (role.permissions.length) {
        permissionAction = attachPermissions
        permissionData = role.permissions
      }
      await permissionAction(slug, { permissions: permissionData }).then(() => {
        notificationFn({
          message: 'Sucessfully attached permission(s) to role',
          color: 'positive',
          icon: 'mdi-check'
        })
      })
        .catch((error) => {
          notificationFn({ error })
        })
    }
    return put(slug, role)
      .then((response) => {
        commit('saveRole', response.data)
        return response.data
      })
  }
}

const mutations = {
  saveRole (state, data) {
    if (data?.length) {
      data = data.map(role => {
        return {
          ...role,
          permissions: role?.permissions?.data
        }
      })
    } else if (data?.permissions?.data) {
      data = {
        ...data,
        permissions: data.permissions.data
      }
      Permission.insertOrUpdate({ data: data.permissions })
    }
    Role.insertOrUpdate({ data })
  }

}

export default {
  actions,
  mutations,
  namespaced: true
}
