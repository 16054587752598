import authentication from 'mixins/authentication'

const beforeEnter = (to, from, next) => {
  authentication.methods.hasRole('backoffice') || authentication.methods.hasRole('admin') ? next() : next('/dashboard')
}

export const index = {
  name: 'individuals',
  path: '/individuals',
  components: {
    default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/index.vue')
  },
  beforeEnter
}

export const show = {
  name: 'individuals-view',
  path: '/individuals/:username',
  redirect: '/individuals/:username/profile',
  components: {
    default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/index.vue')
  },
  beforeEnter,
  children: [
    {
      path: 'profile',
      redirect: 'bookings'
    },
    {
      path: 'bookings',
      components: {
        default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/bookings.vue')
      }
    },
    {
      path: 'journeys',
      components: {
        default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/journeys.vue')
      }
    },
    {
      path: 'invoices',
      components: {
        default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/invoices.vue')
      }
    },
    {
      path: 'notes',
      components: {
        default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/notes.vue')
      }
    },
    {
      path: 'history',
      components: {
        default: () => import(/* webpackChunkName: "individuals" */ 'pages/individuals/view/history.vue')
      }
    }
  ]
}

export default { index, show }
