import authentication from 'mixins/authentication'
import { Platform } from 'quasar'

const meta = {
  permission: 'search.rides'
}

const summary = {
  name: 'ondemand-ride-hailing-summary',
  path: '/on-demand/ride-hailing/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/summary.vue')
  },
  meta: {
    ...meta,
    label: 'Taxi: Summary'
  }
}

const view = {
  path: '/on-demand/ride-hailing',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/map-form/layout.vue')
  },
  children: [
    {
      path: '/on-demand/ride-hailing',
      name: 'ondemand-ride-hailing',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/index.vue')
      },
      meta: {
        label: 'Taxi: Search',
        layout: {
          name: 'basic',
          bottomsheet: true,
          components: { footer: 'MMobileFooter' }
        }
      },
      beforeEnter: (to, from, next) => {
        authentication.methods.hasRole('middleoffice') && Platform.is.desktop ? next({ name: 'ondemand-office-ride-hailing' }) : next()
      }
    },
    {
      path: '/on-demand/ride-hailing/results',
      name: 'ondemand-ride-hailing-results',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/vehicles.vue')
      },
      meta: {
        label: 'Taxi: Results',
        layout: {
          name: 'basic',
          bottomsheet: true,
          components: { footer: 'MMobileFooter' }
        }
      }
    },
    {
      name: 'ondemand-ride-hailing-vehicle',
      path: '/on-demand/ride-hailing/vehicle',
      components: {
        default: () => import(/* webpackChunkName: "ondemand-ridehailing" */ 'pages/ondemand/ride-hailing/vehicle.vue')
      },
      meta: {
        label: 'Taxi: Vehicle',
        layout: {
          name: 'basic',
          bottomsheet: true,
          components: { footer: 'MMobileFooter' }
        }
      }
    }
  ]
}

export default { summary, view }
