<template>
  <div>
    <q-card-section>
      <m-banner
        color="primary"
        :message="$t('bikehire.brompton.must_match_mobilleo')"
      />
    </q-card-section>
    <q-card-section>
      <q-select
        v-model="title"
        :label="$t('title')"
        :options="titles"
        :error="$v.title.$error"
        :error-message="$t('title_is_required')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.title.$touch()"
      />
      <q-input
        v-model="first_name"
        :label="$t('name.forename')"
        :error="$v.first_name.$error"
        :error-message="$t('forename_required')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.first_name.$touch()"
      />
      <q-input
        v-model="last_name"
        :label="$t('name.surname')"
        :error="$v.last_name.$error"
        :error-message="$t('surname_required')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.last_name.$touch()"
      />
      <m-date-picker
        v-model="date_of_birth"
        :label="$t('date.birth')"
        :error="$v.date_of_birth.$error"
        :error-message="$t('bikehire.validation.age')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.date_of_birth.$touch()"
      />
      <q-input
        v-model="email"
        :label="$t('email_address')"
        :error="$v.email.$error"
        :error-message="$t('validation.email')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.email.$touch()"
      />
      <q-input
        v-model="mobile_number"
        :label="$t('mobile_number')"
        prefix="(44)"
        :error="$v.mobile_number.$error"
        :error-message="$t('bikehire.validation.mobile')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.mobile_number.$touch()"
      />
      <q-checkbox v-model="marketing_opt_in" :label="$t('confirm.receive_marketing_brompton')" />
      <div v-html="$t('bikehire.brompton.terms')" />
    </q-card-section>
    <q-card-section>
      <m-banner
        color="primary"
        :message="$t('bikehire.once_signup_complete')"
      />
    </q-card-section>
    <q-card-section>
      <q-btn
        color="primary"
        :label="$t('submit')"
        :disabled="invalidForm"
        @click="submit"
      />
    </q-card-section>
  </div>
</template>

<script>
import MDatePicker from 'components/MFields/date'
import { MBanner } from 'components/'
import date from 'utils/date-time'
import { required, email } from 'vuelidate/lib/validators'
import { signup } from 'api/bikehire'
import selects from 'store/modules/users/membersSelects'
import loading from 'utils/loading'
import { mapGetters } from 'vuex'
import { nationalNumber } from 'utils/phone'
import { handleErrors } from 'utils/utils'
const { titles } = selects

const mobileValidator = (num) => {
  const input = num.trim().split(' ').join('')
  const regex = /^7\d{9}$/
  return regex.test(input)
}

const dobValidator = (dob) => {
  const today = new Date()
  const { years } = date.getDateDiff(today, dob, 'years')
  return years > 18
}

export default {
  name: 'BromptonSignUp',
  components: {
    MDatePicker,
    MBanner
  },
  props: ['user'],
  data () {
    return {
      title: null,
      first_name: null,
      last_name: null,
      date_of_birth: null,
      email: null,
      mobile_number: '',
      supplier: 'brompton',
      agree_terms: true,
      marketing_opt_in: false,
      titles
    }
  },
  validations: {
    title: { required },
    first_name: { required },
    last_name: { required },
    date_of_birth: { dobValidator, required },
    email: { email, required },
    mobile_number: { mobileValidator, required }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    invalidForm () {
      return this.$v.$anyError || this.$v.$anyDirty === false
    }
  },
  beforeMount () {
    const { title, first_name, last_name, mobile } = this.user.meta
    this.email = this.user.email
    this.title = { label: title, value: title }
    this.first_name = first_name
    this.last_name = last_name
    this.mobile_number = nationalNumber(mobile)
  },
  methods: {
    async submit () {
      loading.start({
        message: this.$t('loading.submitting_details'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })

      try {
        const { title, first_name, last_name, email, mobile_number, supplier, agree_terms, marketing_opt_in } = this

        const person = {
          title: title.value,
          first_name,
          last_name,
          date_of_birth: date.toCivilDate(this.date_of_birth),
          email,
          mobile_number: `0${mobile_number}`,
          supplier,
          agree_terms,
          marketing_opt_in
        }

        const { data: { returnUrl } } = await signup(person)

        this.$emit('complete', returnUrl)
      } catch (err) {
        handleErrors(err)
      }

      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../../../../styles/ondemand.styl'
  .q-btn
    width 100%
</style>
