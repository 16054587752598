<template>
  <mango-wrapper :subtitle="$t('mango.wait')">
    <div class="success-wrapper">
      <p v-for="text in $t('mango.wait_blurb')" :key="text">
        {{ text }}
      </p>
      <div class="button-container">
        <button @click="$router.push({ hash: '/mango' })">
          {{ $t('mango.retry') }}
        </button>
      </div>
    </div>
  </mango-wrapper>
</template>

<script>
import mangoWrapper from '../mango-wrapper.vue'
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
import loading from 'utils/loading'

export default {
  components: {
    mangoWrapper
  },
  data () {
    return {
      mangoSigninLink: process.env.VUE_APP_MANGO_SIGNIN
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner',
      user: 'user'
    })
  },
  async mounted () {
    loading.stop()
    this.$store.dispatch('mango/createChargeAccount', this.user.username).catch(handleErrors)
  }
}
</script>

<style scoped lang="stylus">
.success-wrapper
  color black
  margin 12px 16px 0
  & p
    text-align center
    margin-block 12px
    &:last-of-type
      margin-bottom 0

.button-container
  color black
  display flex
  flex-direction column
  text-align center
  width 100%
  margin-inline auto
  & button
    background-color var(--q-color-primary)
    color white
    border-radius 16px
    padding-block 12px
    margin-block 16px
    border none
</style>
