<template>
  <peek-form
    :title="$t('passport.information')"
    :subtitle="$t('passport.ensure_details')"
    :footer-title="$t('data_saved_securely')"
    :call-to-action="$t('save.passport')"
    @save="submit"
  >
    <q-card-section padding>
      <q-card class="bg-white text-black q-mt-md">
        <q-card-section>
          <q-input
            v-model="passport_information.passport_number"
            :label="$t('passport.number')"
            :error="$v.passport_information.passport_number.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.passport_information.passport_number.$touch"
          />
          <m-select-country
            v-model="passport_information.passport_issuing_country"
            :label="$t('passport.issuing_country')"
            :error="$v.passport_information.passport_issuing_country.$error"
            @blur="$v.passport_information.passport_issuing_country.$touch"
          />
          <m-date-field
            v-model="passport_information.passport_expiry"
            civil
            :label="$t('date.expiration')"
            :error="$v.passport_information.passport_expiry.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.passport_information.passport_expiry.$touch"
          />
          <m-date-field
            v-model="passport_information.passport_issue"
            civil
            :label="$t('date.issue')"
            :error="$v.passport_information.passport_issue.$error"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.passport_information.passport_issue.$touch"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="q-if-label q-if-label-above">
            <div class="q-if-label-inner ellipsis" style="font-size:.75em">
              {{ $t('sex.sex') }}
            </div>
          </div>
          <q-option-group
            v-model="passport_information.gender"
            :options="[
              { label: $t('sex.male'), value: 'M' },
              { label: $t('sex.female'), value: 'F' }
            ]"
            type="radio"
            inline
            @blur="$v.passport_information.gender.$touch"
          />
          <q-input
            v-model="passport_information.passport_title"
            :label="$t('title')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :error="$v.passport_information.passport_title.$error"
            @blur="$v.passport_information.passport_title.$touch"
          />
          <q-input
            v-model="passport_information.passport_first_name"
            :label="$t('name.forename')"
            :error="$v.passport_information.passport_first_name.$error"
            :hint="$t('exactly_as_on_document')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.passport_information.passport_first_name.$touch"
          />
          <q-input
            v-model="passport_information.passport_last_name"
            :label="$t('name.surname')"
            :error="$v.passport_information.passport_last_name.$error"
            :hint="$t('exactly_as_on_document')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            @blur="$v.passport_information.passport_last_name.$touch"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <m-date-field
            v-model="passport_information.date_of_birth"
            civil
            :label="$t('date.birth')"
            :rounded="$q.platform.is.mobile"
            :outlined="$q.platform.is.mobile"
            :error="$v.passport_information.date_of_birth.$error"
            @blur="$v.passport_information.date_of_birth.$touch"
          />
        </q-card-section>
        <q-separator />
        <q-card-section>
          <m-select-nationality
            v-model="passport_information.nationality"
            :label="$t('nationality')"
            :error="$v.passport_information.nationality.$error"
            @blur="$v.passport_information.nationality.$touch"
          />
          <m-select-country
            v-model="passport_information.country_of_residence"
            :label="$t('country_of_residence')"
            :error="$v.passport_information.country_of_residence.$error"
            @blur="$v.passport_information.country_of_residence.$touch"
          />
        </q-card-section>
      </q-card>
    </q-card-section>
  </peek-form>
</template>

<script type="text/javascript">
import { required } from 'vuelidate/lib/validators'
import { MDateField, MSelectCountry, MSelectNationality } from 'components'
import peekForm from 'peeks/peekForm.vue'

export default {
  components: {
    peekForm,
    MDateField,
    MSelectCountry,
    MSelectNationality
  },
  data () {
    return {
      passport_information: {
        gender: null,
        nationality: null,
        country_of_residence: null,
        date_of_birth: null,
        passport_title: null,
        passport_expiry: null,
        passport_issue: null,
        passport_number: null,
        passport_last_name: null,
        passport_first_name: null,
        passport_issuing_country: null
      }
    }
  },
  created () {
    this.passport_information = {
      ...this.$store.getters.user.meta.passport_information
    }
  },
  validations: {
    passport_information: {
      gender: { required },
      nationality: { required },
      country_of_residence: { required },
      date_of_birth: { required },
      passport_title: {},
      passport_expiry: { required },
      passport_issue: {},
      passport_number: { required },
      passport_last_name: { required },
      passport_first_name: { required },
      passport_issuing_country: { required }
    }
  },
  methods: {
    submit () {
      this.$v.$touch()

      if (!this.$v.$error) {
        this.$store.dispatch('update_user', { passport_information: this.passport_information }).then(response => {
          this.$q.notify({
            message: this.$t('changes_saved'),
            timeout: 1500,
            type: 'positive',
            icon: 'check',
            position: 'top-right'
          })
        })
      } else {
        this.$q.notify({
          message: this.$t('error.unable_to.save_changes'),
          timeout: 1500,
          type: 'error',
          icon: 'error',
          position: 'top-right'
        })
      }
    }
  }
}
</script>
