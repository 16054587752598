<template>
  <peek-form
    :title="$t('concierge.concierge')"
    :call-to-action="$t('concierge.send')"
    class="concierge-peek"
    @save="submit"
  >
    <q-page padding>
      <type-selection />
      <component
        :is="type === 'question' ? 'question' : 'amendCancel'"
        :ref="(type === 'question' ? 'question' : 'amendCancel') + 'Form'"
      />
    </q-page>
  </peek-form>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import peekForm from '../peekForm.vue'
import typeSelection from './type-selection.vue'
import authentication from 'mixins/authentication'
import question from './question.vue'
import amendCancel from './amend-cancel.vue'

export default {
  name: 'Concierge',
  components: {
    peekForm,
    typeSelection,
    question,
    amendCancel
  },
  mixins: [authentication],
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    type () {
      return this.$route.query.type
    }
  },
  methods: {
    submit () {
      if (this.type === 'question') {
        this.$refs.questionForm.submit()
      } else {
        this.$refs.amendCancelForm.submit()
      }
    }
  }
}
</script>

<style lang="stylus">
.concierge-peek .q-textarea .q-field__native
  min-height 260px
</style>
