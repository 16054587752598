import { hasRole } from 'utils/authentication'

const payment = {
  path: '/booking/:token/payment',
  components: {
    default: () => import(/* webpackChunkName: "payments" */'pages/payments/index.vue')
  },
  props: { default: true },
  beforeEnter: (to, from, next) => {
    (hasRole('guest')) ? next('guest-mode') : next()
  },
  children: [
    {
      name: 'booking-payment-account',
      path: 'account',
      components: {
        default: () => import(/* webpackChunkName: "payments" */'pages/payments/account.vue')
      },
      props: { default: true }
    },
    {
      name: 'booking-payment-card',
      path: 'card',
      components: {
        default: () => import(/* webpackChunkName: "payments" */'pages/payments/card/index.vue')
      },
      props: { default: true }
    },
    {
      name: `booking-payment-paypal`,
      path: 'paypal',
      components: {
        default: () => import(/* webpackChunkName: "payments" */'pages/payments/paypal.vue')
      },
      props: { default: true }
    },
    {
      name: 'booking-payment-supplier',
      path: 'supplier',
      components: {
        default: () => import(/* webpackChunkName: "payments" */'pages/payments/supplier.vue')
      },
      props: { default: true }
    }
  ]
}

export default { payment }
