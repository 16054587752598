import http from './http'

export async function getMangoCustomer (username) {
  const { data: { mango } } = await http.get(`users/${username}/prepayment-schemes`)
  return mango
}

export function signupMango (username, params) {
  return http.post(`users/${username}/prepayment-schemes/mango/create-user`, { ...params })
}

export function signinMango (username, code) {
  return http.post(`users/${username}/prepayment-schemes/mango/auth`, { code, redirect_url: 'http://api.mobilleo/noop' })
}

export function setChargeACcountSettings (username) {
  return http.patch(`users/${username}/prepayment-schemes/mango/set-charge-account-settings`)
}

export function removeMangoDetails (username) {
  return http.delete(`users/${username}/prepayment-schemes/mango`)
}

export function getMangoChargeAccount (username) {
  return http.get(`users/${username}/prepayment-schemes/mango/charge-account`)
}

export function addMangoFunds (username, params) {
  return http.post(`users/${username}/prepayment-schemes/mango/add-funds`, { ...params })
}

// This is for requesting QR codes be made available at the beginning of the day
export function requestQRCode (username, device_id) {
  return http.post(`users/${username}/prepayment-schemes/mango/barcode`, { device_id })
}

// This is for retrieving QR codes
export function getQRCode (username) {
  return http.get(`users/${username}/prepayment-schemes/mango/barcode`)
}
