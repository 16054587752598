import loadScript from 'utils/loadScript'
import 'autotrack'
import 'autotrack/lib/plugins/clean-url-tracker'
import 'autotrack/lib/plugins/event-tracker'
import 'autotrack/lib/plugins/url-change-tracker'
import 'autotrack/lib/plugins/page-visibility-tracker'

export default ({ Vue }) => {
  /**
   * Expose the GA function to the Window and Vue instances
   */
  Vue.prototype.$ga = window.ga = window.ga || function () { (ga.q = ga.q || []).push(arguments) }; ga.l = +new Date()

  return loadScript('https://www.google-analytics.com/analytics.js')
    .then(response => {
      /**
       * Setup our Google Analytics Account
       */
      ga('create', process.env.VUE_APP_GOOGLE_ANALYTICS, 'auto')
      /**
       * Setup Autotrack plugins
       * https://github.com/googleanalytics/autotrack
       */
      ga('require', 'cleanUrlTracker')
      ga('require', 'eventTracker')
      ga('require', 'urlChangeTracker')
      ga('require', 'pageVisibilityTracker')
      /**
       * Send initial page view
       */
      ga('send', 'pageview')
    })
}
