<template>
  <q-card class="timeline-card col-12">
    <q-card-section class="smartsearch-timeline-wrapper">
      <q-timeline>
        <q-timeline-entry
          v-for="(item, i) in route"
          :key="i"
          :title="item.subtitle"
          :subtitle="`${item.title} ${item.civil_time ? '| ' + item.civil_time : '' }`"
          :icon="i > 0 ? i === (route.length - 1) ? 'check' : icon() : 'navigation'"
          :color="color(item)"
        />
      </q-timeline>
    </q-card-section>
  </q-card>
</template>

<script>
import travelContents from 'mixins/travelContents'

export default {
  mixins: [travelContents],
  props: ['type', 'route'],
  methods: {
    icon (type) {
      return `img:${require('../../../assets/travel-icons/' + this.type + '.svg')}`
    },
    color (type) {
      return this.getTravelContentColor(this.type)
    }
  }
}
</script>

<style lang="stylus">
.smartsearch-timeline-wrapper
  padding 12px 36px 0 36px!important

/** Set SVG color to white instead of black */
.q-timeline__dot .q-icon > img
  filter invert(1)
  height 1.5em !important
  width 1.5em !important
</style>
