import http from './http'

export function bays ({ latLng, radius }) {
  return http.get(`/search/carclub`, {
    params: {
      limit: 1000,
      latlng: [latLng.lat, latLng.lng].toString(),
      radius
    }
  })
}

export function setCredentials (creds) {
  return http.post('/carclub/credentials', creds)
}

export function deleteCredentials () {
  return http.delete('/carclub/credentials', {
    supplier: 'CarShare'
  })
}

export function availability (params) {
  return http.get(`/search/carclub/availability`, {
    params
  })
}

export function summary (token, params) {
  return http.get(`/search/carclub/summary/${token}`, {
    params
  })
}

export function amend (start, end, booking_ref) {
  return http.get(`/carclub/reservation/${booking_ref}/amend`, { params: { start, end } })
}

export function changeVehicle (booking_ref) {
  return http.get(`carclub/reservation/${booking_ref}/change-vehicle`)
}

export function setNewVehicle (booking_ref, token) {
  return http.get(`carclub/reservation/${booking_ref}/change-vehicle/${token}`)
}
