import http from './http'

export function index (params) {
  return http.get('/journeys', {
    params: {
      ...params,
      include: 'user,organisation'
    }
  })
}

export function get (reference, params) {
  return http.get(`/journeys/${reference}`, {
    params: {
      ...params,
      include: params.include + ',user,organisation'
    }
  })
}

export function documents (slug, params) {
  return http.get(`/journeys/${slug}/documents`, { params })
}

export function createDocument (reference, data) {
  return http.post(`/journeys/${reference}/documents`, data)
}
