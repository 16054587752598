import http from './http'

export function index (params) {
  return http.get(`/pots`, { params })
}

export function errors (params) {
  return http.get(`/pots/errors`, { params })
}

export function amended (params) {
  return http.get(`/pots/amended`, { params })
}

export function bookedToday (params) {
  return http.get(`/pots/booked-today`, { params })
}
