<template>
  <q-btn
    class="full-width"
    :color="bgColor"
    :text-color="textColor"
    @click="setRoute"
  >
    <q-item
      dense
      class="text-left"
      :dark="selection === type"
    >
      <q-item-section v-if="$q.platform.is.desktop" avatar top>
        <q-avatar :icon="icon" :text-color="textColor" />
      </q-item-section>
      <q-item-section>
        <q-item-label caption class="text-weight-bold">
          {{ title }}
        </q-item-label>
        <q-item-label caption class="subtitle">
          {{ subtitle }}
        </q-item-label>
      </q-item-section>
    </q-item>
  </q-btn>
</template>

<script>
export default {
  props: ['type', 'icon', 'title', 'subtitle'],
  computed: {
    selection () {
      return this.$route.query.type
    },
    bgColor () {
      return this.type === this.selection ? 'primary' : 'white'
    },
    textColor () {
      return this.type === this.selection ? 'white' : 'primary'
    }
  },
  methods: {
    setRoute () {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: this.type
        },
        hash: '/concierge'
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.subtitle
  font-size 12px!important
</style>
