import { tickets } from 'api/bus'
import { handleErrors } from 'utils/utils'

const initial = {
  tickets: [],
  loading: false
}

const state = initial

const getters = {
  tickets: (state) => state.tickets,
  loading: state => state.loading
}

const actions = {
  async getTickets ({ commit }, operator) {
    commit('loadingStart')
    try {
      const response = await tickets(operator)
      commit('REQUEST_TICKETS_SUCCESS', response.data)
    } catch (err) {
      handleErrors(err)
    } finally {
      commit('loadingStop')
    }
  }
}

const mutations = {
  REQUEST_TICKETS_SUCCESS (state, tickets) {
    state.tickets = tickets
  },
  loadingStart () {
    state.loading = true
  },
  loadingStop () {
    state.loading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
