import http from './http'

export function index (params) {
  return http.get(`/backoffice`, { params })
}

export function get (username, params) {
  return http.get(`/backoffice/${username}`, { params })
}

export function put (username, params) {
  return http.put(`/backoffice/${username}`, params)
}

export function post (params) {
  return http.post(`/backoffice`, params)
}

export function block (username, params) {
  return http.post(`/users/${username}/block`, params)
}

export function unblock (username, params) {
  return http.post(`/users/${username}/unblock`, params)
}

export function resetPasswordEmail (email) {
  return http.post(`/password/email`, { email: email })
}

export function notes (slug) {
  return http.get(`/users/${slug}/notes?orderBy=created_at:desc`)
}

export function addNote (slug, text) {
  return http.post(`/users/${slug}/notes`, { body: text })
}

export function activities (username) {
  return http.get(`/users/${username}/activity`)
}
