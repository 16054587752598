import store from 'store'

const meta = {
  layout: 'login',
  public: true
}

export const email = {
  name: 'Reset Password',
  path: '/password/email',
  components: {
    default: () => import(/* webpackChunkName: "password" */ 'pages/password/email/index.vue')
  },
  meta
}

export const reset = {
  name: 'Reset Password with Token',
  path: '/reset-password/:token',
  components: {
    default: () => import(/* webpackChunkName: "password" */ 'pages/password/reset/index.vue')
  },
  meta,
  beforeEnter (to, from, next) {
    /**
     * Ensure user is logged out when hitting this route.
     */
    store.dispatch('logout', false)
    next()
  }
}

export default { email, reset }
