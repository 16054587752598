<template>
  <peek-form
    :title="
      $t('admin.peek_user_system_access_title', {
        user: user ? user.display_name : 'User'
      })
    "
    :footer-title="$t('concierge.some_info_will_be_visible')"
    :disable-call-to-action="disabled"
    @save="confirmation = true"
  >
    <q-page v-if="loading" padding class="row justify-center">
      <div class="column justify-center">
        <div class="row justify-center">
          <component :is="spinnerName" size="72px" :color="partner.hex" />
        </div>
        <div class="text-center q-my-sm">
          {{ loadingMsg || $t('loading.loading') }}
        </div>
      </div>
    </q-page>
    <q-page v-else padding>
      <m-group-title label="Permissions" />
      <q-card>
        <q-card-section>
          <attached-permissions
            v-model="permissions"
            @addition="addPermission"
            @removal="removePermission"
          />
        </q-card-section>
      </q-card>
      <m-group-title label="Roles" />
      <q-card>
        <q-card-section>
          <attached-roles
            v-model="roles"
            @addition="addRole"
            @removal="removeRole"
          />
        </q-card-section>
      </q-card>
    </q-page>
    <q-dialog v-model="confirmation">
      <q-card>
        <q-card-section>
          <div class="text-h6">
            {{ $t('confirm.title_manage_system_access') }}
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-body1">
            Additions
          </div>
          <div class="text-body2">
            Permissions ({{ additions.permissions.length }})
            <attached-permissions
              v-model="additions.permissions"
              dense
              readonly
            />
          </div>
          <div class="text-body2">
            Roles ({{ additions.roles.length }})
            <attached-roles v-model="additions.roles" dense readonly />
          </div>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="text-body1">
            Removals
          </div>
          <div class="text-body2">
            Permissions ({{ removals.permissions.length }})
            <attached-permissions
              v-model="removals.permissions"
              dense
              readonly
            />
          </div>
          <div class="text-body2">
            Roles ({{ removals.roles.length }})
            <attached-roles v-model="removals.roles" dense readonly />
          </div>
        </q-card-section>
        <q-card-section>
          <div class="text-caption text-italic">
            <strong>Please Note</strong>:
            {{ $t('admin.user_access_change_warning') }}
            <br>
            {{ $t('admin.user_access_total_changes', { n: totalCalls }) }}
          </div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            v-close-popup
            flat
            :label="$t('cancel')"
            color="primary"
            @click="loading = false"
          />
          <q-btn
            v-close-popup
            flat
            :label="$t('confirm.confirm')"
            color="primary"
            @click="submit"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </peek-form>
</template>

<script type="text/javascript">
import { get as getUserInfo } from 'api/user'
import {
  attachToUser as attachPermission,
  removeFromUser as removePermission
} from 'api/system/permissions'
import {
  attachToUser as attachRole,
  removeFromUser as removeRole
} from 'api/system/roles'
import { mapGetters } from 'vuex'
import peekForm from 'peeks/peekForm.vue'
import authentication from 'mixins/authentication'
import attachedPermissions from 'peeks/system-settings/roles/form/attachedPermissions.vue'
import attachedRoles from 'peeks/system-settings/permissions/form/attachedRoles.vue'
import { MSpinnerGeo, MSpinnerArrow, MSpinnerKinto, MGroupTitle } from 'components/'

export default {
  components: {
    peekForm,
    attachedPermissions,
    attachedRoles,
    MSpinnerGeo,
    MSpinnerArrow,
    MSpinnerKinto,
    MGroupTitle
  },
  mixins: [authentication],
  data () {
    return {
      confirmation: false,
      user: null,
      loading: false,
      loadingMsg: null,
      search: '',
      permissions: [],
      roles: [],
      additions: {
        roles: [],
        permissions: []
      },
      removals: {
        roles: [],
        permissions: []
      }
    }
  },
  computed: {
    ...mapGetters({
      authedUser: 'user',
      partner: 'partner'
    }),
    spinnerName () {
      const spinnerType = this.$store.getters.partner.spinnerType
      return `m-spinner-${spinnerType}`
    },
    disabled () {
      const hasAdditions =
        this.additions.roles.length || this.additions.permissions.length
      const hasRemovals =
        this.removals.roles.length || this.removals.permissions.length
      return !(hasAdditions || hasRemovals)
    },
    totalCalls () {
      const additions =
        this.additions.roles.length + this.additions.permissions.length
      const removals =
        this.removals.roles.length + this.removals.permissions.length
      return additions + removals
    }
  },
  beforeMount () {
    this.loading = true
    this.$store
      .dispatch('entities/users/setPeekUser', {
        username: this.$route.query?.username || this.authedUser.username
      })
      .then(user => {
        this.user = user
        this.loadUserAccess(user.username)
      })
  },
  methods: {
    loadUserAccess (username) {
      username = username || this.user.username
      getUserInfo({ username }, { include: ['permissions', 'roles'] }).then(
        res => {
          const perms = res.data.permissions.data.map(perm => perm.slug)
          const roles = res.data.roles.data.map(role => role.slug)

          this.permissions = perms
          this.roles = roles

          this.loading = false
        }
      )
    },
    addPermission (slug) {
      this.additions.permissions.push(slug)
    },
    removePermission (slug) {
      const additionIndex = this.additions.permissions.findIndex(
        perm => perm === slug
      )
      if (additionIndex > -1) { this.additions.permissions.splice(additionIndex, 1) } else this.removals.permissions.push(slug)
    },
    addRole (slug) {
      this.additions.roles.push(slug)
    },
    removeRole (slug) {
      const additionIndex = this.additions.roles.findIndex(
        role => role === slug
      )
      if (additionIndex > -1) this.additions.roles.splice(additionIndex, 1)
      else this.removals.roles.push(slug)
    },
    async submit () {
      const methods = ['additions', 'removals']
      const types = ['permissions', 'roles']
      const processes = {
        additionspermissions: attachPermission,
        additionsroles: attachRole,
        removalspermissions: removePermission,
        removalsroles: removeRole
      }
      this.loading = true
      this.loadingMsg = `Submitting changes 0/${this.totalCalls}`
      var completedJobs = 0
      await Promise.all(
        methods.map(async method => {
          await Promise.all(
            types.map(async type => {
              await Promise.all(
                this[method][type].map(async (slug, index) => {
                  await processes[method + type](this.user.username, slug).then(
                    () => {
                      completedJobs++
                      this.loadingMsg = `Submitting changes ${completedJobs}/${this.totalCalls}`
                    }
                  )
                  this[method][type].splice(index, 1)
                })
              )
            })
          )
        })
      )
      this.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-card, .m-group-title
  margin 0 0 16px !important
.q-toolbar
  border-top 1px solid #e0e0e0
</style>
