<template>
  <div>
    <q-list class="bg-white">
      <q-item>
        <q-item-section>
          {{ document.filename }}
        </q-item-section>
        <q-item-section side>
          {{ document.type }}
          <span class="text-body2 text-faded">{{ document.size }}</span>
        </q-item-section>
        <q-item-section side>
          <q-btn
            :label="$t('download')"
            outline
            text-color="primary"
            @click.stop="openURL(document.url)"
          />
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>
<script>
import { openURL } from 'quasar'
export default {
  props: ['document'],
  methods: {
    openURL (url) {
      openURL(url)
    }
  }
}
</script>
