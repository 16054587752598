import { hasRole } from 'utils/authentication'
import store from 'store/modules/ondemand'

const meta = {
  permission: 'feature.mfd1.263.ferry.flow'
}

const beforeEnter = (to, from, next) => {
  if (!store.state.stash.params) {
    return next({ name: 'ondemand-ferry' })
  } else {
    next()
  }
}

const form = {
  name: 'ondemand-ferry',
  path: '/on-demand/ferry',
  components: {
    default: () => import(/* webbpackChunkName: "ondemand-ferry" */ 'pages/ondemand/ferry/index.vue')
  },
  meta: {
    ...meta,
    label: 'Ferry: Search'
  }
}

const results = {
  name: 'ondemand-ferry-results',
  path: '/on-demand/ferry/results',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ferry" */ 'pages/ondemand/ferry/results.vue')
  },
  meta: {
    ...meta,
    label: 'Ferry: Results'
  },
  beforeEnter
}

const passengers = {
  name: 'ondemand-ferry-passengers',
  path: '/on-demand/ferry/passenger-details',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ferry" */ 'pages/ondemand/ferry/passenger-details.vue')
  },
  meta: {
    ...meta,
    label: 'Ferry: Passengers'
  },
  beforeEnter (to, from, next) {
    if (hasRole('guest')) return next({ name: 'guest-mode' })
    else if (!store.state.stash.params) return next({ name: 'ondemand-ferry' })
    else next()
  }
}

const summary = {
  name: 'ondemand-ferry-summary',
  path: '/on-demand/ferry/summary',
  components: {
    default: () => import(/* webpackChunkName: "ondemand-ferry" */ 'pages/ondemand/ferry/summary.vue')
  },
  meta: {
    ...meta,
    label: 'Ferry: Summary'
  },
  beforeEnter
}

export default { form, results, passengers, summary }
