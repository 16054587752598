import Vue from 'vue'
import _ from 'lodash'
import date from 'utils/date-time'
import { bays, availability, summary, amend } from 'api/carclub'
import { Notify } from 'quasar'
import i18n from 'i18n'

function deg2rad (degrees) {
  return degrees * (Math.PI / 180)
}

function radiusFromBounds (lat1, lon1, lat2, lon2) {
  const deltaLat = lat2 - lat1
  const deltaLon = lon2 - lon1
  const earthRadius = 3959 // in miles 6369087 in metres.
  const alpha = deltaLat / 2
  const beta = deltaLon / 2
  const a = Math.sin(deg2rad(alpha)) * Math.sin(deg2rad(alpha)) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(deg2rad(beta)) * Math.sin(deg2rad(beta))
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distance = earthRadius * c
  return distance.toFixed(2)
}

const initialState = {
  // Map view
  bays: {},
  selectedBay: null,
  searchCenter: null,
  mapBounds: null,

  // Booking
  searchLocation: null,
  searchStartDateTime: null,
  searchEndDateTime: null,
  requester: null,
  traveller: null,

  loading: false,
  availabilityResults: null,
  selectedVehicle: null,

  refinedStartDateTime: null,
  refinedEndDateTime: null,
  windowMinTime: null,
  windowMaxTime: null,

  summary: null,
  journey: {
    name: null,
    reference: null
  }
}

const state = _.cloneDeep(initialState)

const getters = {
  bays: state => {
    const bays = Object.values(state.bays)
    const filteredBays = bays.filter((b) => {
      return state.mapBounds[0].lat >= b.latitude && b.latitude >= state.mapBounds[1].lat && state.mapBounds[0].lng >= b.longitude && b.longitude >= state.mapBounds[1].lng
    })
    return filteredBays
  },
  searchCenter: state => state.searchCenter,
  selectedBay: state => state.selectedBay,

  // Booking
  searchLocation: state => state.searchLocation,
  searchStartDateTime: state => state.searchStartDateTime,
  searchEndDateTime: state => state.searchEndDateTime,
  requester: (state, getters, rootState, rootGetters) => state.requester || rootGetters.userLookup,
  traveller: (state, getters, rootState, rootGetters) => state.traveller || rootGetters.userLookup,
  reason_for: state => state.reason_for,
  labels: state => state.labels,

  availabilityResults: state => state.availabilityResults,
  selectedVehicle: state => state.selectedVehicle,

  refinedStartDateTime: state => state.refinedStartDateTime || state.searchStartDateTime,
  refinedEndDateTime: state => state.refinedEndDateTime || state.searchEndDateTime,

  searchParams: (state, getters) => {
    if (!state.searchStartDateTime || !state.searchEndDateTime || !state.searchLocation) return
    return {
      start: date.toCivilDateTime(state.searchStartDateTime),
      end: date.toCivilDateTime(state.searchEndDateTime),
      location: state.searchLocation.place_id || state.searchLocation.value,
      requester: getters.requester.value,
      user: getters.traveller.value
    }
  },

  loading: state => state.loading,

  summaryParams: state => {
    return {
      start: date.toCivilDateTime(state.refinedStartDateTime),
      end: date.toCivilDateTime(state.refinedEndDateTime)
    }
  },

  windowMinTime: state => state.windowMinTime,
  windowMaxTime: state => state.windowMaxTime,

  unavailableSlotsForSelection: state => {
    if (!state.selectedVehicle) return
    return state.selectedVehicle.availability.filter(pa => !pa.is_available)
  },
  notFullyAvailable (state, getters) {
    if (!state.selectedVehicle) return

    const unavailableSlotsInSelection = getters.unavailableSlotsForSelection.filter(s => {
      const slotDate = date.newDate(s.period_start.date)
      return slotDate >= getters.refinedStartDateTime && slotDate < getters.refinedEndDateTime
    })
    return Boolean(unavailableSlotsInSelection.length) || getters.refinedStartDateTime < date.newDate()
  },

  summary: state => state.summary,
  journey: state => state.journey

}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  loadBays ({ commit }, { latLng, bounds }) {
    commit('setValue', { prop: 'mapBounds', payload: bounds })
    const radius = bounds ? radiusFromBounds(bounds[0].lat, bounds[0].lng, bounds[1].lat, bounds[1].lng) : 2
    return bays({ latLng, radius })
      .then(res => {
        commit('loadBaysSuccess', res.data)
      })
  },
  setValue ({ commit }, { prop, payload }) {
    commit('setValue', { prop, payload })
  },
  setSelectedBay ({ commit }, bay) {
    commit('setValue', { prop: 'selectedBay', payload: bay })
    commit('setValue', { prop: 'searchCenter', payload: { lat: bay.latitude, lng: bay.longitude } })
  },

  // Booking
  setSearchLocation ({ commit }, location) {
    commit('setValue', { prop: 'searchLocation', payload: location })
  },
  setSearchStartDateTime ({ commit }, datetime) {
    commit('setValue', { prop: 'searchStartDateTime', payload: datetime })
  },
  setSearchEndDateTime ({ commit }, datetime) {
    commit('setValue', { prop: 'searchEndDateTime', payload: datetime })
  },
  setRequester ({ commit }, requester) {
    commit('setValue', { prop: 'requester', payload: requester })
  },
  setTraveller ({ commit }, traveller) {
    commit('setValue', { prop: 'traveller', payload: traveller })
  },

  setSelectedVehicle ({ commit }, vehicle) {
    commit('setValue', { prop: 'selectedVehicle', payload: vehicle })
  },
  setRefinedStartDateTime ({ commit }, datetime) {
    commit('setValue', { prop: 'refinedStartDateTime', payload: datetime })
  },
  setRefinedEndDateTime ({ commit }, datetime) {
    commit('setValue', { prop: 'refinedEndDateTime', payload: datetime })
  },
  async search ({ commit, getters, dispatch }) {
    commit('setValue', { prop: 'availabilityResults', payload: null })
    commit('setValue', { prop: 'loading', payload: true })

    const res = await availability(getters.searchParams)

    dispatch('setRefinedStartDateTime', getters.searchStartDateTime)
    dispatch('setRefinedEndDateTime', getters.searchEndDateTime)

    const availabilityList = res.data.vehicles[0].availability

    let minTimeFromQuery = date.subtractFromDate(date.roundMinutesDown(getters.searchStartDateTime, 60), { minutes: 120 })
    let minTimeFromResults = date.newDate(availabilityList[0].period_start.date)

    let maxTimeFromQuery = date.addToDate(date.roundMinutesUp(getters.searchEndDateTime, 60), { minutes: 120 })
    let maxTimeFromResults = date.newDate(availabilityList[availabilityList.length - 1].period_start.date)

    commit('setValue', { prop: 'windowMinTime', payload: minTimeFromQuery < minTimeFromResults ? minTimeFromResults : minTimeFromQuery })
    commit('setValue', { prop: 'windowMaxTime', payload: maxTimeFromQuery > maxTimeFromResults ? maxTimeFromResults : maxTimeFromQuery })
    commit('setValue', {
      prop: 'availabilityResults',
      payload: res.data.vehicles
    })

    if (getters.selectedVehicle) {
      const currentSelection = getters.availabilityResults.find(el => {
        return el.plate === getters.selectedVehicle.plate
      })
      dispatch('setSelectedVehicle', currentSelection)
    }

    commit('setValue', { prop: 'loading', payload: false })
  },
  async getSummary ({ commit, dispatch, getters }) {
    commit('setValue', { prop: 'summary', payload: null })
    try {
      const res = await summary(getters.selectedVehicle.token, getters.summaryParams)
      commit('setValue', { prop: 'summary', payload: res.data })
    } catch (err) {
      Notify.create(i18n.t('error.default'))
    }
  },
  async amend ({ commit }, { start, end, booking_ref }) {
    const { data } = await amend(start, end, booking_ref)
    return data
  },
  setJourney ({ commit }, payload) {
    commit('setValue', { prop: 'journey', payload })
  },
  setReason ({ commit }, payload) {
    commit('setValue', { prop: 'reason_for', payload })
  },
  setLabels ({ commit }, payload) {
    commit('setValue', { prop: 'labels', payload })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setValue (state, { prop, payload }) {
    Vue.set(state, prop, payload)
  },
  loadBaysSuccess (state, bays) {
    const indexed = _.keyBy(bays, m => m.description)
    state.bays = { ...state.bays, ...indexed }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
