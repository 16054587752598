import Vue from 'vue'
import App from './App.vue'
import plugins from './plugins'
import router from './router'
import store from './store'
import i18n from './i18n'

import './registerServiceWorker'

/**
 * Import global styles
 */
import 'styles/app.styl'
/**
 * Config our vue instance
 */
Vue.config.performance = process.env.VUE_APP_PERFORMANCE

/**
 * Install anything in ./plugins automatically
 * they should follow https://vuejs.org/v2/guide/plugins.html
 *
 * Everything should really be a plugin...
 */
plugins(
  [
    'google-analytics',
    'google-maps',
    'gmap-cluster',
    'quasar',
    'vuelidate',
    'particles',
    'native-store',
    'branding',
    'sentry'
  ],
  { App, router, store, Vue }
)

/**
 * Kicks off our Vue instance!
 */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#q-app')
