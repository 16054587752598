<template>
  <div>
    <q-card-section>
      <m-banner
        color="primary"
        :message="$t('bikehire.brompton.must_match_mobilleo')"
      />
    </q-card-section>
    <q-card-section>
      <q-input
        v-model="email"
        :label="$t('email_address')"
        :error="$v.email.$error"
        :error-message="$t('validation.email')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.email.$touch()"
      />
      <q-input
        v-model="mobile_number"
        :label="$t('mobile_number')"
        prefix="(44)"
        :error="$v.mobile_number.$error"
        :error-message="$t('bikehire.validation.mobile')"
        :rounded="$q.platform.is.mobile"
        :outlined="$q.platform.is.mobile"
        @blur="$v.mobile_number.$touch()"
      />
    </q-card-section>
    <q-card-section>
      <q-btn
        color="primary"
        label="Submit"
        :disabled="invalidForm"
        @click="submit"
      />
    </q-card-section>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import loading from 'utils/loading'
import { credentials } from 'api/bikehire'
import { mapGetters } from 'vuex'
import { handleErrors } from 'utils/utils'
import { MBanner } from 'components/'
import { nationalNumber } from 'utils/phone'

const mobileValidator = (num) => {
  const input = num.trim().split(' ').join('')
  const regex = /^7\d{9}$/
  return regex.test(input)
}

export default {
  name: 'BromptonLoginForm',
  components: { MBanner },
  props: ['user'],
  data () {
    return {
      email: null,
      mobile_number: null,
      supplier: 'brompton'
    }
  },
  validations: {
    email: { email, required },
    mobile_number: { mobileValidator, required }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    invalidForm () {
      return this.$v.$anyError
    }
  },
  beforeMount () {
    this.email = this.user.email
    this.mobile_number = nationalNumber(this.user.meta.mobile)
  },
  methods: {
    credentials,
    async submit () {
      loading.start({
        message: this.$t('loading.checking.your_details'),
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })

      try {
        const { username } = this.user
        const data = { supplier: 'brompton', phone_number: `0${this.mobile_number}` }

        await credentials(data)

        const member = await this.$store.dispatch('bikehire/setBikehireStatus', username)

        if (member.status === 'Active member') {
          this.$router.push({ name: 'ondemand-bikehire' })
        } else {
          this.$emit('complete')
        }
      } catch (err) {
        handleErrors(err)
      }
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
  @import '../../../../styles/ondemand.styl'
  .q-btn
    width 100%
</style>
