<template>
  <div class="help-grid">
    <div>{{ $t('travel_providers.need_help') }}</div>
    <q-btn
      type="a"
      class="full-width"
      icon="fas fa-phone-alt"
      :label="supportNumber"
      color="primary"
      :href="`tel:${supportNumber}`"
    />
    <q-btn
      class="full-width"
      icon="fas fa-info-circle"
      :label="$t('travel_providers.mango.visit_help')"
      color="primary"
      @click="openURL(links.help)"
    />
  </div>
</template>
<script>
import { openURL } from 'quasar'
export default {
  props: {
    supportNumber: {
      type: String,
      default: null
    },
    links: {
      type: Object,
      default: () => ({})
    }
  },
  methods: { openURL }
}
</script>
<style lang="stylus" scoped>

.help-grid
  display grid
  grid-template-columns 1fr
  grid-gap 0.5em
  align-items center
  padding 0 1em
  justify-content center
</style>
