import { hasRole } from 'utils/authentication'
import { getPartnerFromHostname } from 'utils/partner'

export default function (to, from, next) {
  // Check if the route is public
  const isPublicRoute = to.matched.some(record => record.meta.public)

  const isStaff = hasRole('middleoffice') || hasRole('backoffice') || hasRole('admin')

  const isTfwm = getPartnerFromHostname().slug === 'tfwm'

  // Check if the current route is already the holding page
  if (to.name === 'tfwm-holding') {
    next()
    return
  }
  if (!isPublicRoute && !isStaff && isTfwm) {
    next({ name: 'tfwm-holding' })
  } else {
    next()
  }
}
