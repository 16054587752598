import store from 'store/modules/smartsearch'
import i18n from 'i18n'

const beforeEnter = (to, from, next) => {
  !store.state.contents ? next({ name: 'smart-search' }) : next()
}

export const form = {
  name: 'smart-search',
  path: '/smart-search',
  meta: {
    label: i18n.t('smartsearch.smartsearch')
  },
  components: {
    default: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/index.vue')
  },
  props: { default: true }
}

export const results = {
  name: 'smart-search-results',
  path: '/smart-search/results',
  meta: {
    label: i18n.t('smartsearch.results')
  },
  components: {
    default: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/results/index.vue'),
    right: () => import(/* webpackChunkName: "smartsearch" */'pages/smart-search/sidebar/index.vue')
  },
  beforeEnter
}

export default { form, results }
