import http from './http'

/** Fetch all favourite addresses for logged-in user */
export function getUserFavouriteAddresses () {
  return http.get('/address/favourites')
}

/** Fetch a single favourite address */
export function getFavouriteAddress (id) {
  return http.get(`/address/favourites/${id}`)
}

/** Save a new favourite address */
export function saveFavouriteAddress (address) {
  return http.post('/address/favourites', address)
}

/** Remove a favourite address */
export function deleteFavouriteAddress (id) {
  return http.delete(`/address/favourites/${id}`)
}

/** Update a favourite address */
export function updateFavouriteAddress ({ id, ...address }) {
  return http.patch(`/address/favourites/${id}`, address)
}

export function search (query) {
  return http.get('/address/search', { params: { query } })
}

export function get (reference, query) {
  return http.get(`/address/search/${reference}`, { params: { query } })
}
