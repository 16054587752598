<template>
  <peek-form
    :title="$t('bikehire.membership_status')"
    :call-to-action="callToAction"
    :loading="loading"
    @save="submit"
  >
    <q-page padding>
      <m-group-title :label="$t(`bikehire.brompton.show['Brompton Membership']`)" />
      <q-card class="bg-white text-dark">
        <q-card-section>
          <q-banner v-if="memberStatusText" rounded class="status-text bg-warning">
            {{ memberStatusText }}
          </q-banner>
          <div class="status-details">
            <strong>{{ $t("member_status") }}:</strong>
            <div class="status-icon">
              {{ memberStatus.status }}
            </div>
            <strong>{{ $t("phone_number") }}:</strong> {{ memberStatus.phone_number }}
          </div>
          <q-banner
            rounded
            class="bg-primary"
          >
            <div class="status-card">
              {{ $t("for_queries_contact_logo") }}
              <img :src="logo" style="width:50%; margin:10px 0">
              {{ supportNumber }}
            </div>
          </q-banner>
        </q-card-section>
      </q-card>
    </q-page>
  </peek-form>
</template>

<script>
import { mapGetters } from 'vuex'
import { status } from 'api/bikehire'
import peekForm from 'peeks/peekForm.vue'
import { MGroupTitle } from 'components/index.js'
import logo from 'assets/bike-providers/brompton_logo.svg'

export default {
  name: 'BikehireMembershipPeek',
  components: {
    peekForm,
    MGroupTitle
  },
  data () {
    return {
      loading: false,
      memberStatus: {
        status: '',
        phone_number: ''
      },
      logo,
      supportNumber: this.$t('bikehire.brompton.support_number'),
      callToAction: this.$t('confirm.ok'),
      canSetDetails: ['Not registered', 'Missing phone number']
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    }),
    memberStatusText () {
      return this.$te(`brompton_peek["${this.memberStatus.status}"]`)
        ? this.$t(`brompton_peek["${this.memberStatus.status}"]`)
        : ''
    }
  },
  async beforeMount () {
    this.loading = true

    try {
      const member = await this.$store.dispatch('bikehire/setBikehireStatus', this.user.username)

      if (this.canSetDetails.includes(member.status)) {
        this.callToAction = this.$t('bikehire.add_details')
      }

      this.memberStatus = {
        status: member.status,
        phone_number: this.user.meta.bikehire?.brompton?.phone_number || this.$t('bikehire.no_phone_number')
      }
    } catch (err) {
      console.log(err)
    }

    this.loading = false
  },
  methods: {
    status,
    submit () {
      if (this.canSetDetails.includes(this.memberStatus.status)) {
        this.$router.push({ name: 'bikehire-login-brompton' })
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<style lang="stylus">
  .status-details
    display grid
    grid-template-rows auto
    grid-row-gap 5px
    grid-template-columns 125px auto
    align-items center

  .status-icon
    border-radius 5px
    text-transform uppercase
    font-weight 500
    font-size 90%
    width 100%

  .status-card
    display flex
    flex-direction column
    align-items center
    justify-content space-between
    font-size 125%
    text-align center

  .q-banner
    border-width 1px
    border-style solid
    margin 15px 0

  .status-text
    margin-top 0
</style>
