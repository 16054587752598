import store from 'store'
import { refresh } from 'api/login'

/**
 * If we have a token, we will refresh it no matter what to make sure
 * its still valid and ensure we can populate localstorage correclty.
 */
export default (to, from, next) => {
  if (store.getters.session.token && from.fullPath === '/') {
    refresh().then(response => {
      store.dispatch('saveAuthUser', response.data)
      next()
    }).catch(() => {
      store.dispatch('logout')
      next({ path: '/' })
    })
  } else {
    next()
  }
}
