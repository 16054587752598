import http from './http'
import processCsv from 'utils/process-csv'

export function index (params) {
  return http.get(`/organisations`, params)
}

export function get (slug, params) {
  return http.get(`/organisations/${slug}`, { params })
}

export function put (slug, params) {
  return http.put(`/organisations/${slug}`, params)
}

export function patch (slug, params) {
  return http.patch(`/organisations/${slug}`, params)
}

export function post (params) {
  return http.post(`/organisations`, params)
}

export function deleteOrganisation (slug) {
  return http.delete(`/organisations/${slug}`)
}

export function members (slug, params) {
  return http.get(`/organisations/${slug}/members`, { params })
}

export function addUserToOrganisation (slug, params) {
  return http.post(`/organisations/${slug}/members`, params)
}

export function bookings (slug, params) {
  params = {
    ...params
  }
  return http.get(`/organisations/${slug}/bookings`, { params })
}

export function notes (slug) {
  return http.get(`/organisations/${slug}/notes?orderBy=created_at:desc`)
}

export function addNote (slug, params) {
  return http.post(`/organisations/${slug}/notes`, params)
}

export function activities (slug, params) {
  return http.get(`/organisations/${slug}/activity`, { params })
}

export function invoices (slug, params) {
  return http.get(`/organisations/${slug}/invoices`, {
    params: {
      ...params
    }
  })
}

export function updatePricingSchedule (slug, schedule) {
  return http.post(`/organisations/${slug}/pricingschedule`, schedule)
}

export function toggleAccount (organisation, action) {
  return http.post(`/organisations/${organisation}/account/${action}`)
}

export function toggleAuthorisationRequired (organisation, enabled) {
  return http.patch(`/organisations/${organisation}`, {
    authorisation_enabled: enabled
  })
}

export function toggleAuthorisationBypass (organisation, role, enabled) {
  if (!['middleoffice', 'backoffice'].includes(role)) {
    throw new Error('Invalid role')
  }
  return http.patch(`/organisations/${organisation}`, {
    [`authorisation_${role}_bypass`]: enabled
  })
}

export function block (organisation) {
  return http.post(`/organisations/${organisation}/block`)
}

export function unblock (organisation) {
  return http.post(`/organisations/${organisation}/unblock`)
}

export function updateBrandingColor (slug, params) {
  return http.post(`/organisations/${slug}/brand`, params)
}

export function updateBrandingLogo (slug, params) {
  return http.post(`/organisations/${slug}/brand/logo`, params)
}

export function travelPolicy (slug) {
  return http.get(`/organisations/${slug}/contents`)
}

export function updateTravelPolicy (slug, contentType, policy) {
  return http.patch(`/organisations/${slug}/contents/${contentType}`, policy)
}

export function travelPolicyEnable (slug, contentType) {
  return http.post(`/organisations/${slug}/contents/${contentType}/enable`)
}

export function travelPolicyDisable (slug, contentType) {
  return http.post(`/organisations/${slug}/contents/${contentType}/disable`)
}

export function costCentres (slug) {
  return http.get(`/organisations/${slug}/cost-centres`)
}

export function businessAreas (slug) {
  return http.get(`/organisations/${slug}/business-areas`)
}

export function transportPolicy (slug) {
  return http.get(`/organisations/${slug}/transport-policy`)
}

export function setTransportPolicy (slug, params) {
  return http.post(`/organisations/${slug}/transport-policy`, params)
}

export function getContentApprovalList (slug) {
  return http.get(`/organisations/${slug}/content-approvals`)
}

export function enableContentApprovalType (slug, contentType) {
  return http.post(`/organisations/${slug}/content-approvals`, { content_slug: contentType })
}

export function disableContentApprovalType (slug, contentApprovalId) {
  return http.delete(`/organisations/${slug}/content-approvals/${contentApprovalId}`)
}

export function getClauses (slug) {
  return http.get(`/organisations/${slug}/policies/show/1`).then(res => res.data?.clauses)
}

export function setContentMinimumPriceThreshold (slug, contentType, price) {
  return http.post(`/organisations/${slug}/clauses/create`, {
    policy_id: 1, // no idea what this is, but the endpoint needs it
    content_slug: contentType,
    limit: price,
    action: 'authorisation',
    type: 'fixed'
  })
}

export function updateContentMinimumPriceThreshold (slug, clause_id, limit) {
  return http.put(`/organisations/${slug}/clauses/update`, {
    clause_id,
    limit
  })
}

export function removeContentMinimumPriceThreshold (slug, clauseId) {
  return http.delete(`/organisations/${slug}/clauses/${clauseId}`)
}

// Onward travel
export function getOnwardTravelConfig (slug) {
  return http.get(`/organisations/${slug}/onward-travel/config`)
}

export const updateOnwardTravelConfig = (slug, config) => {
  return http.post(`/organisations/${slug}/onward-travel/config`, config)
}

export function checkBusinessId (businessId) {
  return http.get(`/rental/${businessId}/check`)
}

// Ridehailing
export function getRidehailingConfig (slug) {
  return http.get(`/organisations/${slug}/ridehailing/config`)
}

export const updateRidehailingConfig = (slug, config) => {
  return http.post(`/organisations/${slug}/ridehailing/config`, config)
}

export function getOnwardTravelReports (slug, path) {
  return http.get(`/organisations/${slug}/onward-travel-reports`, { params: { path } })
}

export function onwardTravelReportDownload (slug, path) {
  return http.get(`/organisations/${slug}/onward-travel-reports/download`, { params: { path } }).then(csv => processCsv(csv, path))
}
