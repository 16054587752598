<template>
  <peek-form
    :title="$t('email_update_peek.title')"
    :subtitle="$t('email_update_peek.subtitle')"
    :call-to-action="$t('email_update_peek.cta')"
    :loading="loading"
    @save="resendVerification"
  >
    <p v-for="text in $t('email_update_peek.text')" :key="text">
      {{ text }}
    </p>
  </peek-form>
</template>

<script>
import peekForm from 'peeks/peekForm'
import { verify } from 'api/users'
import User from 'store/models/user'
import { handleErrors } from 'utils/utils'

export default {
  name: 'ChangeEmailPeek',
  components: { peekForm },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    async resendVerification () {
      this.loading = true
      try {
        let outstanding_email = this.$route.query.email

        if (!outstanding_email) {
          const username = this.$store.getters['entities/users/peekUser']
          const user = User.find(username)
          outstanding_email = user.outstanding_email
        }

        if (!outstanding_email) {
          throw new Error('No outstanding email')
        }

        await verify(outstanding_email)
        this.$q.notify({
          color: 'positive',
          message: this.$t('login.verification.email_sent') }
        )
      } catch (err) {
        handleErrors(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
p
  margin 24px 48px
  text-align center
  font-size 1.2rem
  &:first-of-type
    margin-block-start 48px
</style>
