/**
 * "What's a gun doing in your trousers?"
 * "It's for protection."
 * "Protection from what? Zee Germans?"
 *
 * The catch all should always be the last route, hence the z name...
 */
const catchAll = {
  name: 'catch-all',
  path: '*',
  components: {
    default: () => import(/* webpackChunkName: "catch-all" */'pages/404.vue')
  }
}

export default { catchAll }
