<template>
  <q-dialog ref="dialog" @hide="onDialogHide" @show="onShow">
    <q-card style="width: 700px; max-width: 80vw; height: 320px" class="relative-position">
      <q-card-section class="row items-center">
        <q-input
          v-model="label"
          :label="$tc('label.label')"
          class="full-width"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
      </q-card-section>
      <q-card-section class="row items-center">
        <q-spinner v-if="mode ==='edit' && loading" />
        <m-location-search
          v-else
          :value="address"
          :label="$tc('address')"
          no-saved
          class="full-width"
          @selected="onSelected"
        />
      </q-card-section>

      <q-card-actions align="right" class="absolute" style="bottom: 0">
        <q-btn flat :label="$t('cancel')" color="primary" @click="onCancelClick" />
        <q-btn flat :label="$t('save.address')" color="primary" :disable="!canSave" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { MLocationSearch } from 'components/'
import { details } from 'api/location'
import { getFavouriteAddress } from 'api/address'
import dialogueMixin from 'mixins/dialogue'
import { handleErrors } from 'utils/utils'
import { pickBy } from 'lodash'
export default {
  components: { MLocationSearch },
  mixins: [dialogueMixin],
  props: {
    open: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({})
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data () {
    return ({
      label: '',
      address: null,
      loading: true
    })
  },
  computed: {
    canSave () {
      return this.label.length > 0 && this.address
    }
  },
  methods: {
    async onShow () {
      if (this.value) {
        this.loading = true
        this.label = this.value.label || this.value.name
        const { data: address } = await getFavouriteAddress(this.value.id)
        this.address = { ...address, label: address.name }
      }
      this.loading = false
    },
    onOKClick () {
      if (this.mode === 'edit') {
        this.$store.dispatch('addresses/updateAddress', { id: this.value.id, ...this.address, label: this.label })
      } else {
        this.$store.dispatch('addresses/save', { ...this.address, label: this.label })
      }
      this.hide()
    },
    onDialogHide () {
      this.address = null
      this.label = ''
      this.loading = true
    },
    async onSelected (val) {
      try {
        const { data } = await details(val.value)
        const { address: { label, lat, lng, place_id }, text } = data
        const address = {
          label: label,
          name: label,
          formatted_address: text,
          place_id,
          coordinates: `${lat},${lng}`
        }
        if (!this.label) this.label = label
        this.address = pickBy(address)
      } catch (err) {
        handleErrors(err)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
// Allow list to overflow dialog box
.q-dialog > .q-dialog__inner--minimized > div
    overflow: initial
</style>
