<template>
  <peek-form :title="$t('mango.mango')" :footer="false">
    <div class="ticket-wrapper">
      <div class="ticket">
        <div v-if="loading" class="no qr-code" />
        <div v-else-if="qrCode" class="qr-code">
          <img :src="qrCode" alt="QR code">
        </div>
        <div v-else class="no qr-code">
          <div class="cross-wrapper">
            <div class="cross right" />
            <div class="cross left" />
          </div>
          <span class="text-h6">{{ $t('mango.out_of_juice') }}</span><br>
          {{ $t('mango.please_add_funds') }}
        </div>
        <div class="loading-bar-wrapper">
          <div v-if="qrCode" class="loading-bar" />
        </div>
        <div class="balance text-h6">
          <p class="text-body2 text-lowercase">
            {{ $t('mango.balance') }}
          </p>
          {{ $n(balance / 100, 'currency') }}
        </div>
        <mango-top-up class="top-up" :balance="balance / 100" />
      </div>

      <!-- These are the little circle cutouts on the border -->
      <div class="cutout left" />
      <div class="cutout right" />
    </div>
  </peek-form>
</template>

<script>
import { getMangoChargeAccount, requestQRCode, getQRCode } from 'api/prepay'
import peekForm from '../../peekForm'
import MangoTopUp from './top-up'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'

export default {
  name: 'MangoWallet',
  components: { peekForm, MangoTopUp },
  data () {
    return {
      balance: 0,
      loading: false,
      qrCode: '',
      qrTimer: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    deviceId () {
      return this.$store.getters['native/get']('deviceId')
    }
  },
  async mounted () {
    const partner = this.$store.getters.partner
    this.loading = true
    loading.start({
      partnerSlug: partner.slug,
      spinnerColor: partner.hex,
      message: this.$t('loading.checking.your_details')
    })
    try {
      const { data } = await getMangoChargeAccount(this.user.username)
      const { charge_account_balance, charge_account_settings_set } = data

      if (!charge_account_settings_set) {
        this.$router.push({ hash: '/mango/createCharge' })
      }

      this.balance = charge_account_balance?.amount

      if (this.balance >= 1) {
        await requestQRCode(this.user.username, this.deviceId)
        await this.updateQRCode()
      }
    } catch (err) {
      if (err.response?.status === 422) {
        this.$router.push({ hash: '/mango/createCharge' })
      } else {
        handleErrors(err)
      }
      if (err.data?.title === 'Failed to renew access token.') {
        this.$router.push({ hash: '/mango/signin' })
      }
    } finally {
      loading.stop()
      this.loading = false
    }
  },
  beforeDestroy () {
    clearInterval(this.qrTimer)
  },
  methods: {
    async updateQRCode () {
      // If they're no longer on the page, cancel the recursion
      if (!this.$route.hash.includes('mango/wallet')) return
      const loadingBar = document.getElementsByClassName('loading-bar')[0]
      if (loadingBar) loadingBar.style.animationPlayState = 'paused'
      try {
        const { data } = await getQRCode(this.user.username)
        this.qrCode = data.token_url
      } catch (err) {
        handleErrors(err)
      } finally {
        this.qrTimer = setTimeout(this.updateQRCode, 15000)
        if (loadingBar) loadingBar.style.animationPlayState = 'running'
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.ticket-wrapper
  background-color #ddd
  height: calc(100vh - 64px)
  padding 16px 24px

.ticket
  background-color white
  height 100%
  width 100%
  border-radius 10px
  padding 32px 24px
  padding-bottom 12px
  display flex
  flex-direction: column
  align-items center

.top-up
  flex-grow: 1

.cutout
  border-radius 50%
  height 40px
  width 40px
  background-color #ddd
  position absolute
  top 60%
  &.left
    left 4px
  &.right
    right 4px

.qr-code
  width: 100%;
  aspect-ratio: 1/1
  border-radius: 10px;
  img
    width 100%
    display block
  &.no
    margin-inline: auto;
    background-color: #eee;
    padding 16px 32px
    color black
    text-align: center

.loading-bar-wrapper
  width 80%
  border-radius: 15px
  background-color rgba(255,165,0,0.5)

.loading-bar
  background-color rgba(255,165,0,1)
  border-radius 15px
  height 6px
  width 100%
  animation load-down 15s linear infinite

@keyframes load-down {
  from {
    width 100%
  }
  to {
    width 0
  }
}

.cross-wrapper
  margin-inline auto
  margin-top 24px
  margin-bottom 24px
  position relative
  height 50%
  width 70%

.cross
  background-color red
  height 8px
  width 100%
  position absolute
  top 50%
  border-radius 10px
  &.right
    transform rotate(45deg)
  &.left
    transform rotate(135deg)

.balance
  text-align: center
  margin-block-start 24px
  margin-block-end 8px
</style>
