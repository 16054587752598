<template>
  <div class="q-pa-md">
    <div>
      {{ $t('travel_providers.mobilleo.provided_by_mobilleo') }}
    </div>
    <div class="content-grid">
      <div
        v-for="type in mobilleoContentTypes"
        :key="type.slug"
        class="content-type"
      >
        <div class="content-type-icon">
          <m-icons-avatar
            v-if="type.iconIsImage"
            :icon="type.icon"
            :is-image="type.iconIsImage"
            :color="type.iconIsImage ? null : type.color"
            :rounded="!type.iconIsImage"
            size="32px"
          />
          <m-travel-icon
            v-else
            :type="type.slug"
            color="white"
            :bg-color="getTravelContentHex(type.slug)"
            size="32px"
            circle
          />
        </div>
        <div class="content-type-text text-capitalize">
          {{ type.text || type.slug }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { MIconsAvatar, MTravelIcon } from 'components'
import travelContents from 'mixins/travelContents.js'
export default {
  components: { MIconsAvatar, MTravelIcon },
  mixins: [travelContents],
  computed: {
    mobilleoContentTypes () {
      let types = []
      Object.keys(this.travelContents).forEach(key => {
        const content = { slug: key, ...this.travelContents[key] }
        content.iconIsImage = false

        if (!content.icon && content.logo) {
          content.icon = content.logo
          content.iconIsImage = true
        }

        types.push(content)
      })

      const order = [ // ok this bit is really dumb, but gotta fit the design so
        'train',
        'rental',
        'stagecoach-bus',
        'flight',
        'bus',
        'hotel',
        'tram',
        'tube',
        'taxi'
      ]
      const filteredTypes = types.filter(content => content.provider === 'mobilleo') // Filter for content types provided by mobilleo
      let output = []
      order.forEach(orderType => {
        const type = filteredTypes.find(content => content.slug === orderType)
        if (type) {
          output.push(type)
        }
      })

      output.push(...filteredTypes.filter(content => !order.includes(content.slug))) // Add the rest of the content types, because we still wanna show em

      return output
    }
  }
}
</script>
<style lang="stylus" scoped>
.content-grid
  margin 1em 0
  display grid
  grid-template-columns auto auto
  grid-gap 0.5em
  align-items center
  justify-content space-around

  .content-type
    display grid
    grid-template-areas \
      'grid-type-icon grid-type-text'
    grid-auto-columns auto 1fr
    grid-gap 1em

    .content-type-icon
      grid-area grid-type-icon
      justify-self center
      align-self center

    .content-type-text
      grid-area grid-type-text
      justify-self start
      align-self center
</style>
