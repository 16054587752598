<template>
  <div>
    <q-list>
      <template v-for="(address, index) in addresses">
        <address-item
          :key="index"
          :address-id="address.id"
          :text="address.formatted_address"
          :label="address.label || address.name"
          :index="index"
          @edit="edit"
          @delete="deleteAddress"
        />
        <q-separator :key="index+'sep'" />
      </template>
      <q-item>
        <q-btn color="primary" @click="add">
          {{ $t('add') }}
        </q-btn>
      </q-item>
      <address-dialogue
        ref="dialogue"
        :mode="mode"
        :value="dialogueValue"
      />
    </q-list>
  </div>
</template>

<script>
import AddressItem from './item'
import AddressDialogue from './dialogue'

export default {
  components: {
    AddressItem,
    AddressDialogue
    // peekForm
  },
  props: {
    addresses: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      mode: 'add',
      searchResults: [],
      label: '',
      value: '',
      addressInput: '',
      selected: {
        label: '',
        reference: null
      },
      dialogueValue: null
    }
  },
  methods: {
    edit (index) {
      this.mode = 'edit'
      this.dialogueValue = this.addresses[index]
      this.$refs.dialogue.show()
    },
    deleteAddress (id) {
      this.$store.dispatch('addresses/deleteAddress', id)
    },
    add () {
      this.mode = 'add'
      this.dialogueValue = null
      this.$refs.dialogue.show()
    }

  }
}
</script>

<style lang="stylus">
  .search-results {

  }
</style>
