import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import VuexORM from '@vuex-orm/core'

import authentication from './modules/authentication'
import ui from './modules/ui'
import map from './modules/map'
import geolocate from './modules/geolocate'
import paypal from './modules/paypal'
import sagepay from './modules/sagepay'
import ondemand from './modules/ondemand'
import smartsearch from './modules/smartsearch'
import payment from './modules/payment'

import onwardtravel from './modules/onwardtravel'
import rideHailing from './modules/ride-hailing'
import addresses from './modules/addresses'

import members from './modules/users/members'
import individuals from './modules/users/individuals'
import backoffice from './modules/users/backoffice'
import organisations from './modules/organisations'
import requests from './modules/requests'

import carClub from './modules/carclub'
import bikehire from './modules/bikehire'
import bikeshare from './modules/bikeshare'

import finance from './modules/finance'

import discovery from './modules/discovery'

import weather from './modules/weather'

// Models/Modules
import Invoice from './models/invoice'
import invoices from './modules/invoices'

import Journey from './models/journey'
import journeys from './modules/journeys'

import Booking from './models/booking'
import bookings from './modules/bookings'

import Request from './models/request'
import newRequests from './modules/new-requests'

import User from './models/user'
import users from './modules/newUsers'

import Label from './models/label'
import labels from './modules/labels'

import Role from './models/role'
import roles from './modules/system/roles'
import Permission from './models/permission'
import permissions from './modules/system/permissions'

import partner from './modules/partner'

import publicTransport from './modules/publicTransport'

import native from './modules/system/native'

import mango from './modules/mango'

Vue.use(Vuex)

const database = new VuexORM.Database()
database.register(Invoice, invoices)
database.register(Journey, journeys)
database.register(Booking, bookings)
database.register(Request, newRequests)
database.register(User, users)
database.register(Label, labels)
database.register(Role, roles)
database.register(Permission, permissions)

const store = new Vuex.Store({
  modules: {
    authentication,
    ui,
    geolocate,
    map,
    smartsearch,
    paypal,
    sagepay,
    ondemand,
    carClub,
    bikehire,
    bikeshare,
    addresses,
    finance,
    payment,
    mango,

    discovery,

    weather,

    onwardtravel,
    rideHailing,

    members,
    individuals,
    backoffice,
    organisations,
    requests,

    partner,

    publicTransport,

    native
  },
  // strict: Vue.prototype.$env.NODE_ENV !== 'production',
  plugins: [
    VuexORM.install(database),
    createPersistedState({
      paths: [
        'weather.current',
        'weather.lastUpdated',
        'weather.location',
        'weather.coords',
        'authentication.permissions',
        'authentication.roles',
        'authentication.user',
        'authentication.organisation',
        'authentication.partner',
        'ui', 'locations', 'sagepay',
        'native'
      ]
    })
  ]
})

export default store
