export default {
  name: 'Heart',
  slug: 'heart',
  title: 'Heart Mobility',
  email: 'heart@mobilleo.com',
  brandingLogo: 'gohi-logo.svg',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['heart'],
  hex: '#9b1889',
  hasTravelType: false,
  hasReasonFor: false,
  spinnerType: 'arrow',
  appLinks: {
    android:
      'https://play.google.com/store/apps/details?id=com.mobilleo.android.gohi',
    ios: 'https://apps.apple.com/us/app/go-hi/id1567227109'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
  privacyURL: 'https://www.fodmobilitygroup.com/wp-content/uploads/2023/02/FOD-Mobility-Group-Privacy-Policy-23.pdf',
  pages: {
    login: {
      form: {
        class: 'text-dark',
        welcomeMessage: {
          class: 'text-h6 text-center text-weight-bold q-mt-md q-mb-xl',
          text: 'Welcome back, login'
        },
        email: {
          dark: false,
          color: 'primary',
          filled: false,
          class: 'col-12'
        },
        password: {
          dark: false,
          color: 'primary',
          filled: false,
          class: 'col-12'
        },
        signIn: {
          class: 'full-width q-my-md',
          color: 'primary',
          size: 'lg'
        },
        skipLogin: {
          class: 'skipLogin full-width q-my-md',
          color: 'primary',
          size: 'lg'
        },
        forgotPassword: {
          class: 'full-width q-my-md text-dark'
        },
        validation: {
          class: 'space row col text-center text-negative strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {
        },
        verificationButton: {
          class: 'q-my-md mr-auto',
          color: 'login-green',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'request-reset text-dark',
          welcomeMessage: {
            class: 'text-h6 text-center text-weight-bold q-mt-md q-mb-xl'
          },
          email: {
            dark: false,
            color: 'primary',
            filled: false
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'login-green'
          },
          backButton: {
            class: 'full-width q-my-md text-dark',
            unelevated: true,
            flat: true
          }
        }
      },
      reset: {
        form: {
          class: 'text-dark',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'primary',
            filled: false
          },
          password: {
            dark: false,
            color: 'primary',
            filled: false
          },
          passwordConfirmation: {
            dark: false,
            color: 'primary',
            filled: false
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'login-green',
            size: 'lg',
            align: 'center'
          }
        }
      }
    },
    signup: {
      complete: {
        form: {
          class: 'row text-dark',
          welcomeMessage: {
            class: 'col-12'
          },
          email: {
            dark: false,
            color: 'primary',
            filled: false
          },
          organisationName: {
            dark: false,
            color: 'primary',
            filled: false
          },
          name: {
            dark: false,
            color: 'primary',
            filled: false
          },
          password: {
            dark: false,
            color: 'primary',
            filled: false
          },
          passwordConfirmation: {
            dark: false,
            color: 'primary',
            filled: false
          },
          completeButton: {
            class: 'full-width',
            size: 'lg',
            color: 'primary',
            align: 'center'
          },
          skipLogin: {
            class: 'skipLogin full-width q-my-md',
            color: 'primary',
            size: 'lg'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: ''
      }
    },
    carclub: {
      button: {
        url:
          'https://www.enterprisecarclub.co.uk/gb/en/applicant/enrollment.html/contractName=GOHI/brand=enterprisecarclub/msg=Scotland/apptype=ind/ratePlanId=46/ratePlanName=Standard/promo=HITRANS#/home.html',
        color: 'primary'
      }
    }
  },
  components: {
    mGmaps: {
      markers: []
    },
    mImageModal: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'arrow',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {}
      }
    }
  },
  contents: {
    driving: { showInSmartSearch: true },
    bicycle: { showInSmartSearch: true },
    walking: { showInSmartSearch: true },
    bus: { showInSmartSearch: true },
    rail: { showInSmartSearch: true },
    bikehire: { showInSmartSearch: true },
    carclub: { showInSmartSearch: true },
    rental: { showInSmartSearch: true },
    ridehailing: { showInSmartSearch: true },
    flight: { showInSmartSearch: true }
  },
  mobility: [],
  bookables: [
    'bus',
    'ferry',
    'train',
    'flights',
    'rental',
    'carclub',
    'bikehire',
    'taxi',
    'hotel'
  ],
  nearby: [
    'parking',
    'gas_station',
    'ev_station',
    'atm',
    'car_wash',
    'car_repair',
    'restaurant',
    'bar',
    'cafe'
  ]
}
