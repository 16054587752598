<template>
  <q-dialog v-model="show">
    <q-card :style="{minWidth: '750px'}">
      <q-card-section>
        <m-group-title label="Confirmation" sublabel="Please confirm that the following values are correct." />
      </q-card-section>
      <m-group-title label="Invoices being credited" class="q-ml-md q-mt-md" />
      <q-card-section>
        <div v-for="(invoice, index) in invoicesToCredit" :key="`confirmInvoices${index}`" class="row" :class="{'q-mt-md': index > 0}">
          <div class="col">
            <q-item-label>
              {{ invoice.number }}
            </q-item-label>
            <q-item-label caption>
              Invoice Number
            </q-item-label>
          </div>
          <div class="col">
            <q-item-label>
              {{ invoice.raised_at.date | date }}
            </q-item-label>
            <q-item-label caption>
              Invoice Date
            </q-item-label>
          </div>
          <div class="col">
            <q-item-label>
              {{ invoice.gross_amount.text }}
            </q-item-label>
            <q-item-label caption>
              Invoice Gross amount
            </q-item-label>
          </div>
        </div>
      </q-card-section>
      <m-group-title label="Credit lines" class="q-ml-md q-mt-md" />
      <q-card-section>
        <div v-for="(line, index) in salesLines" :key="`confirmSalesLine${index}`" class="q-pt-sm">
          <div class="row">
            <div class="col-2">
              <q-item-label>
                {{ line.quantity }}
              </q-item-label>
              <q-item-label caption>
                Quantity
              </q-item-label>
            </div>
            <div class="col-4">
              <q-item-label>
                {{ line.description }}
              </q-item-label>
              <q-item-label caption>
                Description
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label>
                {{ formatMoney(line.unitPrice) }}
              </q-item-label>
              <q-item-label caption>
                Unit Price
              </q-item-label>
            </div>
            <div class="col">
              <q-item-label>
                {{ vatRates[line.taxRate.value] * 100 }}%
              </q-item-label>
              <q-item-label caption>
                Tax Rate
              </q-item-label>
              <q-tooltip v-if="line.unitPriceIncludesTax">
                Credit amount is inclusive of tax
              </q-tooltip>
            </div>
            <div class="col-3">
              <q-item-label>
                {{ $n(calculateGross(line) / 100, 'currency') }}
              </q-item-label>
              <q-item-label caption>
                Gross credit amount
              </q-item-label>
            </div>
          </div>
          <q-separator v-if="(index+1) === salesLines.length && salesLines.length > 1" class="q-my-sm" />
        </div>
        <div v-if="salesLines.length > 1" class="row">
          <div class="col offset-9">
            <q-item-label>
              {{ totalCreditAmount }}
            </q-item-label>
            <q-item-label caption>
              Total Credit
            </q-item-label>
          </div>
        </div>
      </q-card-section>
      <m-group-title label="Reason for credit" class="q-ml-md q-mt-md" />
      <q-card-section>
        <q-input
          v-model="reason"
          placeholder="Please provide a reason for the credit"
          :input-style="{ minHeight: '100px' }"
          :maxlength="255"
          bottom-slots
          filled
          autogrow
          square
        >
          <template v-slot:hint>
            max character {{ reason ? reason.length : 0 }} / 255 ({{ reason? 255 - reason.length : 255 }} characters left)
          </template>
        </q-input>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right">
        <q-btn label="Confirm" color="green" size="17.5px" :disable="disableConfirm" @click="createCreditInvoice" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script>
import date from 'utils/date-time'
import loading from 'utils/loading'
import { createCreditInvoice as createInvoice } from 'api/invoices'
import creditTaxCalculations from 'mixins/creditTaxCalculations.js'
import { mapGetters } from 'vuex'
import { MGroupTitle } from 'components/'

export default {
  components: { MGroupTitle },
  filters: {
    date (data) {
      return data ? date.toShortDate(data) : null
    }
  },
  mixins: [creditTaxCalculations],
  props: [ 'invoicesToCredit', 'salesLines', 'showDialog' ],
  data () {
    return {
      reason: ''
    }
  },
  computed: {
    ...mapGetters({
      partner: 'partner'
    }),
    show: {
      get () {
        return this.showDialog
      },
      set (val) {
        if (val === false) this.$emit('closeDialog')
      }
    },
    disableConfirm () {
      return !(this.reason && this.reason.length >= 3)
    },
    totalCreditAmount () {
      let total = 0
      this.salesLines.forEach((line) => {
        total += this.calculateGross(line)
      })
      return this.$n(total / 100, 'currency')
    },
    invoiceNumbers () {
      var invoices = []
      this.invoicesToCredit.forEach((i) => {
        invoices.push(i.$id)
      })
      return invoices
    }
  },
  methods: {
    createCreditInvoice () {
      loading.start({
        message: 'Creating credit invoice...',
        partnerSlug: this.partner.slug,
        spinnerColor: this.partner.hex
      })
      createInvoice({
        invoice: this.invoiceNumbers,
        reason_for: this.reason,
        items: this.salesLines.map((line) => {
          return {
            description: line.description,
            quantity: line.quantity,
            currency: 'GBP',
            'tax_rate': line.taxRate.value,
            'unit_price': line.unitPrice,
            'unit_price_includes_tax': line.unitPriceIncludesTax,
            'is_mobilleo_fee': false
          }
        })
      }).then(response => {
        loading.stop()
        this.$q.notify({
          message: `Credit invoice ${response.data.reference} created`,
          color: 'positive',
          icon: 'mdi-check'
        })
        this.$store.dispatch('finance/clearInvoicesToCredit')
        this.$router.push({ path: '/bookings', hash: '/booking/invoices', query: { booking: response.data.booking_references[0] } }, () => {
          setTimeout(() => {
            this.$router.push({ path: '/invoices' })
          }, 500)
        })
      }).catch(error => {
        loading.stop()
        this.$q.notify({
          message: 'Unable to create credit invoice: ' + error.data.message,
          color: 'negative'
        })
      })
    }
  }
}
</script>
