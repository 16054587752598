import { Model } from '@vuex-orm/core'

export default class Journey extends Model {
  static entity = 'journeys'

  static primaryKey = 'reference'

  static fields () {
    return {
      links: this.attr({}),
      date: this.attr({}),
      name: this.string(''),
      reference: this.string(''),
      items: this.attr({}),
      user: this.attr({}),
      traveller: this.attr({}),
      organisation: this.attr({}),
      type: this.string(''),
      labels: this.attr([]),
      city: this.string('')
    }
  }
}
