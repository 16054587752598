export default {
  name: 'MoveZero',
  slug: 'movezero',
  title: 'MoveZero',
  email: 'res@mobilleo.com',
  brandingLogo: 'MoveZero Logo.svg',
  brandingIcon: '',
  token: JSON.parse(process.env.VUE_APP_SWITCH_URLS)['movezero'],
  hex: '#FFDA40',
  hasTravelType: true,
  hasReasonFor: true,
  spinnerType: 'geo',
  appLinks: {
    android: 'https://play.google.com/store/apps/details?id=com.mobilleo.android',
    ios: 'https://itunes.apple.com/gb/app/mobilleo/id1249273159?mt=8'
  },
  termsURL: 'https://mobilleo.s3.eu-west-2.amazonaws.com/terms.pdf',
  privacyURL: 'https://www.fodmobilitygroup.com/wp-content/uploads/2023/02/FOD-Mobility-Group-Privacy-Policy-23.pdf',
  pages: {
    login: {
      form: {
        class: 'row text-dark',
        welcomeMessage: {
          class: 'col-12 text-h6 text-center text-weight-bold q-mt-md q-mb-xl',
          text: 'Welcome to Movezero!'
        },
        email: {
          dark: false,
          color: 'black',
          filled: true,
          class: 'col-12'
        },
        password: {
          dark: false,
          color: 'black',
          filled: true,
          class: 'col-12'
        },
        signIn: {
          class: 'full-width q-my-md',
          color: 'dark',
          size: 'lg'
        },
        skipLogin: {
          class: 'skipLogin full-width q-mb-md',
          color: 'dark',
          size: 'md'
        },
        forgotPassword: {
          class: 'full-width q-my-md text-dark'
        },
        validation: {
          class: 'space row col text-center text-negative strong'
        },
        createButton: {
          class: 'full-width q-my-md',
          color: 'login-green',
          size: 'lg'
        },
        completeButton: {},
        verificationButton: {
          class: 'q-my-md mr-auto',
          color: 'login-green',
          align: 'center'
        }
      }
    },
    password: {
      email: {
        form: {
          class: 'request-reset text-dark',
          welcomeMessage: {
            class: 'text-h6 text-center text-weight-bold q-mt-md q-mb-xl'
          },
          email: {
            dark: false,
            color: 'dark',
            filled: true
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'dark'
          },
          backButton: {
            class: 'full-width q-my-md text-dark',
            unelevated: true,
            flat: true
          }
        }
      },
      reset: {
        form: {
          class: 'text-dark',
          welcomeMessage: {},
          email: {
            dark: false,
            color: 'dark',
            filled: true
          },
          password: {
            dark: false,
            color: 'dark',
            filled: true
          },
          passwordConfirmation: {
            dark: false,
            color: 'dark',
            filled: true
          },
          resetButton: {
            class: 'full-width q-my-md',
            color: 'dark',
            size: 'lg',
            align: 'center'
          }
        }
      }
    },
    signup: {
      complete: {
        form: {
          class: 'row text-dark',
          welcomeMessage: {
            class: 'col-12'
          },
          email: {
            dark: false,
            color: 'dark',
            filled: true,
            class: 'full-width'
          },
          organisationName: {
            dark: false,
            color: 'dark',
            filled: true,
            class: 'full-width'
          },
          name: {
            dark: false,
            color: 'dark',
            filled: true,
            class: 'full-width'
          },
          password: {
            dark: false,
            color: 'dark',
            filled: true,
            class: 'full-width'
          },
          passwordConfirmation: {
            dark: false,
            color: 'dark',
            filled: true,
            class: 'full-width'
          },
          completeButton: {
            class: 'full-width',
            size: 'lg',
            color: 'dark'
          },
          skipLogin: {
            class: 'skipLogin full-width q-my-md',
            color: 'dark',
            size: 'md'
          }
        }
      }
    },
    verification: {
      submitButton: {
        class: ''
      }
    }
  },
  components: {
    mGmaps: {
      markers: []
    },
    mImageModal: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobilityNearbyList: {
      spinnerType: 'geo',
      spinnerClass: 'loader'
    },
    mMobileAppbar: {
      toolbarTitle: {
        img: {}
      }
    }
  },
  contents: {
    driving: { showInSmartSearch: true },
    bicycle: { showInSmartSearch: true },
    walking: { showInSmartSearch: true },
    bus: { showInSmartSearch: true },
    tram: { showInSmartSearch: true },
    subway: { showInSmartSearch: true },
    rail: { showInSmartSearch: true },
    bikehire: { showInSmartSearch: true },
    carclub: { showInSmartSearch: true },
    rental: { showInSmartSearch: true },
    ridehailing: { showInSmartSearch: true },
    flight: { showInSmartSearch: true },
    hotel: { showInSmartSearch: true }
  },
  mobility: [
    'e-scooter'
  ],
  bookables: [
    'onwardtravel',
    'bus',
    'tram',
    'train',
    'bikehire',
    'carclub',
    'taxi',
    'rental',
    'flight',
    'hotel'
  ],
  nearby: [
    'parking',
    'gas_station',
    'ev_station',
    'atm',
    'car_wash',
    'car_repair',
    'restaurant',
    'bar',
    'cafe'
  ]
}
