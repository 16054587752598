import { index, get, put, post, block, unblock, resetPasswordEmail, overview } from 'api/members'
import { attachToUser as attachRole, removeFromUser as removeRole } from 'api/system/roles'
import { setPaymentsEnabled } from 'api/users'
import _ from 'lodash'
import selects from './membersSelects'
import User from 'store/models/user'
import { Notify } from 'quasar'
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'

const initialState = {
  selectedUserId: null,
  index: {},
  selects
}

const state = _.cloneDeep(initialState)

const getters = {
  getUser: state => id => {
    return _.cloneDeep(state.index[id])
  },
  getSelectedUser: state => {
    return _.cloneDeep((state.index[state.selectedUserId]))
  },
  getSelect: state => id => {
    return _.cloneDeep(id ? state.selects[id] : state.selects)
  }
}

const actions = {
  reset ({ commit }) {
    commit('reset')
  },
  setSelectedUserId ({ commit }, userId) {
    commit('setSelectedUserId', userId)
  },
  loadUsersOverview ({ commit }, { orgId, params }) {
    return overview(orgId, params)
      .then(members => {
        commit('loadUsersSuccess', members.data)
        return members
      })
      .catch(e => {
        commit('loadUsersFailure', e)
      })
  },
  loadUsers ({ commit }, { orgId, params }) {
    return index(orgId, params)
      .then(members => {
        commit('loadUsersSuccess', members.data)
        return members
      })
      .catch(e => {
        commit('loadUsersFailure', e)
      })
  },
  loadUser ({ commit }, { orgId, username, params }) {
    return get(orgId, username, params)
      .then(member => {
        commit('loadUserSuccess', member.data)
      })
      .catch(e => {
        commit('loadUserFailure', e)
      })
  },
  inviteUser ({ commit }, { orgId, user }) {
    return post(orgId, user)
  },
  updateUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return put(orgId, username, { email: user.email, ...user.attributes })
      .then(member => {
        commit('updateUserSuccess', member.data)
      })
      .catch(e => {
        commit('updateUserFailure', e)
      })
  },
  blockUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return block(orgId, username)
      .then(() => {
        Notify.create({
          message: i18n.t('user.blocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('blockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: e.data.title,
          color: 'negative',
          icon: 'clear'
        })
        commit('blockUserFailure', e)
      })
  },
  unblockUser ({ commit }, { user }) {
    const orgId = user.organisation.slug
    const username = user.username
    return unblock(orgId, username)
      .then(() => {
        Notify.create({
          message: i18n.t('user.unblocked'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('unblockUserSuccess', username)
      })
      .catch(e => {
        Notify.create({
          message: i18n.t('error.user.not_unblocked'),
          color: 'negative',
          icon: 'clear'
        })
        commit('unblockUserFailure', e)
      })
  },
  resetUserPassword ({ commit }, { user }) {
    resetPasswordEmail(user.email)
      .then(() => {
        Notify.create({
          message: i18n.t('reset_password_email_sent'),
          color: 'positive',
          icon: 'mdi-check'
        })
        commit('resetUserPasswordSuccess', user)
      })
      .catch(e => {
        handleErrors(e, i18n.t('error.password_reset_not_sent'))
      })
  },
  changeRole ({ commit, getters }, { user, role }) {
    let currentRoles = user.roles || []
    let rolesToAdd = ['member'] // Default to always having member role
    let rolesToRemove = []
    let shouldPaymentsBeEnabled = true

    // TODO: Consider whether this is worth swapping out with an array/map. - Luna N
    switch (role) {
    case 'middleoffice_outsource':
      rolesToAdd = rolesToAdd.concat(['middleoffice.outsource', 'middleoffice', 'selfbooker'])
      shouldPaymentsBeEnabled = true
      break
    case 'middleoffice':
      rolesToRemove.push('middleoffice.outsource')
      rolesToAdd = rolesToAdd.concat(['middleoffice', 'selfbooker'])
      shouldPaymentsBeEnabled = true
      break
    case 'member':
      rolesToRemove = rolesToRemove.concat(['middleoffice.outsource', 'middleoffice'])
      rolesToAdd.push('selfbooker')
      shouldPaymentsBeEnabled = true
      break
    case 'read-only':
      rolesToRemove = rolesToRemove.concat(['middleoffice.outsource', 'middleoffice', 'selfbooker'])
      shouldPaymentsBeEnabled = false
      break
    }

    return new Promise(async (resolve, reject) => {
      if (rolesToRemove.length) {
        await rolesToRemove.reduce(async (promise, role) => {
          await promise
          if (!currentRoles.includes(role)) return

          await removeRole(user.username, role).catch(reject).then(() => {
            currentRoles = currentRoles.filter(r => r !== role)
          })
        }, undefined)
      }

      if (rolesToAdd.length) {
        await rolesToAdd.reduce(async (promise, role) => {
          await promise
          if (currentRoles.includes(role)) return

          await attachRole(user.username, role).catch(reject).then(() => {
            currentRoles.push(role)
          })
        }, undefined)
      }

      await setPaymentsEnabled(user.username, shouldPaymentsBeEnabled)

      resolve({
        newRole: role,
        rolesArray: user?.roles ? currentRoles : [] // If user roles attribute is not set, don't update roles
      })
    })
  }
}

const mutations = {
  reset (state) {
    for (var prop in state) {
      state[prop] = initialState[prop]
    }
  },
  setSelectedUserId (state, id) {
    state.selectedUserId = id
  },

  loadUsersSuccess (state, members) {
    const indexed = _.keyBy(members, m => m.username)
    User.insert({ data: members.map((m) => {
      return {
        ...m,
        meta: m.attributes
      }
    }) })
    state.index = { ...state.index, ...indexed }
  },
  loadUsersFailure (state, error) {
    throw Error(error)
  },

  loadUserSuccess (state, member) {
    state.index = { ...state.index, [member.username]: member }
  },
  loadUserFailure (state, error) {
    throw Error(error)
  },

  updateUserSuccess (state, member) {
    state.index = { ...state.index, [member.username]: member }
  },
  updateUserFailure (state, error) {
    throw Error(error)
  },

  blockUserSuccess (state, username) {
    const blockedUser = { ...state.index[username], is_blocked: true }
    state.index = { ...state.index, [blockedUser.username]: blockedUser }
  },
  blockUserFailure (state, error) {
    throw Error(error)
  },

  unblockUserSuccess (state, username) {
    const unblockedUser = { ...state.index[username], is_blocked: false }
    state.index = { ...state.index, [unblockedUser.username]: unblockedUser }
  },
  unblockUserFailure (state, error) {
    throw Error(error)
  },

  resetUserPasswordSuccess (state, user) {
    return user
  },

  changeRoleSuccess (state, { user, role }) {
    const u = { ...state.index[user], roles: [role] }
    state.index = { ...state.index, [user]: u }
  },
  changeRoleFailure (state, error) {
    throw Error(error)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
