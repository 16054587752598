import partners from 'mixins/partners'

const partnerHostnames = JSON.parse(process.env.VUE_APP_PARTNER_HOSTNAMES)
const partnersMap = new Map(); Object.keys(partners).forEach(k => { partnersMap.set(partnerHostnames[partners[k].slug], k) })

const partnerEnvs = JSON.parse(process.env.VUE_APP_SWITCH_URLS)
const partnersEnvMap = new Map(); Object.keys(partners).forEach(k => { partnersEnvMap.set(partnerEnvs[partners[k].slug], k) })

const getPartnerFromHostname = () => partners[partnersMap.get(window.location.host) || 'mobilleo']
const getPartnerFromEnv = (token) => partners[partnersEnvMap.get(token) || 'mobilleo']
const getPartnerFromLabel = (label) => partners[label]

export default getPartnerFromHostname
export { getPartnerFromHostname, getPartnerFromEnv, getPartnerFromLabel }
