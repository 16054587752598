import _ from 'lodash'
import { Screen, colors } from 'quasar'
import { hasRole } from 'utils/authentication'

// initial state
const initial = {
  quasar: {
    view: 'hHh lpr fFf',
    mini: {
      mode: false,
      width: 60
    },
    sides: {
      left: true,
      right: false
    },
    breakpoints: {
      left: 1200,
      right: 0
    },
    footer: {
      class: {
        'no-shadow': true
      },
      style: {
        zIndex: 0
      }
    }
  },
  branding: null,
  sideAddress: false
}

const state = _.cloneDeep(initial)

// getters
const getters = {
  ui: (state) => state
}

// actions
const actions = {
  toggleSidebar ({ commit }) {
    commit('toggleSidebar')
  },
  setSidebar ({ commit }, open) {
    commit('setSidebar', open)
  },
  resetUI ({ commit }) {
    commit('reset')
  },
  toggleRightSidebar ({ commit }) {
    commit('toggleRightSidebar')
  },
  showSideAddress ({ commit }) {
    commit('showSideAddress')
  },
  hideSideAddress ({ commit }) {
    commit('hideSideAddress')
  },
  setBrand ({ commit }, color) {
    commit('setBrand', color)
  }
}

// mutations
const mutations = {
  toggleSidebar (state) {
    if (Screen.lt.xl || !hasRole('backoffice')) {
      state.quasar.sides.left = !state.quasar.sides.left
      state.quasar.mini.mode = false
    } else {
      state.quasar.sides.left = true
      state.quasar.mini.mode = !state.quasar.mini.mode
    }
  },
  setSidebar (state, open) {
    state.quasar.sides.left = open
  },
  toggleRightSidebar (state) {
    state.quasar.sides.right = !state.quasar.sides.right
  },
  showSideAddress (state) {
    state.sideAddress = true
  },
  hideSideAddress (state) {
    state.sideAddress = false
  },
  reset (state) {
    for (let f in state) {
      state[f] = _.cloneDeep(initial[f])
    }
  },
  setBrand (state, color) {
    state.branding = color
    colors.setBrand('primary', color)
    colors.setBrand('secondary', colors.lighten(color, -10))
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
