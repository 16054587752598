<template>
  <svg
    :width="size"
    :height="size"
    :class="classes"
    class="q-spinner q-spinner-geo"
    viewBox="0 0 148 148"
  >
    <use :xlink:href="require('assets/mobilleo-icon-blue.svg') + '#mobilleo-icon-blue'" />
  </svg>
</template>

<script>
export default {
  name: 'MSpinnerGeo',
  props: {
    color: String,
    size: {
      type: [Number, String],
      default: '16px'
    }
  },
  computed: {
    classes () {
      if (this.color) {
        return `text-${this.color}`
      }
      return 'text-white'
    }
  }
}
</script>

<style lang="stylus" scoped>
.q-spinner-geo
  animation q-spin 2s linear infinite
</style>
