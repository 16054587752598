/**
 * Load a Javascript file dynamically via a Promise
 * @param {*} src
 */

const cache = {}

export default function loadScript (src, onComplete) {
  if (cache[src]) {
    if (onComplete) onComplete()
    return Promise.resolve()
  }

  return Promise.all(
    new Promise((resolve, reject) => {
      var head = document.head || document.getElementsByTagName('head')[0]
      const script = document.createElement('script')
      script.async = true
      script.src = src
      script.charset = 'utf8'

      head.appendChild(script)
      cache[src] = true
      script.onload = () => {
        if (onComplete) onComplete()
        resolve(src)
      }
      script.onerror = () => {
        reject(src)
      }
    }).catch(() => console.error(`Unable to load ${src}`))
  ).catch(() => {
    /** Empty catch to get ride of console.warnings */
  })
}
