import http from './http'

export function locations (params) {
  const location = params.value
  const latitude = location.split(',')[0]
  const longitude = location.split(',')[1]
  return http.get(`/bikehire/locations?latitude=${latitude}&longitude=${longitude}`)
}

export function availability (params) {
  return http.get(`/search/bikehire/availability`, { params })
}

export function summary (token) {
  return http.get(`/search/bikehire/summary/${token}`)
}

export function signup (data) {
  return http.post('/bikehire/register', data)
}

export function status (supplier, userId) {
  return http.get(`/bikehire/${supplier}/status/${userId}`)
}

export function credentials (data) {
  return http.post(`/bikehire/credentials`, data)
}

export function returnSearch (params, bookingRef) {
  return http.get(`/bikehire/return/${bookingRef}`, { params })
}

export function returnSummary (token) {
  return http.get(`/bikehire/return/summary/${token}`)
}

export function bookReturn (token) {
  return http.post(`/bikehire/return/${token}`)
}
