import http from './http'

export function search (params) {
  return http.get(`/search/bus`, { params })
}

export function summary (token, params) {
  return http.post(`/bus/summary/${token}`, params)
}

export function timetable (code) {
  return http.get(`/bus/stop/${code}/timetable`)
}

export function tickets (operator = 'stagecoach') {
  return http.get(`/bus/ticket-types?operator=${operator}`)
}
