<template>
  <div v-if="!active" class="register-grid">
    <div class="signin_text">
      {{ $t('travel_providers.signin_prompt') }}
    </div>
    <div class="signin_button">
      <q-btn :label="$t('travel_providers.signin_action')" color="primary" @click="$router.push({ hash: '/user-settings/carclub' })" />
    </div>
    <div class="join_text">
      {{ $t('travel_providers.join_prompt') }}
    </div>
    <div class="join_button">
      <q-btn :label="$t('travel_providers.join_action')" color="primary" @click="openURL(links.signup)" />
    </div>
  </div>
  <div v-else class="delete-grid">
    <div class="signin_text">
      {{ $t('travel_providers.change_details') }}
    </div>
    <div class="signin_button">
      <q-btn :disable="loading" :label="$t('travel_providers.change_details_action')" color="primary" @click="deleteCredentials" />
    </div>
  </div>
</template>
<script>
import { openURL } from 'quasar'
import { mapGetters } from 'vuex'
export default {
  props: {
    links: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      userLookup: 'userLookup'
    }),
    active () {
      return this.userLookup.has_carclub
    }
  },
  methods: {
    openURL,
    deleteCredentials () {
      this.loading = true
      this.$store.dispatch('deleteCarClubCredentials')
        .then(() => {
          this.$q.notify({
            message: this.$t('carclub.credentials.removed'),
            timeout: 1500,
            type: 'positive',
            icon: 'check',
            position: 'top-right'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="stylus" scoped>
.register-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr 1fr

  .q-btn
    width 100%

.delete-grid
  display grid
  grid-gap 0.5em
  align-items center
  padding 0 1em
  grid-template-columns 1fr

  .q-btn
    width 100%
</style>
