import { DateTime } from 'luxon'
import i18n from 'i18n'
//
// --
// ----
//      WE HAVE THIS FUNCTION BECAUSE THE API DATE FORMAT DOES NOT WORK WITH JS DATE IN SAFARI.
//      DO NOT USE ANY DATE FUNCTIONS DIRECTLY FROM QUASAR OR ANYWHERE IF THEY DONT HANDLE THE SAFARI BUG.
//      THE BUG IS THAT THE YYYY-MM-DD HH:MM:SS FORMAT DOES NOT WORK WITH SAFARI, IT REQUIRES A T INSTEAD
//      OF A SPACE.
//      https://stackoverflow.com/questions/21883699/safari-javascript-date-nan-issue-yyyy-mm-dd-hhmmss
// ----
// --
//

// Lessons in time
// Luxon and JSDate have different month offsets (1 vs 0) so thats why there are '-1' in this file

// The codebase is currently going through a standarisation process whereby we are isolating our dependence on JSDate
// so that we don't end up with incorrect dates because of local offsets.

function createFromJS (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  const luxonDate = DateTime.fromObject({ year, month, day, hour, minute })
  return luxonDate
}

function createDate (value) {
  if (value instanceof DateTime) {
    return value
  } else if (value instanceof Date) {
    return createFromJS(value)
  } else if (typeof value === 'string' || value instanceof String) {
    // Needs the T to be parseable
    // setZone because we dont care about the timezone, this ensures the times are not modified when displayed (e.g having offset applied)
    return DateTime.fromISO(value.split(' ').join('T'), { setZone: true })
  } else if (typeof value === 'number') {
    return DateTime.fromMillis(value)
  } else if (value) {
    return DateTime.fromISO(value)
  } else {
    return DateTime.local()
  }
}

export default {
  newDate (val) {
    return createDate(val)
  },
  now () {
    return this.newDate(new Date())
  },
  luxonFormatDate (timestamp, format) {
    const date = createDate(timestamp)
    return date.toFormat(format)
  },
  toShortDate (timestamp) { // 10/14/1983 - localised formatting
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString(DateTime.DATE_SHORT)
  },
  toHugeDate (timestamp) { // Tuesday, October 14, 1983 // localised formatting
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString(DateTime.DATE_HUGE)
  },
  toCivilDate (timestamp) { // 1983-14-10
    const date = createDate(timestamp)
    return date.toFormat('yyyy-LL-dd')
  },
  toCivilTime (timestamp) { // 19:45
    const date = createDate(timestamp)
    return date.toFormat('HH:mm')
  },
  toMediumDate (timestamp) {
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' })
  },
  toMediumShortDayDate (timestamp) {
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString({ weekday: 'short', month: 'long', day: '2-digit', year: 'numeric' })
  },
  toMediumShortDate (timestamp) {
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString(DateTime.DATE_MED)
  },
  toMediumDateTime (timestamp) { // 1 Sept 2021, 08:45
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString(DateTime.DATETIME_MED)
  },
  toShortDateTime (timestamp) {
    const date = createDate(timestamp)
    return date.setLocale('en-gb').toLocaleString(DateTime.DATETIME_SHORT)
  },
  toDayOfMonth (timestamp) {
    const date = createDate(timestamp)
    function getNumberSuffix (num) {
      let day = num
      const th = 'th'
      const rd = 'rd'
      const nd = 'nd'
      const st = 'st'

      /* eslint-disable-next-line */
      if (num == 11 || num == 12 || num == 13) return (day += th)

      let lastDigit = num.toString().slice(-1)

      switch (lastDigit) {
      case '1': return (day += st)
      case '2': return (day += nd)
      case '3': return (day += rd)
      default: return (day += th)
      }
    }
    return `${getNumberSuffix(date.toFormat('d'))} ${date.toFormat('LLLL')}`
  },
  toWeekdayDate (timestamp) {
    const date = createDate(timestamp)
    return date.toFormat('ccc d LLL')
  },
  toNameOfDay (timestamp) {
    const date = createDate(timestamp)
    return date.toFormat('cccc')
  },
  toCivilDateTime (timestamp) { // 1983-14-10 19:45
    const date = createDate(timestamp)
    return `${date.toFormat('yyyy-LL-dd')} ${date.toFormat('HH:mm')}`
  },
  toCardExpiry (timestamp) { // 1983-14-10 19:45
    const date = createDate(timestamp)
    return date.toLocaleString({ month: 'numeric', year: '2-digit' })
  },
  toTicketExpiry (timestamp, config) {
    const date = createDate(timestamp)
    const expiry = date.plus(config)
    return expiry.toFormat('dd LLLL yyyy')
  },
  getDateDiff (date1, date2, unit = 'milliseconds') {
    const end = createDate(date1)
    const start = createDate(date2)
    return end.diff(start, unit)
  },
  addToDate (timestamp, config) {
    const withT = createDate(timestamp)
    const newDate = withT.plus(config)
    return newDate
  },
  adjustDate (timestamp, config) {
    const withT = createDate(timestamp)
    const newDate = withT.set(config)
    return newDate
  },
  subtractFromDate (timestamp, config) {
    const withT = createDate(timestamp)
    const newDate = withT.minus(config)
    return newDate
  },
  roundMinutesUp (timestamp, step = 5) {
    const date = createDate(timestamp)
    var coeff = 1000 * 60 * step
    const roundedDate = createDate(Math.ceil(date.ts / coeff) * coeff)
    return roundedDate
  },
  roundMinutesDown (timestamp, step = 5) {
    const date = createDate(timestamp)
    var coeff = 1000 * 60 * step
    const roundedDate = createDate(Math.floor(date.ts / coeff) * coeff)
    return roundedDate
  },
  getDiff (timestamp, options) {
    const date = createDate(timestamp)
    return date.diffNow(options)
  },
  minutesToHours (val) {
    if (val < 60) return `${val}m`
    let m = val
    let h = 0
    while (m >= 60) {
      h++
      m -= 60
    }
    if (h >= 24) {
      const days = Math.floor(h / 24)
      return i18n.tc('num.days', days)
    }
    return `${h}h ${m}m`
  }
}
